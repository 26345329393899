import { Dropdown } from 'bootstrap';

/**
 * Bootstrap autocomplete input element.
 */

class ObjectsInputItem {

	static exclude = ['id', 'text', 'html', 'title'];

	static cls = {
		node: 'item',
		node_add: 'loading-nohide loading-center-y',
		loading: 'loading',
		label: 'name',
		remove_btn: 'remove'
	}

	constructor(owner, data, loading) {
		const _this = this;
		_this.cls = _this.constructor.cls;
		_this.owner = owner;
		const node = (_this.node = _this.node_init());
		node.appendChild((_this.label = _this.label_init()));
		node.appendChild((_this.remove_btn = _this.remove_btn_init()));

		_this.data = {};
		if (data !== undefined && data !== null) {
			_this.update(typeof data === 'object' ? data : {id: data});
		}
		_this.loading = loading;
	}

	/**
	 * Создаёт элемент item
	 */
	node_init() {
		const node = document.createElement('span');
		node.className = this.cls.node + ' ' + (this.cls.node_add || '');
		return node;
	}

	/**
	 * Создаёт объект метки элемента
	 */
	label_init() {
		const node = document.createElement('span');
		node.className = this.cls.label + ' ' + (this.cls.label_add || '');
		return node;
	}

	/**
	 * Создаёт элемент remove
	 */
	remove_btn_init() {
		const node = document.createElement('span');
		node.className = this.cls.remove_btn + ' ' + (this.cls.remove_btn_add || '');;
		return node;
	}

	/**
	 * Состояние режима "loading"
	 */
	get loading() {
		return this.node.classList.contains(this.cls.loading);
	}
	set loading(on) {
		this.node.classList[on ? 'add' : 'remove'](this.cls.loading);
	}

	/**
	 * Идентификатор элемента. Всегда строка
	 */
	get id() {
		return this.node.dataset.id;
	}
	set id(value) {
		this.node.dataset.id = value;
	}

	/**
	 * html содержимое метки элемента
	 */
	get html() {
		return this.label.innerHTML;
	}
	set html(value) {
		this.label.innerHTML = value;
		this.title = this.text;
	}

	/**
	 * Текстовое представление содержимого метки элемента
	 */
	get text() {
		return this.label.innerText;
	}
	set text(value) {
		this.label.innerText = value;
		this.title = value;
	}

	/**
	 * Подсказка метки элемента
	 */
	get title() {
		return this.node.title;
	}
	set title(value) {
		this.node.title = value;
	}

	/**
	 * Обновить данные элемента
	 * @param {*} data
	 */
	update(data) {
		const _this = this, exclude = _this.constructor.exclude;

		// Убрать класс loading
		_this.loading = false;

		// Установить основные данные элемента
		for (const name of exclude) {
			if (data[name] !== undefined) _this[name] = data[name];
		}

		// Установить прочие данные элемента
		for (const [name, val] of Object.entries(data)) {
			if (exclude.indexOf(name) < 0) _this.data[name] = val;
		}

		if (!_this.id) throw '"id" attribute must be defined';
	}

}


export default class ObjectsInput{
	static _instance_id = 0;

	static cls = {
		autocomplete: 'autocomplete',
		autocomplete_always: 'autocomplete-always',
		noautocomplete: 'noautocomplete',
		counter: 'counter',
		dialog_button: 'dialog-button',
		dropdown: 'objectsinput-dropdown',
		dialog: 'objectsinput-dialog',
		inner: 'inner',
		invalid: 'is-invalid',
		items: 'items',
		item_click: 'item-click',
		loading: 'loading',
		widget: 'objectsinput-control'
	};

	static defaults = {
		autocomplete: true,
		dialog: false,
		dialog_class: undefined,
		dialog_onclick: false,
		dropdown_class: undefined,
		empty_search: false,
		input_timeout: 500,
		itemClass: ObjectsInputItem,
		load: undefined,
		min_length: 2,
		multiple: false,
		noresult_text: 'Ничего не найдено',
		onchange: undefined,
		ondialog: undefined,
		ondialoghidden: undefined,
		ondialoghide: undefined,
		ondialogshow: undefined,
		ondialogshown: undefined,
		onitemclick: undefined,
		request: undefined,
		search: undefined,
		search_placeholder: 'Начните набирать текст...',
		widget_class: undefined
	};

	//_attr_re = /^data-oi-(.+)$/;

	constructor(input, options) {
		const
			_this = this,
			instance_id = ++ObjectsInput._instance_id,
			classes = (_this.cls = Object.assign({}, _this.constructor.cls, _this.cls));

		// Подготовка настроек options
		const opts = (_this.options = Object.assign({}, _this.constructor.defaults, _this.defaults, typeof options === 'object' ? options : {}));
		// let _attr;
		// for (let a of input.getAttributeNames()) {
		// 	if (_attr = _this._attr_re.exec(a)) opts[_attr[1].toLowerCase().replace('-', '_')] = input.getAttribute(a);
		// }

		// Словарь объектов по их идентификаторам
		_this.items = new Map();

		// Скрыть исходный input
		(_this.input = input).style.setProperty('display', 'none', 'important');
		input.type='hidden';

		// Создание виджета
		const widget = (_this.widget = document.createElement('DIV'));
		widget.dataset.objectsinput = instance_id;

		// inner node
		_this.inner = document.createElement('DIV');
		_this.inner.className = classes.inner;
		widget.appendChild(_this.inner);

		// items node
		_this.items_node = document.createElement('div');
		_this.items_node.className = classes.items;
		_this.inner.appendChild(_this.items_node);

		// autocomplete
		_this.autocomplete = new ObjectsInputAutocomplete(_this);

		// Диалог
		if (opts.dialog) {
			_this.dialog = new ObjectsInputDialog(_this);
		}

		// Классы виджета
		widget.className = classes.widget;
		if (input.className) widget.className += ' ' + input.className;
		if (opts.onitemclick) widget.className += ' ' + classes.item_click;
		if (opts.widget_class) {
			if (typeof opts.widget_class === 'string')
				widget.className +=  ' ' + opts.widget_class;
			else
				widget.className +=  ' ' + opts.widget_class.join(' ');
		}
		if (!opts.autocomplete) {
			widget.classList.add(classes.noautocomplete);
		}

		// Вставляем виджет после исходного input
		input.insertAdjacentElement('afterend', widget);
		input.dataset.objectsinput = instance_id;

		// Счётчик объектов
		_this.counter = new ObjectsInputCounter(_this);

		// request
		if (opts.request) {
			_this.request = (opts.request instanceof ObjectsInputRequest ? opts.request : new ObjectsInputRequest(opts.request))
		}

		// Добавление обработчиков событий
		_this._bind();

		// Set initial value
		_this.add_item(_this.input_list(), false);
	}

	/**
	 * Получение ближайшего родительского элемента с указанным классом в
	 * пределах виджета. Если не найден, то возвращается null.
	 */
	_closest(node, cls) {
		while (node && (node !== this.widget) && (node !== document.body)) {
			if (node.classList.contains(cls)) return node;
			node = node.parentElement;
		}
		return null;
	}

	/**
	 * Назначение обработчиков на события
	 */
	_bind() {
		const
			_this = this,
			widget = _this.widget,
			options = _this.options;

		// Клик на виджете
		widget.addEventListener('click', function(e){
			if (e.defaultPrevented) return;
			let target = e.target;
			while (target && target !== widget && target !== document.body) {

				// Клик на удаление объекта
				if (target.classList.contains(options.itemClass.cls.remove_btn)) {
					try {
						let node = _this._closest(target, options.itemClass.cls.node);
						_this.remove_item(node);
						node.remove();
					} catch(e) {}
					_this.focus();
					e.preventDefault();
					return;
				}

				// Клик на объекте
				if (target.classList.contains(options.itemClass.cls.node) && options.onitemclick) {
					options.onitemclick.call(_this, _this.get_item(target.dataset.id), target);
					e.preventDefault();
					return;
				}

				// Клик на autocomplete или items
				// Автоматическое открытие диалога
				if ((target.classList.contains(_this.cls.autocomplete)
					 || target.classList.contains(_this.cls.items)) &&
					 _this.dialog && options.dialog_onclick) {
						setTimeout(() => _this.dialog.toggle(), 20);
						e.preventDefault();
					}

				target = target.parentElement;
			}

			_this.focus();
		});

	}

	/**
	 * Очистить элемент ввода.
	 * Удаляет все элементы объектов и очищает строку быстрого поиска
	 */
	clear(event) {
		const _this = this, items = Array.from(_this.items.values());
		event = (event || event === undefined);

		_this.input.value  = '';
		_this.autocomplete.value = '';
		if (_this.autocomplete.dropdown) _this.autocomplete.dropdown.clear();
		_this.items_node.innerHTML = '';
		_this.items.clear();
		_this.counter.value = 0;

		if (event && _this.options.onchange) _this.options.onchange.call(_this, {remove: items});
	}

	/**
	 * Возвращает массив идентификаторов объектов.  Всегда возвращает массив.
	 * @returns
	 */
	id_list() {
		return Array.from(this.items.keys());
	}

	input_list() {
		return this.input.value ? this.input.value.split(',') : [];
	}

	loading(active) {
		this.autocomplete.widget.classList[active || active === undefined ? 'add' : 'remove' ](this.cls.loading);
	}

	/**
	 * Возвращает true если есть хотя бы один элемент в состоянии loading;
	 */
	items_loading() {
		for (const item of this.items.values()) if (item.loading) return true;
		return false;
	}

	/**
	 * Получить элемент объекта по указанному идентификатору
	 * @param {string} id
	 */
	get_item(id) {
		return this.items.get('' + id);
	}

	add_item(data, event) {
		const _this = this;
		let items = [];

		if (!_this.options.multiple) _this.clear(false);

		let loading, item;
		for (const item_data of (Array.isArray(data) ? data : [data])) {
			if (typeof item_data === 'object') {
				// item_data - Объект
				if (item = _this.get_item(item_data.id)) {
					item.update(item_data);
				} else {
					item = _this._add_item(item_data);
				}
			} else if (!(item = _this.get_item(item_data))) {
				// item_data - Идентификатор
				item = _this._add_item(item_data, loading = loading || true);
			}
			items.push(item);
		}

		_this.counter.value = _this.items_node.childNodes.length;
		_this.input.value = _this.id_list().join(',');
		if (loading) _this._load_items();
		if ((event || event === undefined) && _this.options.onchange) _this.options.onchange.call(_this, {add: items});

		return items;
	}

	/**
	 * Создаёт объект ObjectsInputItem
	 */
	_add_item(data, loading) {
		const _this = this, item = new _this.options.itemClass(_this, data, loading);
		_this.items_node.appendChild(item.node);
		if (item.id !== undefined) {
			_this.items.set(item.id, item);
		}
		return item;
	}

	/**
	 * Удалить элемент
	 */
	remove_item(item, event) {
		const _this = this;

		if (item instanceof Element) {
			item = item.dataset.id;
		}
		if (!(item instanceof ObjectsInputItem)) {
			item = _this.get_item(item);
		}

		if (item) {
			if (item.id !== undefined) _this.items.delete(item.id);
			item.node.remove();
			_this.counter.value = _this.items_node.childNodes.length;
			_this.input.value = _this.id_list().join(',');
			if ((event || event === undefined) && _this.options.onchange) _this.options.onchange.call(_this, {remove: [item]});
		}
	}

	_dropdown_fill(items, more) {
		this.autocomplete.dropdown.fill(items && items.length ? items : this.options.noresult_text, undefined, more);
	}

	/**
	 * Получить состояние "invalid"
	 */
	get invalid() {
		return this.widget.classList.contains(this.cls.invalid);
	}

	/**
	 * Установить состояние "invalid"
	 */
	set invalid(value) {
		this.widget.classList[value ? 'add' : 'remove'](this.cls.invalid);
	}

	/**
	 * Обновить данные элементов
	 */
	update_items(items) {
		let _items = this.items, _item;
		for (let item of items) if (_item = _items.get('' + item.id)) _item.update(item);
	}

	_load_items() {
		const _this = this, id = [];
		_this.items.forEach(item => item.loading && id.push(item.id));
		if (!id.length) return;
		if (typeof _this.options.load === 'function') {
			_this.options.load.call(_this, id, items => typeof items === 'string' ? console.error(items) :  _this.update_items(items));
		} else if (_this.request) {
			_this.request.load(_this, id);
		}
	}

	/**
	 * Перезагрузка всех выбранных элементов
	 */
	reload() {
		this.items_node.innerHTML = '';
		this.add_item(this.input_list(), false);
	}

	focus() {
		this.autocomplete.focus();
	}

	get value() {
		return this.input.value;
	}

	/**
	 * Вызвать обработчик options.onevent.  Если он установлен. Аргументы args будут переданы в
	 * обработчик "как-есть".
	 */
	dispatch(event, ...args) {
		if (typeof this.options['on' + event] === 'function') return this.options['on' + event].apply(this, args);
	}
}

/**
 * Поле ввода быстрого поиска
 */
class ObjectsInputAutocomplete {
	constructor(owner) {
		const _this = this, opts = owner.options;
		_this.owner = owner;

		// widget
		const widget = (_this.widget = document.createElement('DIV'));
		widget.className = owner.cls.autocomplete;
		if (opts.autocomplete == 'always') widget.classList.add(owner.cls.autocomplete_always);

		// autocomplete input
		const input = (_this.input = document.createElement('INPUT'));
		if (owner.input.placeholder) {
			input.placeholder = owner.input.placeholder;
		}
		else if (opts.search_placeholder) {
			input.placeholder = opts.search_placeholder;
		}

		widget.appendChild(input);
		owner.inner.appendChild(widget);

		// autocomplete dropdown
		_this.dropdown = new ObjectsInputDropdown(_this);

		_this._bind();
	}

	/**
	 * Установить фокус на autocomplete
	 */
	focus() {
		this.input.focus();
	}

	/**
	 * Получить значение поля ввода (input)
	 */
	get value() {
		return this.input.value;
	}

	/**
	 * Установить значение поля ввода (input)
	 */
	set value(val) {
		this.input.value = val;
	}

	/**
	 * Назначение обработчиков на события
	 */
	_bind() {
		const
			_this = this,
			input = _this.input,
			options = _this.owner.options;

		let onautocomplete;

		// Ввод в поле input
		input.addEventListener('input', function(){
			// Автопоиск отключён
			if (!options.autocomplete) {
				input.value = '';
				return;
			}
			// Останавливаем запущенный таймер
			if (_this._ac_timer && clearTimeout(_this._ac_timer)) _this._ac_timer=null;
			// Останавливаем предыдущий запрос
			if (_this.request) _this.request.search_abort(_this);
			// Запускаем таймер
			_this._ac_timer = setTimeout(onautocomplete || (onautocomplete = _this._onautocomplete()), options.input_timeout);
			_this.dropdown.clear(true);
		});

		// Нажатие клавиш в поле input
		input.addEventListener('keydown', function(e){
			switch (e.key) {
			case 'ArrowDown':
				// Если выпадающее меню не является пустым
				if (!_this.dropdown.is_empty()) {
					if (!_this.dropdown.active) {
						// Показать выпадающее меню
						_this.dropdown.show();
						_this.dropdown._focus_item('first');
					} else {
						// Сфокусировать на следующем элементе
						_this.dropdown._focus_item('next');
					}
					e.preventDefault();
				} else if (_this.owner.dialog) {
					// Открыть диалог, если есть кнопка диалога
					_this.owner.dialog.show();
					e.preventDefault();
				}
				break;

			case "ArrowUp":
				if (_this.dropdown.active) {
					// Сфокусировать на предыдущем элементе
					_this.dropdown._focus_item('prev');
					e.preventDefault();
				} else if (_this.owner.dialog && _this.owner.dialog.active) {
					_this.owner.dialog.hide();
					e.preventDefault();
				}
				break;

			case 'Home':
				if (_this.dropdown.active) {
					// Сфокусировать на предыдущем элементе
					_this.dropdown._focus_item('first');
					e.preventDefault();
				}
				break;

			case 'End':
				if (_this.dropdown.active) {
					// Сфокусировать на предыдущем элементе
					_this.dropdown._focus_item('last');
					e.preventDefault();
				}
				break;

			case "Escape":
				if (_this.dropdown.active) {
					_this.dropdown.hide();
					e.preventDefault();
				} else if (_this.owner.dialog && _this.owner.dialog.active) {
					_this.owner.dialog.hide();
					e.preventDefault();
				}
				break;

			case "Enter":
				if (_this.dropdown.active) {
					_this.dropdown.item_click('active');
					e.preventDefault();
				}
				break;

			case "Backspace":
				if (e.ctrlKey && !e.altKey && !e.shiftKey) { // Ctrl + BackSpace
					let item = _this.owner.items_node.lastElementChild;
					if (item) _this.owner.remove_item(item);
				}
				break;
			}
		});
	}

	/**
	 * Обёртка возвращающая функцию быстрого поиска по строке.
	 */
	_onautocomplete() {
		const
			_this = this,
			owner = _this.owner,
			opts = owner.options,
			input = _this.input;

		if (typeof opts.search === 'function') {
			// Пользовательская функция
			return function() {

				// Проверка на запуск поиска при пустой строке
				if (input.value.length < opts.min_length) {
					if (opts.empty_search && !_this._empty_search_fired && _this._empty_search_fired !== undefined) {
						_this._empty_search_fired = true;
					} else {
						return;
					}
				} else {
					_this._empty_search_fired = false;
				}

				// Включить режим "loading"
				_this.owner.loading(true);
				// Запуск пользовательской функции поиска
				opts.search.call(owner, input.value, items => {
					_this.owner.loading(false);
					if (Array.isArray(items)) {
						_this.dropdown.fill(items && items.length ? items : opts.noresult_text);
					}
				});
			};
		} else if (owner.request) {
			// Использование объекта ObjectsInputRequest
			return function() {
				// Проверка на запуск поиска при пустой строке
				if (input.value.length < opts.min_length) {
					if (opts.empty_search && !_this._empty_search_fired) {
						_this._empty_search_fired = true;
					} else {
						return;
					}
				} else {
					_this._empty_search_fired = false;
				}
				owner.request.search(owner, input.value);
			}
		} else return () => {};
	}

}


/**
 * Выпадающее меню быстрого поиска
 */
class ObjectsInputDropdown {
	constructor(autocomplete) {
		const _this = this, autocomplete_input = ((_this.autocomplete = autocomplete).input);

		// Контейнер меню
		const widget = (_this.widget = document.createElement('div'));
		widget.className = autocomplete.owner.cls.dropdown;
		widget.classList.add('dropdown-menu');
		let cls = autocomplete.owner.options.dropdown_class;
		if (cls) widget.className += ' ' + (typeof cls === 'string' ? cls : cls.join(' '));
		autocomplete_input.insertAdjacentElement('afterend', widget);

		autocomplete_input.dataset.bsToggle = 'dropdown';
		autocomplete_input.ariaExpanded = false;

		// Обработчики событий диалога
		autocomplete_input.addEventListener('show.bs.dropdown', event => _this._onshow(event));
		autocomplete_input.addEventListener('shown.bs.dropdown', event => _this._onshown(event));
		autocomplete_input.addEventListener('hidden.bs.dropdown', event => _this._onhidden(event));
		_this.instance = new Dropdown(autocomplete_input, {
			//autoClose: 'outside',
			//reference: this.owner.widget
		});
	}

	/**
	 * Обработчик события диалога - show
	 */
	_onshow(event) {
		const
			_this = this,
			widget = _this.widget,
			input_widget = _this.autocomplete.input;

		if (_this.is_empty()) {
			event.preventDefault();
			return;
		}

		widget.style.overflow = 'auto';
		document.body.appendChild(widget);

		// Заполнить содержимое вызовом пользовательской функции options.ondialog
		// if (typeof opts.ondialog === 'function') {
		// 	// Если функция возвращает false - диалог не показываем
		// 	if (opts.ondialog.call(_this, widget) === false) {
		// 		_this.instance.hide();
		// 		return;
		// 	}
		// }

		// Вызов обработчика options.ondialogshow
		// Если функция возвращает false - диалог не показываем
		// if (typeof opts.ondialogshow === 'function' && opts.ondialogshow.call(_this.input, input_widget) === false) {
		// 	_this.instance.hide();
		// 	return;
		// }

		// Вычисление максимальной высоты меню.
		// Вызывается при изменении размера окна и при скроллинге
		if (!_this._resize) {
			_this._resize = () => {
				const
					bounds = input_widget.getBoundingClientRect(),
					height = Math.max(bounds.top, document.body.clientHeight - bounds.bottom);
				widget.style.maxHeight = `calc( ${height}px - 0.5rem )`;
			}
		}
		// К каким элементам привязан обработчик resize
		_this._resize_targets = [window];
		// Назначение обработчика resize элементам
		let parent = input_widget.parentNode;
		while (parent && parent !== document.documentElement) {
			if (parent.clientHeight < parent.scrollHeight) _this._resize_targets.push(parent);
			parent = parent.parentNode;
		}
		for (const node of _this._resize_targets) node.addEventListener('resize', _this._resize);
		_this._resize();

		_this._bind();
	}

	/**
	 * Установить фокус на следующий пункт меню ('next', 'prev', 'first', 'last')
	 */
	_focus_item(direction) {
		const widget = this.widget;
		let active = widget.querySelector('.active'), focus;
		switch (direction[0]) {
			case 'l': // last
				focus = widget.lastElementChild;
				break;
			case 'f': // first
				focus = widget.firstElementChild;
				break;
			case 'n': // next
				focus = active ? active.nextElementSibling : widget.firstElementChild;
				break;
			case 'p': // prev
				if (active) focus = active.previousElementSibling;
				break;
		}
		if (focus && active !== focus) {
			if (active) active.classList.remove('active');
			focus.classList.add('active');
			focus.scrollIntoView({block:'nearest'});
		}
		return focus;
	}

	/**
	 * Обработка нажатия на элемент.
	 * item = 'active' - если элемент не указан используется текущий активный.
	 */
	item_click(item) {
		const _this = this;
		if (item == 'active') item = _this.widget.querySelector('a.active');
		if (!item) return;
		_this.autocomplete.owner.add_item(item.dataset.id);
		_this.clear(true);
		_this.autocomplete.value = '';
		_this.autocomplete.focus();
	return item;
	}

	/**
	 * Назначить обработчики событий
	 */
	_bind() {
		const _this = this, widget = _this.widget;

		widget.addEventListener('click', (e) => {
			let target = e.target;

			while (target && target !== widget) {
				if (target.tagName == 'A') {
					_this.item_click(target);
					return;
				}
				target = target.parentElement;
			}
		});
	}

	/**
	 * Обработчик события выпадающего меню - shown
	 */
	_onshown() {
		this.active = true;
	}

	/**
	 * Обработчик окончания скрытия меню
	 */
	_onhidden(event) {
		const
			_this = this,
			widget = _this.widget;

		_this.active = false;
		_this.autocomplete.input.insertAdjacentElement('afterend', widget);

		// Отключение обработчиков resize
		for (const node of _this._resize_targets) node.removeEventListener('resize', _this._resize);
		_this._resize_targets = [];
	}

	show() {
		this.instance.show();
	}

	hide() {
		this.instance.hide();
	}

	/**
	 * Очистить меню
	 */
	clear(hide) {
		this.widget.innerHTML = '';
		if (hide) this.hide();
	}

	/**
	 * true - если меню содержит элементы
	 */
	is_empty() {
		return !this.widget.hasChildNodes();
	}

	fill(items, show, more) {
		const _this = this, widget = _this.widget;

		// Очистка текущего выпадающего меню
		_this.clear();

		// Заполнение выпадающего меню
		if (items) {
			if (typeof items === 'string') {
				widget.innerHTML = '<span class="dropdown-item-text text-muted"></span>';
				widget.querySelector('.dropdown-item-text').innerText = items;
			} else if (items.length) {
				// Заполнение меню
				let menu_item;
				for (let item of items) {
					if (item.id === undefined) {
						menu_item = document.createElement('div');
						menu_item.className = 'dropdown-item-text';
					} else {
						menu_item = document.createElement('a');
						menu_item.className = 'dropdown-item';
						menu_item.href = '#';
						menu_item.dataset.id = item.id;
					}
					if (item.html){
						menu_item.innerHTML = item.html;
					} else {
						menu_item.innerText = item.text;
					}
					menu_item.title = menu_item.innerText;
					widget.appendChild(menu_item);
				}
				if (more) widget.insertAdjacentHTML('beforeend', '<span class="dropdown-item-text text-muted">...</span>');
			}
		}

		// Показать меню
		if ((show || show === undefined) && !_this.active) _this.show();
	}

}

/**
 * Счётчик объектов
 */
class ObjectsInputCounter {

	show_value = 2;

	static cls = {
		counter: 'counter'
	};

	constructor(owner) {
		const _this = this;

		_this.owner = owner;
		_this._value = 0;
	}

	/**
	 * Получить значение счётчика
	 */
	get value() {
		return this._value;
	}

	/**
	 * Установить значение счётчика
	 */
	set value(val) {
		const _this = this;
		if (_this._value != val) _this[(_this._value = val) >= _this.show_value ? 'show' : 'hide']();
	}

	/**
	 * Создание элемента счётчика
	 */
	show() {
		const _this = this;
		if (!_this.node) {
			const node = (_this.node = document.createElement('div'));
			node.className = _this.constructor.cls.counter;
			node.innerHTML = '<span data-bs-toggle="dropdown"'
				+ ' data-bs-boundary="viewport" data-bs-auto-close="outside" aria-expanded="false"></span>'
				+ '<div class="dropdown-menu overflow-auto" style="max-height: calc( 100vh - 2rem )"></div>';
			_this.owner.autocomplete.widget.insertAdjacentElement('afterend', node);
			const menu = node.lastChild;
			node.addEventListener('show.bs.dropdown', function(){
				menu.innerHTML = '';
				let menu_item;
				for (let item of _this.owner.items_node.childNodes) {
					menu_item = item.cloneNode(true);
					menu_item.classList.add('dropdown-item');
					menu.appendChild(menu_item);
				}
			});
			node.addEventListener('hidden.bs.dropdown', function(){
				menu.innerHTML = '';
			});
		}
		_this.node.firstChild.innerText = _this._value;
	}

	/**
	 * Удаление элемента счётчика
	 */
	hide() {
		const _this = this;
		if (_this.node) {
			_this.node.remove();
			delete _this.node;
		}
	}
}

/**
 * Выпадающий диалог
 */
class ObjectsInputDialog {
	constructor(owner) {
		const _this = this;

		_this.owner = owner;

		// Кнопка
		const button = (_this.button = document.createElement('div'));
		button.className = owner.cls.dialog_button;
		//button.href = '#';
		//button.role = 'button';
		button.dataset.bsToggle = 'dropdown';
		button.ariaExpanded = false;

		owner.inner.insertAdjacentElement('beforeend', button);

		// Контейнер диалога
		const widget = (_this.widget = document.createElement('div'));
		widget.className = owner.cls.dialog;
		widget.classList.add('dropdown-menu');
		button.insertAdjacentElement('afterend', widget);

		// Обработчики событий диалога
		button.addEventListener('show.bs.dropdown', event => _this._onshow(event));
		button.addEventListener('shown.bs.dropdown', event => _this._onshown(event));
		button.addEventListener('hide.bs.dropdown', event => _this._onhide(event));
		button.addEventListener('hidden.bs.dropdown', event => _this._onhidden(event));
		_this.instance = new Dropdown(button, {
			autoClose: 'outside',
			reference: this.owner.widget
		});
	}

	/**
	 * Обработчик события диалога - show
	 */
	_onshow() {
		const
			_this = this,
			opts = _this.owner.options,
			widget = _this.widget,
			input_widget = _this.owner.widget;

		widget.style.overflow = 'auto';
		document.body.appendChild(widget);

		for (const cls of (opts.dialog_class || '').split(/ +/g)) if (cls) widget.classList.add(cls);

		// Заполнить содержимое вызовом пользовательской функции options.ondialog
		if (!_this._ondialog_done && typeof opts.ondialog === 'function') {
			// Если функция возвращает false - диалог не показываем
			if (opts.ondialog.call(_this, widget) === false) {
				_this.instance.hide();
				return;
			}
			_this._ondialog_done = true;
		}

		// Вызов обработчика options.ondialogshow
		// Если функция возвращает false - диалог не показываем
		if (typeof opts.ondialogshow === 'function' && opts.ondialogshow.call(_this.owner, widget) === false) {
			_this.instance.hide();
			return;
		}

		input_widget.classList.add('_dialog-active');

		// Вычисление максимальной высоты меню.
		// Вызывается при изменении размера окна и при скроллинге
		if (!_this._resize) {
			_this._resize = () => {
				const
					bounds = input_widget.getBoundingClientRect(),
					height = Math.max(bounds.top, document.body.clientHeight - bounds.bottom);
				widget.style.maxHeight = `calc( ${height}px - 0.5rem )`;
				widget.style.minWidth = `${bounds.width}px`;
			}
		}
		// К каким элементам привязан обработчик resize
		_this._resize_targets = [window];
		// Назначение обработчика resize элементам
		let parent = input_widget.parentNode;
		while (parent && parent !== document.documentElement) {
			if (parent.clientHeight < parent.scrollHeight) _this._resize_targets.push(parent);
			parent = parent.parentNode;
		}
		for (const node of _this._resize_targets) node.addEventListener('resize', _this._resize);
		_this._resize();

	}

	/**
	 * Обработчик события диалога - shown
	 */
	_onshown() {
		const _input = this.owner;
		this.active = true;
		if (typeof _input.options.ondialogshown === 'function') _input.options.ondialogshown.call(_input, this.widget);
	}

	/**
	 * Обработчик скрытия диалога
	 */
	_onhide(event) {
		const
			_this = this,
			opts = _this.owner.options,
			widget = _this.widget;

		if (opts.ondialoghide && opts.ondialoghide.call(_this.owner, widget) === false) {
			event.preventDefault();
			return;
		}
	}

	/**
	 * Обработчик окончания скрытия диалога
	 */
	_onhidden(event) {
		const
			_this = this,
			opts = _this.owner.options,
			widget = _this.widget;

		_this.active = false;
		_this.owner.widget.classList.remove('_dialog-active');
		_this.button.insertAdjacentElement('afterend', widget);

		// Отключение обработчиков resize
		for (const node of _this._resize_targets) node.removeEventListener('resize', _this._resize);
		_this._resize_targets = [];

		if (opts.ondialoghidden) opts.ondialoghidden.call(_this.owner, widget);
	}

	show() {
		this.toggle('show');
	}

	hide() {
		this.toggle('hide');
	}

	toggle(mode) {
		/*
		Пока непонятно нужен ли этот таймер...
		const _this = this;
		if (_this._dialog_timer) {
			clearTimeout(_this._dialog_timer);
			delete _this._dialog_timer;
		}
		if (!_this.dialog) return;
		if (mode === undefined) {
			mode = _this.dialog_active ? 'hide' : 'show';
		} else if (mode != 'show' && mode != 'hide') {
			throw `Wrong mode "${mode} in dialog_toggle()"`;
		}
		_this._dialog_timer = setTimeout(() => _this.dialog[mode](), 50);
		*/
		if (mode === undefined) {
			mode = this.active ? 'hide' : 'show';
		} else if (mode != 'show' && mode != 'hide') {
			throw `Wrong mode "${mode} in toggle()"`;
		}
		this.instance[mode]();
	}

}


/**
 * Выполнение запроса на сервер
 */
export class ObjectsInputRequest {

	defaults = {
		data: {},
		id_delim: ',',
		load_before: undefined,
		load_complete: undefined,
		method: 'POST',
		param_id: 'id',
		param_search: 'q',
		search_before: undefined,
		response_items: '',
		response_more: 'more',
		response_type: 'json',
		timeout: 30000,
		url: ''
	}

	constructor(options) {
		this.options = Object.assign({}, this.defaults, options);
		this._search = new Map();
		this._load = new Map();
	}

	/**
	 * Загрузить данные элементов
	 */
	load(input, items) {
		const
			_this = this,
			opts = _this.options,
			data = Object.assign({}, opts.data, opts.load_data),
			id = [];

		// Подготовка данных
		for (const item of (items || [])) id.push(item instanceof ObjectsInputItem ? item.id : item);
		data[opts.param_id] = id.join(opts.id_delim);

		// Выполнение обработчика
		if (typeof opts.load_before === 'function' && opts.load_before.call(_this, data, items || [], input) === false) return;

		// Подготовка запроса
		const
			xhr = new XMLHttpRequest(),
			xhr_complete = () => _this._load_complete(xhr, input);
		Object.assign(xhr, {
			timeout: opts.load_timeout !== undefined ? opts.load_timeout : opts.timeout,
			responseType: opts.load_response_type || opts.response_type,
			onload: xhr_response({
				success: function() {
					const
						param = opts.load_response_items !== undefined ? opts.load_response_items : opts.response_items,
						response = (param ? (this.response || {})[param] : this.response) || [];
					input.update_items(response);
				},
				complete: xhr_complete
			}),
			onabort: xhr_complete,
			ontimeout: xhr_complete
		});

		// Отправка запроса
		let
			url = (opts.load_url !== undefined ? opts.load_url : opts.url),
			no_hash = /^(.*)#.*$/.exec(url);
		if (no_hash) url = no_hash[1];
		url += (url.indexOf('?') < 0 ? '?' : '&') + (new Date()).getTime();
		const post_data = new FormData();
		for (let [n, v] of Object.entries(data) ) {
			post_data.append(n, v);
		}
		xhr.open(opts.load_method || opts.method, url);
		xhr.send(post_data);
		let loads = _this._load.get(input);
		if (loads === undefined) _this._load.set(input, new Set([xhr])); else loads.add(xhr);
	}

	_load_complete(xhr, input) {
		const _this = this;
		let _loads = _this._load.get(input);
		if (_loads) {
			_loads.delete(xhr);
			if (!_loads.size) _this._load.delete(input);
			_loads = undefined;
		}
		if (!_loads && typeof _this.options.load_complete === 'function') _this.options.load_complete.call(_this, input);
	}

	/**
	 * Возвращает true, если есть активные запросы load для указанного input
	 */
	is_loading(input) {
		return !!(this._load.get(input) || {}).size;
	}

	/**
	 * Получить элементы по строке поиска
	 */
	search(input, search) {
		const
			_this = this,
			opts = _this.options,
			data = Object.assign({}, opts.data, opts.search_data);

		// Остановка запущенного XHR
		_this.search_abort(input)

		// Подготовка данных
		data[opts.param_search] = (search === undefined || search === null ? '' : search);

		// Выполнение обработчика
		if (typeof opts.search_before === 'function' && opts.search_before.call(this, data, data[opts.param_search], input) === false) return;

		// Проверка на запуск поиска при пустой строке
		// if (autocomplete.value.length < opts.min_length) {
		// 	if (opts.empty_search && !_this._empty_search_fired) {
		// 		_this._empty_search_fired = true;
		// 	} else {
		// 		return;
		// 	}
		// } else {
		// 	_this._empty_search_fired = false;
		// }

		// Включить режим "loading"
		input.loading(true);

		// Подготовка запроса
		const
			xhr = new XMLHttpRequest(),
			loading_off = () => {
				input.loading(false);
				_this._search.delete(input);
			}
		Object.assign(xhr, {
			timeout: opts.search_timeout !== undefined ? opts.search_timeout : opts.timeout,
			responseType: opts.search_response_type || opts.response_type,
			onload: xhr_response({
				success: function() {
					const
						param = opts.search_response_items !== undefined ? opts.search_response_items : opts.response_items,
						response = (param ? (this.response || {})[param] : this.response) || [],
						more = (param ? (this.response || {})[opts.search_response_more || opts.response_more] : false);
					input._dropdown_fill(response, more);
				},
				complete: loading_off
			}),
			onabort: loading_off,
			ontimeout: loading_off
		});

		// Отправка запроса
		let
			url = (opts.load_url !== undefined ? opts.load_url : opts.url),
			no_hash = /^(.*)#.*$/.exec(url);
		if (no_hash) url = no_hash[1];
		url += (url.indexOf('?') < 0 ? '?' : '&') + (new Date()).getTime();
		const post_data = new FormData();
		for (let [n, v] of Object.entries(data) ) {
			post_data.append(n, v);
		}

		xhr.open(opts.search_method || opts.method, url);
		xhr.send(post_data);
		_this._search.set(input, xhr);
	}

	search_abort(input) {
		const xhr = this._search.get(input);
		if (!xhr) return;
		if (xhr.readyState < 4) xhr.abort();
		this._search.delete(input);
	}

}

/**
 * Обработка ответа XMLHttpRequest.
 * @param options object - Параметры
 * @returns Функция, которая будет обрабатывать событие XMLHttpRequest.onload.
 */
function xhr_response(options) {
	return function() {
		const xhr = this, args = options.args ? options.args : [];
		if (xhr.status == 200) {
			if (options.success) options.success.apply(xhr, args);
		} else {
			if (options.error) options.error.apply(xhr, args);
		}
		if (options.complete) options.complete.apply(xhr, args);
	};
}
