/**
 * Дерево элементов
 *
 * @class
 * @param {DOM} element - Элемент в котором находится дерево.
 * @param {Object} options - Настройки дерева.
 */
export default class TreeView {

	/**
	 * Настройки дерева по умолчанию.
	 */
	static defaults = {
		autoload: true,
		check_timeout: 0,
		cls_widget: '',
		default_expanded: false,
		focus_on_check: true,
		focus_timeout: 0,
		lines: false,
		readonly: false,
		show_focus: false,
		subchecks: true,
		toggle_timeout: 0,
		view: 'tree',
		widget: true
	};

	/**
	 * Классы компонентов дерева.
	 */
	static cls = {
			check_wrapper: '_item-check-wrapper',
			checkbox: '_item-checkbox',
			children: '_has-children',
			collapsed: '_collapsed',
			focus_input: '_focus',
			focused: '_item-focused',
			inner: '_item-inner',
			item: '_item',
			label: '_item-label',
			lines: '_lines',
			loading: '_loading',
			root_inner: '_root',
			root: 'part-treeview',
			show_focus: '_show-focus',
			subchecks: '_item-subchecks',
			subitems: '_subitems',
			toggle: '_item-toggle',
			view: {
				list: '_view-list'
			}
		};

	constructor(element, load, options) {
		const _this = this,
			// Настройки дерева.
			opts = (_this.options = Object.assign({}, _this.constructor.defaults, _this.defaults, options)),
			cls = (_this.cls = Object.assign({}, _this.constructor.cls, _this.cls));

		if (typeof element !== 'object') throw 'Wrong required parameter "element" in constructor';
		if (typeof load !== 'function') throw 'Wrong required parameter "load" in constructor';

		_this._load = load;

		/*
		 * Флажки
		 */
		if (Array.isArray(opts.checkbox)) {
			if (opts.checkbox.length) _this.states = opts.checkbox;
		} else if (opts.checkbox !== undefined) {
			_this.states = [opts.checkbox];
		}
		if (opts.subchecks) _this._subchecks = new Map();

		// Обработчики событий
		_this._on = {
			focus: [],
			check: [],
			toggle: [],
			load: []
		}

		/**
		 * Словарь развернутых элементов.
		 */
		_this._expanded = new Set();

		/**
		 * Словарь выбранных элементов
		 */
		_this._checks = new Map();

		/**
		 * Словарь сирот без родителя.
		 */
		_this._orphans = new Map();

		/*
		 * Элемент в котором находится дерево.
		 */
		if (element.tagName === 'INPUT') {
			(_this.input = element).style.setProperty('display', 'none', 'important');
			element = document.createElement('div');
			_this.input.insertAdjacentElement('afterend', element);
		}
		_this.element = element;
		element.setAttribute('data-treeview', 1);

		/*
		 * Корневой элемент дерева.
		 */
		element.innerHTML = '';
		if (opts.widget) {
			element.appendChild(_this.widget = document.createElement('div'));
		} else {
			_this.widget = element;
		}

		//_this.widget.tabIndex = 0;
		const widget_class = [cls.root];
		if (opts.cls_widget) 	 widget_class.push(opts.cls_widget);
		if (opts.show_focus)     widget_class.push(cls.show_focus);
		if (opts.lines) 	 	 widget_class.push(cls.lines);
		if (opts.view != 'tree') widget_class.push(cls.view[opts.view]);
		_this.widget.className += ' ' + widget_class.join(' ');

		/*
		 * Словарь всех элементов дерева, где ключом является id элемента.
		 */
		_this.items = new Map();
		/**
		 * Служебный корневой элемент
		 */
		_this.root = new TreeViewItem(_this);

		/*
		 * Таймер запуска обработчика при выборе элемента.
		 */
		class Timeout {
			constructor(func, timeout) {
				const t = this;
				t.i = null;
				t.t = timeout;
				t.a = [];
				t.f = function(){ func.apply(_this, t.a); };
			}
			start() {
				const t = this;
				if (t.i) { clearTimeout(t.i); t.i = null; }
				t.a = arguments;
				if (t.t) t.i = setTimeout(t.f, t.t); else t.f();
			}
			stop() {
				if (this.i) { clearTimeout(this.i); this.i = null; }
			}
		}

		_this._onfocus = new Timeout(
			function(item){ this._event('focus', item); },
			opts.focus_timeout);
		_this._oncheck = new Timeout(
			function(item){ this._event('check', item); },
			opts.check_timeout);
		_this._ontoggle = new Timeout(
			function(item){ this._event('toggle', item); },
			opts.toggle_timeout);

		_this._ontoggle_exec = function(){
			if (_this._on.toggle.length) _this._ontoggle.start();
		};

		if (_this.input) {
			if (!_this.states) _this.input_update = _this._input_upd_nochecks;
			else if (_this.states.length == 1) _this.input_update = _this._input_upd_check;
			else if (_this.states.length > 1) _this.input_update = _this._input_upd_states;
		}

		_this.clear();
		_this.input_update();
		_this._bind();
		_this._loads = 0; // Счётчик активных загрузок
		if (opts.autoload) _this.load();
	}

	/**
	 * Добавляет элементы в дерево.
	 */
	add(list) {
		const _this = this, render = typeof _this.options.render === 'function' ? _this.options.render : undefined;
		if (!(Array.isArray(list) || (list instanceof Set))) list = [list];
		for (const item of list) new TreeViewItem(_this, render ? render(item) : item);
	}

	/**
	 * Привязка обработчиков событий.
	 */
	_bind() {
		const
			_this = this,
			cls = _this.cls,
			opts = _this.options,
			items = _this.items;

		/**
		 * Получение элемента дерева для node.
		 * Если элемента нет возвращает null.
		 */
		const _item = function(node) {
			node = _this._closest(node, cls.inner);
			// Если клик произошел не на заголовке элемента - всегда возвращаем null
			return (node && (node = _this._closest(node, cls.item))) ? (items.get(node.dataset.id) || null) : null;
		};

		/**
		 * Обработчики
		 */

		// Click
		_this.widget.addEventListener('click', function(e){
			let item = _item(e.target);
			if (!item) return;

			// Клик на кнопке toggle (сворачивание/разворачивание элемента)
			// и есть подэлементы
			if (e.target.classList.contains(cls.toggle) && item.children) {
				_this.toggle(item.id, e.ctrlKey);
			} else if (e.target.classList.contains(cls.checkbox) && !opts.readonly) {
				_this.check(item, undefined, e.ctrlKey, true);
				if (opts.focus_on_check) _this.focus_item(item);
			} else if (_this._closest(e.target, cls.label, _this.widget)) {
				_this.focus_item(item);
			}
			_this.focus();
			//e.stopPropagation();
		})

		// KeyDown
		_this.widget.addEventListener('keydown', function(e){
			let focused = items.get(_this._focus_id) || null,
				item;

			switch (e.key) {

				case 'ArrowDown':
					item = focused ? focused.next() : _this.root.first();
					if (item) {
						if (e.shiftKey && _this.states && !opts.readonly) _this.check(item, focused.checked !== undefined ? focused.checked : null, false, true);
						_this.focus_item(item);
						e.preventDefault();
					}
					break;

				case 'ArrowUp':
					item = focused ? focused.prev() : _this.root.first();
					if (item) {
						if (e.shiftKey && _this.states && !opts.readonly) _this.check(item, focused.checked !== undefined ? focused.checked : null, false, true);
						_this.focus_item(item);
						e.preventDefault();
					}
					break;

				case 'ArrowRight':
					if (focused && focused.children) {
						if (focused.collapsed) {
							_this.toggle(focused, e.ctrlKey);
						} else {
							try {
								_this.focus_item(focused.first());
							} catch(e) {
								console.error(e);
							}
						}
						e.preventDefault();
					}
					break;

				case 'ArrowLeft':
					if (focused) {
						if (focused.children && !focused.collapsed) {
							_this.toggle(focused, e.ctrlKey);
							e.preventDefault();
						} else if (focused.parent && focused.parent !== _this.root) {
							_this.focus_item(focused.parent);
							e.preventDefault();
						}
					}
					break;

				case 'Home':
					e.preventDefault();
					if (!(item = _this.root.first())) return;
					_this.focus_item(item);
					break;

				case 'End':
					e.preventDefault();
					if (!(item = _this.root.last())) return;
					_this.focus_item(item);
					break;

				case 'PageUp':
					if (focused) {
						let ch = _this.widget.clientHeight,
							h = 0,
							first = _this.root.first();

						item = focused;
						while (item && ((focused=item) !== first)) {
							h += item.$i.offsetHeight;
							if (h > ch) break;
							item = item.prev();
						}
						_this.focus_item(focused);
						e.preventDefault();
					}
					break;

				case 'PageDown':
					if (focused) {
						let ch = _this.widget.clientHeight,
							h = 0,
							last = _this.root.last();
						item = focused;
						while (item && ((focused = item) !== last)) {
							h += item.$i.offsetHeight;
							if (h > ch) break;
							item = item.next();
						}
						_this.focus_item(focused);
						e.preventDefault();
					}
					break;

				case ' ':
					if (_this.states && !opts.readonly) _this.check(focused, undefined, e.ctrlKey, true);
					e.preventDefault();
					break;

			}
		});

		_this.widget.addEventListener('scroll', () => {
			if (_this._focus) {
				_this._focus.parentElement.style.top = _this.widget.scrollTop + 'px';
				_this._focus.parentElement.style.left = _this.widget.scrollLeft + 'px';
			}
		});

	}

	/**
	 * Установить/сбросить флажок элемента дерева.
	 */
	check(item, check, subitems, event) {
		const _this = this, states = _this.states;

		// Флажки отключены или элемент не существует
		if (!states || (typeof item !== 'object' && !(item = _this.items.get('' + item)))) return;

		// Переключение, если не указано состояние
		if (check === undefined) {
			let state = states.indexOf(item.checked) + 1;
			check = (state >= states.length ? null : states[state]);
		}

		if (states.indexOf(check) < 0) {
			// Сбросить флажок
			_this._uncheck(item);
		} else {
			// Установить флажок
			_this._check(item, check);
		}
		if (_this._on.check.length && event) _this._oncheck.start(item);
	}

	/**
	 * Установить флажок элемента в указанное состояние
	 * @param Object item Элемент
	 */
	_check(item, state) {
		if (item.checked === state) return;
		const _this = this;

		_this._checks.set(item.id, state);
		(item.$ && item.$.setAttribute('data-state', state));

		// Отобразить, что есть флажки в подэлементах
		if (_this._subchecks && item.checked === undefined) {
			let i, parent = item, _subchecks = _this._subchecks;
			while ((parent = parent.parent) && parent !== _this.root) {
				if (i = ((_subchecks.get(parent.id) || 0) + 1)) {
					_subchecks.set(parent.id, i);
					if (i == 1 && parent && parent.$) parent.$.classList.add(_this.cls.subchecks);
				}
			}
		}
		item.checked = state;
		_this.input_update();
	}

	/**
	 * Массив идентификаторов выбранных (checked) элементов.
	 * В аргументах можно передать состояния по которым фильтровать элементы. Будут возвращены
	 * идентификаторы только тех элементов у которых установлены указанные флажки.
	 */
	checked() {
		if (arguments.length) {
			const res = [], args = new Set(arguments);
			for (const [k, v] of this._checks.entries()) {
				if (args.has(v)) res.push(k);
			}
			return res;
		}
		return Array.from(this._checks.keys());
	}

	/**
	 * Установить или снять флажок элементов дерева.  Вызов обработчика события ``check``
	 * производится не будет.  Данный метод обычно используется для начальной установки флажков
	 * при создании дерева.
     *
     * @param {Array} items - Массив идентификаторов элементов.
     * @param {boolean} check - Установить указанное состояние флажка, ``undefined`` - сбросить
     *                          флажок.
	 */
	checks(items, state) {
		const _this = this, states = _this.states, _items = _this.items;

		// Флажки отключены или items не указан
		if (!states || !items || !items.length) return;

		if (states.indexOf(state) < 0) {
			// Сбросить флажок
			for (let item of items) { if (item = _items.get('' + item)) _this._uncheck(item); }
		} else {
			// Установить флажок
			for (let item of items) {
				if (item = _items.get('' + item)) {
					_this._check(item, state);
				}
			}
		}
	}

	/**
	 * Получение ближайшего родительского элемента с указанным классом для node.
	 */
	_closest(node, cls, stop_node) {
		try {
			while (node && node !== this.widget && node !== stop_node) {
				if (node.classList.contains(cls)) return node;
				node = node.parentElement;
			}
		} catch(e) {
			console.error(e, node);
		}
		return null;
	}

	/**
	 * Очистка дерева.
	 */
	clear(preserve_state) {
		var _this = this;
		_this.widget.innerHTML = `<div class="${_this.cls.root_inner}"></div>`;

		_this.items.clear();
		_this.items.set(_this.root.id, _this.root);
		if (_this._subchecks) _this._subchecks.clear();
		_this.root.children = [];
		_this.root.$ = (_this.root.$$ = _this.widget.firstElementChild);

		/**
		 * Элемент на котором всегда будет фокус
		 */
		const
			_focus_wrapper = document.createElement('div'),
		 	_focus = (_this._focus = document.createElement('input'));
		_focus_wrapper.className = _this.cls.focus_input;
		_focus_wrapper.appendChild(_focus);
		_this.widget.insertAdjacentElement('afterbegin', _focus_wrapper);

		if (preserve_state) return;

		_this._focus_id = null;
		/**
		 * Словарь развернутых элементов.
		 */
		_this._expanded.clear();

		/**
		 * Словарь выбранных элементов
		 */
		_this._checks.clear();
	}

	/**
	 * Свернуть элемент
	 */
	collapse(item, subitems) {
		let _this = this;
		item.$.classList.add(_this.cls.collapsed);
		item.collapsed = true;
		_this._expanded.delete(item.id);

		if (subitems) {
			for (let _item of item.children) {
				if (_item.children && !_item.collapsed) _this.collapse(_item, subitems);
			}
		}
	}

	/**
	 * Текущий focused элемент
	 */
	current() {
		return this._focus_id === null ? null : (this.items.get(this._focus_id) || null);
	}

	/**
	 * Вызвать обработчик события
	 * @param string name - Имя события
	 * @param TreeViewItem item - Элемент для которого вызывается обработчик
	 */
	_event(name, item) {
		try {
			for (let handler of this._on[name]) handler.call(this, item);
		} catch(e) {
			console.error(e);
		}
	}

	/**
	 * Развернуть элемент
	 */
	expand(item, subitems, internal) {
		if (!item.children || !item.collapsed) return;
		let _this = this;
		item.collapsed = false;
		_this._expanded.add(item.id);
		item.$.classList.remove(_this.cls.collapsed);

		if (item.children === true) {
			// Загрузка подэлементов.
			// Не выполняется, если уже запущен процесс загрузки.
			if (!item.loading) {
				if (subitems) internal.expand_subitems = subitems;
				_this.load(item.id, undefined, internal);
			}
		} else {
			// Разворачивание подэлементов
			if (subitems) {
				for (let _item of item.children) _this.expand(_item, subitems, internal);
			}
			// Вызов обработчика события
			if (internal && internal.toggle_event) _this._ontoggle_exec();
		}
	}

	/**
	 * Массив идентификаторов развёрнутых (expanded) элементов
	 */
	expanded() {
		return Array.from(this._expanded);
	}

	/**
	 * Установить фокус ввода на дерево.
	 */
	focus() {
		const _this = this;
		_this._focus.parentElement.style.top = _this.widget.scrollTop + _this.widget.clientHeight / 2 + 'px';
		_this._focus.parentElement.style.left = _this.widget.scrollLeft + _this.widget.clientWidth / 2 + 'px';
		_this._focus.focus();
	}

	/**
	 * Установить фокус на элемент
	 *
	 * @param {Object} item Элемент на который устанавливается фокус
	 */
	focus_item(item, noevent, force) {
		if (!item) return null;

		let _this = this, focused = _this.items.get(_this._focus_id);

		try {
			if (focused.id !== null) {
				if (focused.id == item.id && !force) return item;
				focused.$.classList.remove(_this.cls.focused);
			}
		} catch(e) {}

		_this._focus_id = item.id;
		if (item.$) item.$.classList.add(_this.cls.focused);
		if (item.$i) _this._scroll_to(item.$i);
		if (_this._on.focus.length && !noevent) _this._onfocus.start(item);
		_this.input_update();
		return item;
	}

	/**
	 * Обновление данных input
	 */
	input_update() {}

	/**
	 * Обновление данных input в режиме без флажков
	 */
	_input_upd_nochecks() {
		this.input.value = this._focus_id;
	}

	/**
	 * Обновление данных input в режиме флажка с одним состоянием
	 */
	_input_upd_check() {
		this.input.value = this.checked().join(',');
	}

	/**
	 * Обновление данных input в режиме флажка с несколькими состояниями
	 */
	_input_upd_states() {
		this.input.value = (this._checks.size ? JSON.stringify(Object.fromEntries(this._checks)) : '');
	}

	/**
	 * Возвращает true, если дерево пустое.
	 */
	is_empty() {
		try {
			return !this.root.children.length;
		} catch(e) {
			return true;
		}
	}

	/**
	 * Загрузка содержимого дерева.
	 * @param parent_id Идентификатор родительского элемента для загрузки содержимого.
	 */
	load(parent_id, oncomplete, internal) {
		const _this = this;
		++_this._loads;
		if (parent_id === undefined) parent_id = null;

		let node;
		// Индикатор загрузки
		try {
			((node = _this.items.get(parent_id)) || _this.root).loading = true;
		} catch(e) {
			console.error(e);
		}

		// Получение элементов дерева
		if (!internal) {
			internal = {};
			if (typeof oncomplete === 'function') internal.onloadcomplete = oncomplete;
		}
		let load_data = _this.options.load_data;
		if (typeof load_data === 'function') load_data = load_data.call(_this, parent_id);
		_this._load.call(_this, parent_id, data => _this.ondata(data, parent_id, internal), load_data, node);
	}

	/**
	 * Добавление орбработчика для события.
	 */
	on(event, handler) {
		if (this._on[event] === undefined) throw 'Wrong event "' + event + '"';
		if (typeof handler !== 'function') throw 'Wrong handler';
		this._on[event].push(handler);
	}

	/**
	 * Обработка полученных данных
	 */
	ondata(data, parent_id, internal) {
		const _this = this;

		if (parent_id === undefined) parent_id = null;

		// Уменьшение счётчика загрузок
		if (--_this._loads < 0) _this._loads = 0;

		if (typeof data === 'string') {  // Передано сообщение об ошибке?
			console.error(data);
		} else if (typeof data === 'object') {  // Переданы нормальные данные
			_this.add(data, parent_id);

			// Развернуть элементы
			let item;
			for (let i of _this._expanded) {
				if ((item = _this.items.get(i)) && (item.children || {}).size) _this.expand(item, undefined, internal);
			}
		}

		// Скрыть индикатор загрузки
		try {
			(_this.items.get(parent_id) || _this.root).loading = false;
		} catch(e) {
			console.error(e);
		}

		// Вызвать обработчик события
		if (!_this._loads) {
			if (internal && internal.onloadcomplete) internal.onloadcomplete.call(_this);
			if (_this._on.load.length) _this._event('load');
		}
	}

	/**
	 * Перезагрузить дерево с сохранением текущего состояния элементов
	 */
	refresh(focus_id) {
		let _this = this;

		// Развернутые элементы
		/*
		var expanded = _this.expanded();
		if (path && path.length) expanded = expanded.concat(path);
		data[_this.options.ajax_expanded] = JSON.stringify(expanded);
		*/
		_this.clear(true);
		_this.load(undefined, () => _this._restore_focus(focus_id));
	}

	/**
	 * Восстановление фокуса. Вызывается из .refresh()
	 */
	_restore_focus(focus_id) {
		const _this = this;
		if (_this._loads) return;
		let item = ((focus_id !== undefined) && _this.items.get('' + focus_id)) || _this.current();
		if (item) setTimeout(() => _this.focus_item(item, false, true), 0);
	}

	/**
	 * Если необходимо, прокрутить до элемента, чтобы он стал видимым.
	 * @param {Element} node
	 */
	_scroll_to(node) {
		const pos = {l: node.offsetLeft, t: node.offsetTop, h: node.offsetHeight};
		while (node = node.offsetParent) {
			if ((node.clientHeight + 4) < node.scrollHeight) {
				// Проверка прокрутки
				if (pos.t < node.scrollTop) {
					node.scrollTop = pos.t;
				} else if ((pos.t + pos.h) > (node.scrollTop + node.clientHeight) && pos.h <= node.clientHeight) {
					node.scrollTop = pos.t + pos.h - node.clientHeight;
				}
			}
			pos.t += node.offsetTop;
			pos.l += node.offsetLeft;
		}
	}

	/**
	 * Развернуть/свернуть элемент дерева
	 */
	toggle(item, subitems, noevent) {
		let _this = this;

		if (typeof item !== 'object' && !(item = _this.items.get(item))) return;

		if (item.children) {
			if (item.collapsed) {
				_this.expand(item, subitems, {toggle_event: !noevent});
			} else {
				_this.collapse(item, subitems);
				if (!noevent) _this._ontoggle_exec();
			}
		}
	}

	/**
	 * Снять флажок элемента
	 * @param Object item Элемент
	 */
	_uncheck(item) {
		if (item.checked === undefined) return;
		const _this = this;

		_this._checks.delete(item.id);
		(item.$ && item.$.removeAttribute('data-state'));

		// Отобразить, что есть флажки в подэлементах
		if (_this._subchecks) {
			let i, parent = item, _subchecks = _this._subchecks;
			while ((parent = parent.parent) && parent !== _this.root) {
				if ((i = ((_subchecks.get(parent.id) || 1) - 1)) > 0) {
					_subchecks.set(parent.id, i);
				} else {
					_subchecks.delete(parent.id);
					parent && parent.$ && parent.$.classList.remove(_this.cls.subchecks);
				}
			}
		}
		delete item.checked;
		_this.input_update();
	}

	/**
	 * Снять все флажки.
	 */
	uncheck_all(event) {
		const
			_this = this,
			items = _this.items,
			_event = [];
		let item;
		for (let id of _this._checks.keys()) {
			try {
				delete (item = items.get(id)).checked;
				item.$.removeAttribute('data-state');
				if (event) _event.push(id);
			} catch(e) {}
		}
		_this._checks.clear();
		_this._subchecks.clear();

		if (_event.length && _this._on.check.length && event) _this._oncheck.start();
	}


	/**
	 * Перемещение элемента
	 * @param item Перемещаемый элемент
	 * @param target Перемещение выполняется относительно этого элемента
	 * @param place Положение item относительно target: 'before', 'after'
	 */
	move_item(item, target, place) {
		throw "Method TreeView.move_item() removed";

		const
			target_parent = target.parent,
			target_index = target_parent.children.indexOf(target),
			parent = item.parent,
			index = parent.children.indexOf(item);
		if (place == 'before') {
			if (index >= 0) parent.children.splice(index, 1);
			target_parent.children.splice(target_index, 0, item);
			if (item.$) {
				if (target.$) {
					target.$.insertAdjacentElement('beforebegin', item.$);
				} else {
					item.$.remove();
				}
			}
		} else if (place == 'after') {
			if (index >= 0) parent.children.splice(index, 1);
			target_parent.children.splice(target_index + 1, 0, item);
			if (item.$) {
				if (target.$) {
					target.$.insertAdjacentElement('afterend', item.$);
				} else {
					item.$.remove();
				}
			}
		}
	}

}


/**
 * Элемент дерева
 */
class TreeViewItem {

	_attrs = new Set(['id', 'text', 'html', 'hint', 'checked', 'children', 'collapsed', 'parent_id']);

	/**
	 * @param {TreeView} tree - Дерево в который добавляется элемент
	 * @param {object} item - Данные элемента
	 */
	constructor(tree, item) {
		const _this = this;

		_this.tree = tree;

		if (item) {
			if (item.id === undefined) throw `TreeViewItem: attribute "id" is undefined`;
			_this._id = item.id === null ? '' : '' + item.id;
			_this.data = {};
			_this.update(item);

			// В данных не был указан parent_id добавляем к корню.
			if (!('_parent_id' in _this)) _this.parent_id = tree.root.id;

			// Появился родительский элемент для сирот
			const orphans = tree._orphans.get(_this.id);
			if (orphans !== undefined) {
				if (!(Array.isArray(_this.children))) for (const child of orphans) _this.add(child);
				tree._orphans.delete(_this.id);
			}

		} else {
			// Корневой элемент дерева.
			if (tree.root) throw 'Root item already created';
			_this._id = null;
		}
		tree.items.set(_this.id, _this);
	}

	/**
	 * Добавить подэлемент
	 * @param {TreeViewItem} item
	 */
	add(item) {
		const _this = this;
		try { _this._childs.push(item); } catch (e) { _this._childs = [item]; }
		item.index = _this._childs.length - 1;
		item._parent = _this;

		// Если необходимо - прорисовываем
		if (_this.$) {
			if (!_this.$$) _this._children();
			_this.$$.appendChild(item.$ || item.render());
		}
	}

	/**
	 * Получить подэлементы
	 */
	get children() {
		return this._childs;
	}

	/**
	 * Установить подэлементы
	 */
	set children(value) {
		this._childs = value;
	}

	/**
	 * Создаёт узел children
	 */
	_children() {
		const _this = this, tree = _this.tree, children = _this.children, cnode = (_this.$$ = document.createElement('DIV'));

		_this.$.classList.add(tree.cls.children);
		if (_this.collapsed !== false && (_this.collapsed || children === true || !tree.options.default_expanded)) {
			_this.collapsed = true;
			_this.$.classList.add(tree.cls.collapsed);
		} else {
			tree._expanded.add(_this.id);
		}

		cnode.className = tree.cls.subitems;
		_this.$.appendChild(cnode);

		// if (children !== true) {
		// 	let k = 0;
		// 	for (let subitem of children) {
		// 		subitem.parent = item;
		// 		cnode.appendChild(subitem.$);
		// 	}
		// }
	}

	/**
	 * Первый подэлемент текущего элемента
	 *
	 * @param {boolean} hidden - Учитывать скрытые
	 *
	 * Возвращает TreeViewItem или undefined, если first() вызван для root элемента пустого дерева.
	 */
	first(hidden) {
		let _this = this;

		if (_this.children && _this.children.length && (hidden || !_this.collapsed)) return _this.children[0];

		return _this.tree.root === _this ? undefined : _this;
	}

	/**
	 * Получить подсказку элемента
	 */
	get hint() {
		return this._hint;
	}

	/**
	 * Установить текстовое представление элемента
	 */
	set hint(value) {
		this._hint = value;
		if (this.$i) this.$i.title = value;
	}

	/**
	 * Получить HTML представление элемента
	 */
	get html() {
		const _this = this;
		if (_this._html !== undefined) return _this._html;
		if (_this.$l) return _this.$l.innerHTML;
		if (_this._text !== undefined) {
			const div = document.createElement('div');
			div.innerText = _this._text;
			return div.innerHTML;
		}
	}

	/**
	 * Установить текстовое представление элемента
	 */
	set html(value) {
		this._html = value;
		if (this.$l) this.$l.innerHTML = value;
	}

	get id() {
		return this._id;
	}

	/**
	 * Последний подэлемент во всей иерархии текущего элемента
	 *
	 * @param {boolean} hidden - Учитывать скрытые
	 *
	 * Возвращает TreeViewItem или undefined, если last() вызван для root элемента дерева.
	 */
	last(hidden) {
		let _this = this;

		if (_this.children && _this.children.length && (hidden || !_this.collapsed)) {
			// Опускаемся по иерархии ниже
			return _this.children[_this.children.length-1].last(hidden);
		}

		return _this.tree.root === _this ? undefined : _this;
	}

	/**
	 * Получить уровень
	 */
	get level() {
		const _this = this;
		if (_this._level !== undefined && _this._lparent === _this.parent) return _this._level;
		return (_this._level = ((_this._lparent = _this.parent) ? _this.parent.level + 1 : -1));
	}

	/**
	 * Получить режим loading
	 */
	get loading() {
		return this._loading;
	}

	/**
	 * Установить режим loading
	 */
	set loading(value) {
		const _this = this;
		_this._loading = value;
		if (_this.$) _this.$.classList[value ? 'add' : 'remove'](_this.tree.cls.loading);
	}

	/**
	 * Получить следующий элемент.
	 * @param {boolean} hidden Выбирать даже, если элемент скрыт
	 * @param {boolean} nochild Заходить в подэлементы
	 *
	 * Возвращает TreeViewItem или undefined, если достигнут конец дерева
	 */
	next(hidden, nochild) {
		const _this = this;

		// Достигнут конец дерева
		if (nochild && _this.tree.root === _this) return undefined;

		// Проверяем, что есть подэлементы
		if (!nochild && _this.children && _this.children.length && (hidden || !_this.collapsed)) { return _this.children[0]; }

		// Следующий элемент на текущем уровне
		const parent = _this.parent;
		let res;
		try { res = parent.children[_this.index + 1]; } catch(e) {}
		if (res !== undefined) return res;

		// Следующий за родительским
		return parent.next(hidden, true);
	}

	/**
	 * Получить родительский элемент
	 */
	get parent() {
		return this._parent;
	}

	/**
	 * Получить идентификатор родительского элемента
	 */
	get parent_id() {
		return this._parent_id;
	}

	/**
	 * Установить идентификатор родительского элемента
	 */
	set parent_id(value) {
		// Приведение значения к строке
		if (value === undefined) value = null; else if (value !== null) value = '' + value;

		// Если ничего не изменилось - выходим
		if (this.parent !== undefined && this._parent_id === value) return;  // Ничего не изменилось

		const _this = this, tree = _this.tree;

		// Перенесено в другую ветку - удалить из старой ветки
		if (_this.parent) _this.remove();

		// Установить идентификатор
		_this._parent_id = value;

		// Получение родительского элемента.
		let parent = tree.items.get(value);

		// Родительского в дереве пока нет?
		if (parent === undefined) {
			// Родительского элемента нет - проверяем сирот
			if (!(parent = tree._orphans.get(value))) tree._orphans.set(value, parent = new Set());
			parent.add(_this);
		} else {
			parent.add(_this);
		}
	}

	/**
	 * Получить предыдущий элемент.
	 * @param {boolean} hidden Выбирать даже, если элемент скрыт
	 * @param {boolean} nochild Не заходить в подэлементы
	 *
	 * Возвращает TreeViewItem или undefined, если достигнуто начало дерева
	 */
	prev(hidden, nochild) {
		const _this = this;

		// Достигнуто начало дерева
		if (_this.tree.root === _this) return undefined;

		// Предыдущий элемент на текущем уровне
		const parent = _this.parent;
		let res;
		try { res = parent.children[_this.index - 1]; } catch(e) {}
		if (res === undefined) return parent === _this.tree.root ? undefined : parent;

		return nochild ? res : res.last(hidden) || res;
	}

	/**
	 * Удалить элемент дерева.
	 * index=undefined Удаляет свой экземпляр из родительского.
	 * index=number Удаляет указанный подэлемент.
	 */
	remove(index) {
		const _this = this;
		if (index === undefined) {
			if (_this.parent) {
				_this.parent.remove(_this.index);
				delete _this.parent;
				delete _this.index;
				delete _this._parent_id;
				if (_this.$) _this.$.remove();
			}
		} else {
			const childs = _this._childs;
			try {
				childs.splice(index, 1);
				const childs_len = childs.length;
				for (let i=index;i<childs_len;i++) childs[i].index = i;
			} catch(e) {
				console.error(e);
			}
		}
	}

	/**
	 * Создаёт DOM элемент
	 */
	render() {
		const _this = this,
			tree = _this.tree,
			cls = tree.cls,
			node = (_this.$ = document.createElement('DIV')),
			id = _this.id;

		node.dataset.id = (id || (id === null || id === undefined ? '' : id));
		// Класс элемента
		node.className = cls.item;
		node.style.setProperty('--tree-level', _this.level);
		//+ (tree._subchecks && tree._subchecks.has(id) ? ' ' + cls.subchecks : '');

		// Флажок
		if (tree._checks.has(id)) node.setAttribute('data-state', tree._checks.get(id));
		else if (_this.checked !== undefined) node.setAttribute('data-state', _this.checked);

		node.innerHTML = `<div class="${cls.inner}">`
		+ (tree.options.view == 'tree' ? `<span class="${cls.toggle}"></span>` : '')
	   + (tree.states ? `<span class="${cls.check_wrapper}"><span class="${cls.checkbox}"></span></span>` : '')
	   + `<div class="${cls.label}"></div></div>`;

	   _this.$i = node.firstElementChild;
	   _this.$l = _this.$i.lastElementChild;
	   if (_this._html !== undefined) _this.$l.innerHTML = _this._html;
	   else if (_this._text !== undefined) _this.$l.innerText = _this._text;

	   if (_this._hint) _this.$i.title = _this._hint;

	   if (_this.children) _this._children();
	   //item.path = _this._node_path;

	   return node;
	}

	/**
	 * Получить текстовое представление элемента
	 */
	get text() {
		const _this = this;
		if (_this._text !== undefined) return _this._text;
		if (_this.$l) return _this.$l.innerText;
		if (_this._html !== undefined) {
			const div = document.createElement('div');
			div.innerHTML = _this._html;
			return div.innerText;
		}
	}

	/**
	 * Установить текстовое представление элемента
	 */
	set text(value) {
		this._text = value;
		if (this.$l) this.$l.innerText = value;
	}

	/**
	 * Обновление данных.
	 * @param {object} data
	 */
	update(data) {
		const _this = this, _attrs = _this._attrs;
		// Установка стандартных параметров
		for (const attr of _attrs) if (attr in data && attr !== 'id') _this[attr] = data[attr];
		for (const [attr, value] of Object.entries(data)) if (!_attrs.has(attr)) _this.data[attr] = value;
	}

}
