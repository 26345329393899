/**
 *
 * Создаёт "разделитель" между двумя объектами в контейнере позволяющий менять
 * размеры панелей.
 *
 * @class
 * @param node {Object} - Элемент-контейнер. Обычно это <div>.
 * @param options {Object} - Настройки разделителя.
 *
 */
export default class Splitter {
	/**
     * @member node DOM - Элемент для которого происходит изменение размера.
     * @member pos number - Расположение разделителя относительно элемента.
	 * -1 - до, 1 - после.
     * @member handler DOM - Собственно разделитель.
	 */

	options = {
		size: null,
		cls: "splitter",
		cls_add: '',
		tag: 'div',
		cursor: null,
		pos: 'after',
		min: 0,
		max: 0,
		axis: 'width'
	};

	css_prefix = 'splitter-' + (new Date().getTime())

	css_panels = {
		'-webkit-touch-callout': 'none !important', /* iOS Safari */
		'-webkit-user-select': 'none !important', /* Safari */
		'-khtml-user-select': 'none !important', /* Konqueror HTML */
		'-moz-user-select': 'none !important', /* Old versions of Firefox */
		'-ms-user-select': 'none !important', /* Internet Explorer/Edge */
		'user-select': 'none !important' /* Non-prefixed version, currently */
	};

	re_opts = /^data-splitter-(.+)$/;

	constructor(node, options) {
		const _this = this;

		if (typeof node !== 'object') throw 'Parameter "node" not defined';

		// Инициализация Настройки
		const opts = Object.assign({}, _this.options, typeof options === 'object' ? options : {});
		_this.options = opts;

		// Создание CSS
		_this._css = document.createElement('style');
		let _css = [];
		for (let [s, v] of Object.entries(_this.css_panels)) _css.push(s + ':' + v);
		_this._css.innerHTML = 'body.' + _this.css_prefix + ' {' + _css.join(';') + '}';
		document.head.appendChild(_this._css);

		_this.node = node;

		// Получить настройки из атрибутов элемента
		let attr_name;
		for (let name of node.getAttributeNames()) {
			if (attr_name = _this.re_opts.exec(name)) {
				opts[attr_name[1].replace('-', '_')] = node.getAttribute(name);
			}
		}

		// Создание разделителя
		const handler = document.createElement(opts.tag);
		handler.className = opts.cls + ' ' + opts.cls_add;
		if (opts.pos == 'before') {
			node.insertAdjacentElement('beforebegin', handler);
			_this.pos = -1;
		}
		else if (opts.pos == 'after') {
			node.insertAdjacentElement('afterend', handler);
			_this.pos = 1;
		}
		else if (opts.pos == 'start') {
			node.insertAdjacentElement('afterbegin', handler);
			_this.pos = -1;
		}
		else if (opts.pos == 'end') {
			node.insertAdjacentElement('beforeend', handler);
			_this.pos = 1;
		}
		else {
			throw `Incorrect value "${opts.pos}" for "pos" options`;
		}

		// Установка стиля курсора мыши
		const css = window.getComputedStyle(handler);
		let cursor = css.cursor; // текущий курсор мыши у разделителя
		if (['auto', 'default'].indexOf(cursor)>=0) {
			// Курсор заменяется на указанный в настройках
			if (!(cursor = opts.cursor)) {
				// Если необходимо определить вид курсора автоматически
				cursor = (opts.axis && opts.axis[0] == 'h' ? 'row-resize' : 'col-resize');
			}
			handler.style.cursor = cursor;
		}
		if (opts.size) handler.style[opts.axis] = opts.size;
		_this.handler = handler;

		// Обработчики мыши
		let coeff, measure, corr, active = false, cover, _width,
			onstart, onend, onmove;

		// mousedown и touchstart
		onstart = function(e){
			if (e.type == 'mousedown') {
				if (e.button != 0) return;
			} else {
				e = e.touches[0];
			}

			let coords = handler.getBoundingClientRect();
			if (opts.axis[0] == 'w')
				corr = (_this.pos < 0 ? e.clientX - coords.right : coords.left - e.clientX);
			else
				corr = (_this.pos < 0 ? e.clientY - coords.bottom : coords.top - e.clientY);

			let w = node.style[opts.axis];
			if (!w || w == 'auto') w = window.getComputedStyle(node)[opts.axis];
			_width = w;

			let [, _w, _m] = /^(-?[0-9.]+)([^0-9.]+)$/.exec(w);
			coeff = ((measure = _m) == 'px' ? 1 : _w / node[opts.axis[0] == 'w' ? 'offsetWidth' : 'offsetHeight']);

			cover = document.createElement('DIV');
			let _s = cover.style;
			_s.position = 'fixed';
			_s.left = 0;
			_s.top = 0;
			_s.width = '100vw';
			_s.height = '100vh';
			_s.zIndex = 10000;
			_s.cursor = cursor;
			_s.backgroundColor = 'transparent';
			document.body.appendChild(cover);

			// Добавление класса на время работы сплиттера
			//_this.widget.classList.add(_this.css_prefix);
			document.body.classList.add(_this.css_prefix);

			active = true;

			for (const [k, v] of Object.entries({
				'mousemove': onmove,
				'touchmove': onmove,
				'mouseup': onend,
				'touchend': onend,
				'touchcancel': onend})) {
				cover.addEventListener(k, v);
			}
		};

		// mousemove и touchmove
		onmove = function(e) {
			if (!active) return;

			if (e.type != 'mousemove') e = e.touches[0];

			let coords = node.getBoundingClientRect(), width;
			if (opts.axis[0] == 'w')
				width = ((_this.pos < 0 ? coords.right - e.clientX : e.clientX - coords.left) + corr) * coeff;
			else
				width = ((_this.pos < 0 ? coords.bottom - e.clientY : e.clientY - coords.top) + corr) * coeff;

			if (width < opts.min) width = opts.min;
			else if (opts.max && width > opts.max) width = opts.max;

			node.style[opts.axis] = (width > 0 ? width : 0) + measure;
		 };

		// mouseup и touchup
		onend = function(){
			if (!active) return;
			// Убираем вспомогательный класс
			document.body.classList.remove(_this.css_prefix);

			cover.remove();
			active = false;
			if (_width != node.style[opts.axis]) {
				const e = new Event('resize', {
					bubbles: true,
					cancelable: true
				});
				node.dispatchEvent(e);
			}
		};

		handler.addEventListener('mousedown', onstart);
		handler.addEventListener('touchstart', onstart);
	}

	/**
	 * Возвращает размер элемента, для которого  назначен разделитель
	 */
	node_size() {
		let node = this.node, axis = this.options.axis;
		if (arguments.length) {
			let w = arguments[0];
			node.style[axis] = (typeof w === 'number' ? w + 'px' : w);
		} else {
			let w = node.style[axis];
			if (!w || w == 'auto') w = window.getComputedStyle(node)[axis];
			return w;
		}
	}

	/**
	 * Назначение обработчика события
	 * @param event string - Тип события
	 * @param handler callback - Функция-обработчик.
	 * @returns ItemsList - Возвращает объект для которого была вызван метод
	 * (this).  Позволяет создавать цепочки вызовов как в jQuery.
	 */
	on(event, handler) {
		this.node.addEventListener(event, handler);
		return this;
	}

}
