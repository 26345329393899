import { Dropdown, Modal } from 'bootstrap';
import Sortable from 'sortablejs';
import TreeView from "./treeview.js";
import ObjectsInput from "./objectsinput.js";

/**
 * Элемент управления фильтрами.
 */
export class FiltersControl {

    /**
     * Классы компонентов виджета
     */
    static cls = {
        control: 'filters-control',
        panel_active: '_panel-active',
        panel_button: '_panel-btn',
        search_button: '_search-btn',
        search_input: '_search-input',
        filter_item_label: '_filter-item-label',
        filter_item_value: '_filter-item-value',
        widget: 'filters-control-wrapper'
    };

    /**
     * Настройки по умолчанию
     */
    static defaults = {
        clear_search: false,
        cls_control: 'input-group input-group-sm',
        cls_panel_button: 'btn btn-outline-secondary dropdown-toggle',
        cls_search_button: 'btn btn-outline-secondary',
        cls_search_input: 'form-control',
        cls_widget: '',
        panel_button_html: '<i class="bi bi-funnel"></i>',
        search_button_html: '<i class="bi bi-search"></i>',
        search_min_length: 2,
        search_name: 'search',
        search_timeout: 750
    };

    /**
     * Конструктор
     * @param {Element} wrapper - Элемент в котором разместить виджет
     * @param {object} options - Настройки
     */
    constructor(wrapper, options) {
        const _this = this;

        _this.options = Object.assign({}, _this.constructor.defaults, _this.defaults, options || {});
        _this.cls = Object.assign({}, _this.constructor.cls, _this.cls);

        const widget = (_this.widget = _this.widget_init());
        wrapper.appendChild(widget);
        const control = (_this.control = _this.control_init());
        widget.appendChild(control);
        control.appendChild(_this.panel_btn = _this.panel_button_init())
        control.appendChild(_this.search_input = _this.search_input_init());
        control.appendChild(_this.search_btn = _this.search_button_init())

        _this.input = _this.input_init();
        _this.panel = _this.panel_init();

    }

    /**
     * Добавление фильтров.
     * Является обёрткой для метода :js:meth:`FiltersPanel.add`
     */
    add(...filters) {
        this.panel.add(...filters);
    }

    /**
     * Создание элемента управления фильтрами и быстрого поиска.
     */
    control_init() {
        const
            _this = this,
            control = document.createElement('div');
            control.className = _this.cls.control + ' ' + _this.options.cls_control;
        return control;
    }

    /**
     * Скрыть панель фильтров
     */
    hide_panel() {
        const _this = this;
        _this.panel.widget.classList.remove('show');
        _this.widget.classList.remove(_this.cls.panel_active);
        window.removeEventListener('resize', _this.panel.onresize());
    }

    /**
     * Инициализация элемента ввода input (запускается в конструкторе)
     */
    input_init() {
        const
            _this = this,
            /**
             * Поле ввода установленных фильтров
             */
            input = new ObjectsInput(_this.search_input, {
                autocomplete: 'always',
                multiple: true,
                item_click: true,
                min_length: _this.options.search_min_length,
                input_timeout: _this.options.search_timeout,
                empty_search: true,
                load: function(id, ondata) { ondata([]); }, // Заглушка
                search: function(search, ondata) {
                    _this.trigger('filter');
                    ondata();
                },
                onitemclick: (item) => {
                    _this.show_panel();
                    setTimeout(() => _this.panel.filter[item.id].focus(), 20);
                },
                onchange: (changes) => {
                    if (changes.remove && changes.remove.length) {
                        for (let item of changes.remove) {
                            _this.panel.filter[item.id].clear(true);
                        }
                        _this.panel.apply();
                    }
                }
            });

        return input;
    }

    /**
     * Обновление информации об установленных фильтрах
     */
    input_update() {
        const
            _this = this,
            input = _this.input,
            filters = _this.panel.values(),
            search_text = _this.search_text;

        // Очистка всех настроек
        input.clear(false);

        function _make(flt, text) {
            const _item = {id: flt.name};
            if (text) {
                const _node = document.createElement('div');
                _node.innerText = text;
                _item.html = `<span class="${_this.cls.filter_item_label}">${flt.label}</span><span class="${_this.cls.filter_item_value}">${_node.innerHTML}</span>`;
            } else {
                _item.text = flt.label;
            }
            return _item;
        }

        // Добавление фильтров
        for (let flt of filters) {
            let text = flt.text;
            if (text instanceof Promise) {
                let node = document.createElement('div');
                node.innerHTML = '<span></span>: <span style="opacity: 0.5">загрузка...</span>';
                node.firstChild.innerText = flt.label;
                input.add_item({id: flt.name, html: node.innerHTML}, false);
                text.then((text) => input.update_items([_make(flt, text)]));
            } else {
                input.add_item(_make(flt, text), false);
            }
        }
        // Восстановление содержимого быстрого поиска
        _this.search_text = search_text;
    }

    /**
     * Возвращает ``true``, если панель фильтров отображается.
     */
    get panel_active() {
        return this.panel.widget.classList.contains('show');
    }

    /**
     * Инициализация панели фильтров (запускается в конструкторе)
     */
    panel_init() {
        const _this = this,
            panel_opts = Object.assign({filters: _this.options.filters}, _this.options.panel),
            onapply = panel_opts.onapply,
            oncancel = panel_opts.oncancel;

        panel_opts.onapply = function(){ onapply && onapply.apply(this,arguments); _this._panel_onapply()};
        panel_opts.oncancel = function(){ oncancel && oncancel.apply(this,arguments); _this._panel_oncancel()};

        return new FiltersPanel(_this, panel_opts);
    }

    /**
     * Создание кнопки активации панели
     */
    panel_button_init() {
        const
            _this = this,
            button = document.createElement('button');
        button.className = _this.cls.panel_button + ' ' + _this.options.cls_panel_button;
        button.type = 'button';
        button.innerHTML = _this.options.panel_button_html;
        // Показывать/скрывать панель по нажатию на кнопку
        button.addEventListener('click', () => _this.toggle_panel());
        return button;
    }

    /**
     * Обработчик события панели onapply
     */
    _panel_onapply() {
        const _this = this;
        _this.hide_panel();
        if (_this.options.clear_search) _this.search_text = undefined;
        _this.input_update();
        _this.trigger('filter');
    }

    /**
     * Обработчик события панели oncancel
     */
    _panel_oncancel() {
        const _this = this;
        _this.hide_panel();
    }

    /**
     * Создание кнопки поиска
     */
    search_button_init() {
        const
            _this = this,
            button = document.createElement('button');
        button.className = _this.cls.search_button + ' ' + _this.options.cls_search_button;
        button.type = 'button';
        button.tabIndex = -1;
        button.innerHTML = _this.options.search_button_html;
        button.addEventListener('click', () => _this.trigger('filter'));
        return button;
    }

    /**
     * Создание поля быстрого поиска
     */
    search_input_init() {
        const
            _this = this,
            input = document.createElement('input');
        input.className = _this.cls.search_input + ' ' + _this.options.cls_search_input;
        return input;
    }

    /**
     * Получить строку быстрого поиска
     */
    get search_text() {
        return this.input.autocomplete.value;
    }

    /**
     * Установить строку быстрого поиска
     */
    set search_text(val) {
        this.input.autocomplete.value = ((val === undefined || val === null) ? '' : val);
    }

    /**
     * Показать панель фильтров
     */
    show_panel() {
        const _this = this, panel = _this.panel;
        panel.widget.classList.add('show');

        // Расчёт максимального размера панели
        const control_rect = _this.control.getBoundingClientRect(), style = panel.widget.style;
        setTimeout(function() {
            style['max-height'] = `calc( 100vh - ${control_rect.bottom}px - 1rem )`;
        }, 1);
        _this.widget.classList.add(_this.cls.panel_active);

        // Перерисовка панели
        panel.update();

        window.addEventListener('resize', panel.onresize());
    }

    /**
     * Получить или установить текущее состояние фильтров.
     * Параметры:
     * (states, apply)
     */
    state() {
        let states, apply = true;
        for (const arg of arguments) {
            if (typeof arg === 'object') states = arg;
            else if (typeof arg === 'boolean') apply = arg;
        }

        const _this = this;
        if (states) {
            // Установить настройки
            if (states.hasOwnProperty("filters")) {
                _this.panel.filters = states.filters;
            }
            if (states.hasOwnProperty("value")) {
                _this.value = states.value;
            }
            if (apply) _this.panel.apply();
        } else {
            // Получить настройки
            return {
                filters: _this.panel.filters,
                value: _this.value
            };
        }
    }

    /**
     * Показать/скрыть панель фильтров
     */
    toggle_panel() {
        if (this.panel_active) this.hide_panel(); else this.show_panel();
    }

    /**
     * Вызвать обработчик события.
     * Доступные обработчики:
     * "filter"
     */
    trigger(name, ...args) {
        const _this = this;
        if (typeof _this.options[name = ('on' + name)] === 'function') {
            if (name == 'onfilter') args.unshift(_this.value);
            _this.options[name].apply(_this, args);
        }
    }

    /**
     * Получить установленные фильтры.
     * Добавляет строку быстрого поиска.
     */
    get value() {
        const _this = this;
        let res = _this.panel.value;
        if (_this.search_text.trim().length >= _this.options.search_min_length) {
            res[_this.options.search_name] = _this.search_text;
        }
        return res;
    }

    /**
     * Установить значения фильтров
     */
    set value(values) {
        const _this = this;
        _this.search_text = values[_this.options.search_name];
        _this.panel.value = values;
        _this.input_update();
    }

    /**
     * Создание корневого элемента
     */
    widget_init() {
        const
            _this = this,
            widget = document.createElement('div');
        widget.className = _this.cls.widget + ' ' + _this.options.cls_widget;
        return widget;
    }

}


export default class FiltersPanel {

    /**
     * Классы компонентов виджета
     */
    static cls = {
        widget: 'filters-panel',
        filters: '_filters',
        buttons: '_buttons',
        button_apply: '_apply-btn',
        button_cancel: '_cancel-btn',
        button_clear: '_clear-btn',
        button_add: '_add-btn',
        add_wrapper: '_add-wrapper',
        filter_label: '_filter-label'
    };

    /**
     * Настройки по умолчанию
     */
    static defaults = {
        button_add: {
            cls: 'btn-light btn-sm dropdown-toggle',
            label: '<i class="bi-plus-lg"></i> Добавить фильтр...',
            hint: 'Добавить условие фильтрации',
            click: 'add_filter_menu'
        },
        button_apply: {
            cls: 'btn-success',
            label: '<i class="bi-check-lg d-none d-sm-inline-block"></i> Применить',
            hint: 'Выполнить фильтрацию',
            click: 'apply'
        },
        button_cancel: {
            cls: 'btn-secondary',
            label: '<i class="bi-arrow-counterclockwise d-none d-sm-inline-block"></i> Отменить',
            hint: 'Отменить изменения фильтров',
            click: 'cancel'
        },
        button_clear: {
            cls: 'btn-outline-danger ms-auto',
            label: '<i class="bi-x-lg"></i> <span class="d-none d-sm-inline">Сбросить</span>',
            hint: 'Сбросить настройки всех фильтров',
            click: 'clear'
        },
        button_presets: {
            cls: "btn-primary dropdown-toggle",
            label: "<i class=\"bi bi-funnel\"></i> Сохранённые фильтры",
            hint: "Сохранить или восстановить настройки фильтров",
            dropdown: function(...args){return this.presets_menu.apply(this, args)}
        },

        buttons: ['apply', 'cancel', 'clear'],
        cls_add_wrapper: '',
        cls_button: 'btn',
        cls_buttons: '',
        cls_filters: '',
        cls_dropdown_error: 'alert alert-danger m-2 px-2 py-1 small',
        cls_widget: '',
        dropdown_loading: '<div class="text-center"><i class="spinner-border spinner-border-sm opacity-5"></i></div>',
        static: false
    };

    /**
     * Конструктор
     * @param {FiltersControl|Element} control - Элемент управления или узел DOM в котором размещается панель
     * @param {object} options - Настройки
     */
    constructor(control, options) {
        const
            _this = this;

        // Связана с FiltersControl
        if (control instanceof FiltersControl) {
            control = (_this.control = control).widget;
        }

        // Настройки
        _this.options = Object.assign({}, _this.constructor.defaults, _this.defaults, options);
        _this.cls = Object.assign({}, _this.constructor.cls, _this.cls);

        // Widget
        const widget = (_this.widget = _this.widget_init());
        control.appendChild(widget);
        _this._filters = [];
        _this.filter = {};
        _this.buttons = {};

        // Filters panel
        widget.appendChild(_this.filters_wrapper = _this.filters_wrapper_init());

        // Button "Add filter"
        if (!_this.options.static) widget.appendChild(_this.add_wrapper_init());

        // Buttons
        if (_this.buttons_wrapper = _this.buttons_init()) widget.appendChild(_this.buttons_wrapper);

        if (_this.options.filters) _this.add.apply(_this, _this.options.filters);

    }

    /**
     * Добавить фильтры на панель.
     */
    add(...filters) {
        const _this = this;
        let update = false;

        for (const flt of filters) {
            try {
                // Проверка наличия фильтра с указанным именем
                if (_this.filter[flt.name] !== undefined) throw `Duplicate filter name "${flt.name}"`;

                _this.filter[flt.name] = flt;
                if (!flt.options.hide) {
                    _this.filters.push(flt.name);
                    update = true;
                }
                flt.panel = _this;
            } catch(e) {
                console.error(e);
            }
        }

        if (update) _this.update();
    }

    /**
     * Нажатие на кнопку добавления фильтра
     */
    add_filter_menu() {
        const
            _this = this;

        if (_this._add_filter_menu) return;

        // Создание меню списка доступных фильтров
        const menu = document.createElement('div');

        menu.className = 'dropdown-menu';

        // Добавление фильтра при нажатии на пункт меню
        menu.addEventListener('click', evt => {
            let target = evt.target;
            while (target && target !== menu) {
                if (target.dataset.name) {
                    _this.show_filter(target.dataset.name, true);
                    setTimeout(() => {
                        _this.buttons.add.scrollIntoView(true);
                        _this.filter[target.dataset.name].focus();
                        }, 50);
                    return;
                }
                target = target.parentNode;
            }
        });

        const
            btn = _this.buttons.add,

            // Вычисление максимальной высоты меню.
            // Вызывается при изменении размера окна и при скроллинге
            resize = () => {
                const
                    bounds = btn.getBoundingClientRect(),
                    height = Math.max(bounds.top, document.body.clientHeight - bounds.bottom);
                menu.style.maxHeight = `calc( ${height}px - 0.5rem )`;
            },
            // К каким элементам привязан обработчик resize
            resize_targets = [window];

        // Заполнение меню списка доступных фильтров при каждом показе
        btn.addEventListener('show.bs.dropdown', () => {
            menu.style.overflow = 'auto';
            menu.innerHTML = '';
            let item;
            for (const flt of Object.values(_this.filter).sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)) {
                if (_this.filters.indexOf(flt.name) >= 0) continue;
                menu.insertAdjacentHTML('beforeend', '<button class="dropdown-item" type="button"></button>');
                item = menu.lastElementChild;
                item.innerText = flt.label;
                item.dataset.name = flt.name;
            }
            if (!menu.childNodes.length) {
                menu.innerHTML = '<div class="text-muted px-2 small">Фильтров больше нет</div>';
            }
            // Выносим за пределы панели для корректного отображения
            document.body.appendChild(menu);

            // Вычисляем максимальную высоту меню
            resize();

            // Назначение обработчика resize элементам
            let parent = btn.parentNode;
            while (parent && parent !== document.documentElement) {
                if (parent.clientHeight < parent.scrollHeight) resize_targets.push(parent);
                parent = parent.parentNode;
            }
            for (const node of resize_targets) node.addEventListener('resize', resize);
        });

        // Возвращение меню в панель после скрытия
        btn.addEventListener('hidden.bs.dropdown', () => {
            btn.insertAdjacentElement('afterend', menu);
            for (const node of resize_targets) node.removeEventListener('resize', resize);
        });

        btn.setAttribute('data-bs-toggle', 'dropdown');
        btn.setAttribute('aria-expanded', 'false');
        btn.insertAdjacentElement('afterend', menu);

        _this._add_filter_menu = new Dropdown(_this.buttons.add);
        _this._add_filter_menu.show();
    }

    /**
     * Создание панели добавления фильтров
     */
    add_wrapper_init() {
        const _this = this, wrapper = document.createElement('div');
        wrapper.className = _this.cls.add_wrapper + ' ' + _this.options.cls_add_wrapper;
        wrapper.tabIndex = -1;
        _this._create_button('add', wrapper);
        return wrapper;
    }

    /**
     * Применить фильтры
     */
    apply() {
        const _this = this;
        for (let name of _this.filters) _this.filter[name].apply();
        _this.state_save();
        _this.trigger('apply');
    }

    /**
     * Создание панели кнопок
     */
    buttons_init() {
        const _this = this, options = _this.options;
        if (!options.buttons || !options.buttons.length) return;

        const wrapper = document.createElement('div');
        wrapper.className = _this.cls.buttons + ' ' + _this.options.cls_buttons;
        wrapper.tabIndex = -1;
        for (const name of options.buttons) _this._create_button(name, wrapper);
        return wrapper;
    }

    /**
     * Отменить изменения
     */
    cancel() {
        const _this = this;
        for (let name of _this.filters) _this.filter[name].restore();
        _this.state_restore();
        _this.trigger('cancel');
    }

    /**
     * Сбросить
     */
    clear() {
        const _this = this;
        for (let name of _this.filters) _this.filter[name].clear(false);
        _this.trigger('clear');
    }

    /**
     * Диалог подтверждения.  Открывается диалог bootstrap.Modal
     * options: {
     *   body: "Текст запроса",    // Текст диалога
     *   ok_label: "OK",           // Текст кнопки подтверждения, если cancel_label вычисляется как false, то кнопка отсутствует
     *   ok_class: "btn-primary",  // Дополнительные классы кнопки подтверждения
     *   ok: function() {
     *      // Будет выполнена при нажатии кнопки подтверждения
     *      // Если возвращает false - диалог не будет закрыт.
     *   },
     *   onhide: function() {
     *      // Будет выполняться после скрытия диалога
     *   },
     *   cancel_label: "Отмена",         // Текст кнопки отмены, если cancel_label вычисляется как false, то кнопка отсутствует
     *   cancel_class: "btn-secondary",  // Дополнительные классы кнопки отмены
     * }
     */
    confirm(options) {
        const
            _this = this,
            dialog = document.createElement('div'),
            opts = Object.assign({
                ok_label: 'ОК',
                ok_class: 'btn-primary',
                cancel_label: 'Отмена',
                cancel_class: 'btn-secondary'
            }, options);
        let modal, error_node; // bootstrap.Modal instance, error Element

        dialog.className = 'modal fade';
        dialog.innerHTML = '<div class="modal-dialog"><div class="modal-content"><div class="modal-body"></div><div class="modal-footer"></div></div></div>';

        const
            body = dialog.querySelector('.modal-body'),
            footer = dialog.querySelector('.modal-footer');

        body.innerText = opts.body || '';

        if (opts.ok_label) {
            const ok_btn = document.createElement('button');
            ok_btn.className = `btn ${opts.ok_class}`;
            ok_btn.innerText = opts.ok_label;
            ok_btn.type = 'button';
            ok_btn.addEventListener('click', () => {
                if (error_node) { error_node.remove(); error_node = undefined; }
                if (typeof opts.ok === 'function') {
                    bs_btn_loading(ok_btn);
                    let res = false;
                    try {
                        res = opts.ok(dialog);
                    } catch (e) {
                        console.error(e);
                    }
                    if (res === false || (typeof res === 'object' && res.error)) {

                        error_node = document.createElement('div');
                        error_node.className = _this.options.cls_dropdown_error;
                        error_node.innerText = res === false ? 'Ошибка выполнения' : res.error;
                        body.insertAdjacentElement('beforeend', error_node);

                        bs_btn_loading(ok_btn, false);
                        return;
                    }
                }
                modal.hide();
            });
            footer.appendChild(ok_btn);
        }
        if (opts.cancel_label) {
            const cancel_btn = document.createElement('button');
            cancel_btn.className = `btn ${opts.cancel_class}`;
            cancel_btn.innerText = opts.cancel_label;
            cancel_btn.type = 'button';
            cancel_btn.dataset.bsDismiss = "modal";
            footer.appendChild(cancel_btn);
        }

        dialog.addEventListener('hidden.bs.modal', () => {
            modal.dispose();
            dialog.remove();
            if (typeof opts.onhide === 'function') opts.onhide();
        });
        document.body.appendChild(dialog);
        (modal = new Modal(dialog)).show();
        return modal;
    }

    /**
     * Создание кнопки
     * @param {string} name - Имя кнопки
     * @param {Element} container - Контейнер в который добавляется кнопка
     * @returns Элемент <button>
     */
    _create_button(name, container) {
        const
            _this = this,
            options = _this.options,
            btn = options['button_' + name];

        if (!btn) {
            console.error(`Not defined option "button_${name}"`);
            return;
        }

        const btn_node = (_this.buttons[name] = document.createElement('button'));
        btn_node.className = options.cls_button + ' ' + (btn.cls || '');
        if (btn.label) btn_node.innerHTML = btn.label;
        if (btn.hint) btn_node.title = btn.hint;
        if (btn.click) {
            if (typeof btn.click === 'string' && typeof _this[btn.click] === 'function') {
                btn_node.addEventListener('click', event => _this[btn.click](event));
            } else if (typeof btn.click === 'function') {
                btn_node.addEventListener('click', event => btn.click(event));
            } else {
                console.error(`Wrong value of attribute "click": ${btn.click}`, btn.click);
            }
        }
        btn_node.disabled = btn.disabled;
        container.appendChild(btn_node);

        // Create dropdown
        if (btn.dropdown) {
            btn_node.setAttribute('data-bs-toggle', 'dropdown');
            btn_node.setAttribute('aria-expanded', 'false');
            const dropdown_node = document.createElement(btn.dropdown_tag || 'div'),
                // Вычисление максимальной высоты меню.
                // Вызывается при изменении размера окна и при скроллинге
                resize = () => {
                    const
                        bounds = btn_node.getBoundingClientRect(),
                        height = Math.max(bounds.top, document.body.clientHeight - bounds.bottom);
                    dropdown_node.style.maxHeight = `calc( ${height}px - 0.5rem )`;
                },
                // К каким элементам привязан обработчик resize
                resize_targets = [window];
            let dropdown_instance;

            // Заполнение меню списка доступных фильтров при каждом показе
            btn_node.addEventListener('show.bs.dropdown', () => {
                dropdown_node.style.overflow = 'auto';
                // Выносим за пределы панели для корректного отображения
                document.body.appendChild(dropdown_node);
                // Вычисляем максимальную высоту меню
                resize();
                // Назначение обработчика resize элементам
                let parent = btn_node.parentNode;
                while (parent && parent !== document.documentElement) {
                    if (parent.clientHeight < parent.scrollHeight) resize_targets.push(parent);
                    parent = parent.parentNode;
                }
                for (const node of resize_targets) node.addEventListener('resize', resize);
            });

            // Предотвращение скрытия
            btn_node.addEventListener('hide.bs.dropdown', (e) => {
                if (btn_node.dataset.closePrevent) { e.preventDefault(); return false; }
            });

            // Возвращение меню в панель после скрытия
            btn_node.addEventListener('hidden.bs.dropdown', () => {
                btn_node.insertAdjacentElement('afterend', dropdown_node);
                for (const _node of resize_targets) _node.removeEventListener('resize', resize);
            });

            dropdown_node.className = 'dropdown-menu';
            if (typeof btn.dropdown === 'string') {
                dropdown_node.innerHTML = btn.dropdown;
            } else if (typeof btn.dropdown === 'function') {
                btn_node.addEventListener('show.bs.dropdown', () => {
                    const content = btn.dropdown.call(_this, dropdown_node, btn_node, dropdown_instance);
                    if (typeof content === 'string') {
                        dropdown_node.innerHTML = content;
                    } else if (content instanceof Promise) {
                        dropdown_node.innerHTML = _this.options.dropdown_loading;
                        content
                            .then((result) => dropdown_node.innerHTML = result)
                            .catch((reason) => dropdown_node.innerHTML = _this._presets_error(reason))
                            .finally(() => dropdown_instance.update());
                    }
                });
            }
            btn_node.insertAdjacentElement('afterend', dropdown_node);
            dropdown_instance = new Dropdown(btn_node, {autoClose: 'outside'});
        }

        return btn_node;
    }

    /**
     * Получить массив имён видимых фильтров
     */
    get filters() {
        return this._filters;
    }

    /**
     * Установить массив имён видимых фильтров
     */
    set filters(value) {
        const _this = this;

        if (!value) value = [];

        // Скрыть фильтры, которых нет в value
        for (const name of [..._this.filters]) {
            if (value.indexOf(name) < 0) {
                _this.hide_filter(name, false, true);
            }
        }

        _this._filters = value;
        _this.update();
        //if (_this.control) _this.control.input_update();
    }

    /**
     * Создание панели фильтров
     */
    filters_wrapper_init() {
        const _this = this, filters = document.createElement('div');
        filters.className = _this.cls.filters + ' ' + _this.options.cls_filters;
        filters.tabIndex = -1;

        if (typeof Sortable !== 'undefined') {
            _this.sortable = new Sortable(filters, {
                direction: 'vertical',
                handle: '.' + _this.cls.filter_label,
                onUpdate: () => {
                    _this.sync_filters();
                }
            });
            filters.dataset.sortable = 1;
        }

        return filters;
    }

    /**
     * Скрыть фильтр
     */
    hide_filter(name, event, no_update_label_width) {
        const
            _this = this,
            flt = _this.filter[name];
        if (!flt) {
            console.error(`Filter "${name}" not defined`);
            return;
        }
        let i;
        while ((i = _this.filters.indexOf(name)) >= 0) _this.filters.splice(i, 1);
        flt.widget_dispose();
        if (!no_update_label_width) _this.update_label_width();
    }

    /**
     * Возвращает функцию обработчик изменения размеров панели
     */
    onresize() {
        if (this._onresize) return this._onresize;
        const
            _this = this,
            _onresize = () => {
                _this._onresize_timer = undefined;
                _this.update_label_width();
            };
        return (_this._onresize = () => {
            if (_this._onresize_timer) clearTimeout(_this._onresize_timer);
            _this._onresize_timer = setTimeout(_onresize, 20);
        });
    }

    /**
     * Отображение ошибки меню presets
     */
    _presets_error(reason) {
        const tpl = document.createElement('div');
        tpl.innerHTML = `<div class="${this.options.cls_dropdown_error}"></div>`;
        tpl.firstChild.innerText = reason;
        return tpl.innerHTML;
    }

    /**
     * Заполнение меню presets
     */
    _presets_items(presets) {
        const res = [];

        if (presets.length) res.push('<hr class="dropdown-divider">');

        const tpl = document.createElement('div');
        for (const preset of presets) {
            try {
                tpl.innerText = preset.label;
                res.push(`<a href="#" class="dropdown-item d-flex align-items-center" data-action="load"><span>${tpl.innerHTML}</span>`
                    + '<span class="ms-auto d-flex">'
                    + '<button class="btn btn-outline-danger border-0 shadow-none px-1 py-0 m-0" data-action="delete" title="Удалить..." tabindex="-1"><i class="bi bi-x"></i></button>'
                    + '<span></a>');
            } catch(e) {
                console.error(e);
            }
        }
        return res.join('');
    }

	/**
     * Меню сохранения и загрузки фильтров
     */
	presets_menu(menu, button, dropdown, presets) {
        const _this = this;

        if (presets === undefined) {
            if (typeof _this.options.presets_load === 'function') {
                presets = _this.options.presets_load.call(_this);
                if (presets instanceof Promise) {
                    return new Promise((resolve, reject) => {
                        presets
                            .then((loaded_presets) => resolve(_this.presets_menu(menu, button, dropdown, loaded_presets)))
                            .catch((reason) => reject(reason));
                    });
                }
            } else {
                throw `Wrong type of presets_load option: ${typeof _this.options.presets_load}`
            }
        }

		const
            res = ['<div class="px-2"><div class="input-group"><input type="text" class="form-control" placeholder="Введите название..."><button class="btn btn-outline-primary" type="button" data-action="save">Сохранить</button></div></div>'];

        let presets_list = [];

        if (typeof presets === 'object' && presets.error) {
            // Error
            res.push(_this._presets_error(presets.error));
        } else if (presets === false) {
            // Error
            res.push(_this._presets_error('Ошибка загрузки сохранённых настроек'));
        } else {
            // Loaded
            res.push(_this._presets_items(presets_list=presets));
        }

        const presets_names = new Set();
        for (const preset of presets_list) if (preset) presets_names.add(preset.label);

		/**
		 * Привязка обработчиков
		 */
		if (!menu.dataset.presets) {
			menu.dataset.presets = true;
            menu.addEventListener('keydown', event => {
                if (event.key == 'Escape') {
                    dropdown.hide();
                    button.focus();
                    event.preventDefault();
                    return;
                }
                if (event.key == 'Enter' && event.target.tagName == 'INPUT') {
                    event.target.nextElementSibling.click();
                }
            });
			menu.addEventListener('click', event => {
                let target = event.target;

                while (target && target !== menu) {

                    // Восстановление настройки
                    if (target.dataset.action == 'load') {

                        let preset, label = target.firstElementChild.innerText;
                        for (const item of presets_list) {
                            if (item.label == label) {
                                preset = JSON.parse(JSON.stringify(item));  // deep copy
                                break;
                            }
                        }

                        if (preset) {
                            _this.state(preset, false);
                            dropdown.hide();
                        } else if (!target.firstElementChild.nextElementSibling.classList.contains('text-danger')) {
                            target.firstElementChild.insertAdjacentHTML('afterend', '<span class="text-danger ms-1">- ошибка</span>');
                        }
                        break;
                    }

                    // Сохранение настроек
                    if (target.dataset.action == 'save') {
                        const
                            label_input = target.previousElementSibling,
                            label = label_input.value;
                        if (!label) {
                            label_input.focus();
                            break;
                        }

                        button.dataset.closePrevent = true;
                        const
                            save_resolve = () => {
                                button.dataset.closePrevent = '';
                                dropdown.hide();
                            },
                            save_reject = (reason) => {
                                _this.confirm({
                                    body: reason,
                                    ok_label: false,
                                    onhide: () => {
                                        button.dataset.closePrevent = '';
                                    }
                                });
                            },
                            save = () => {
                                try {
                                    const res = _this.options.presets_save.call(_this, label, _this.state());
                                    if (res instanceof Promise) {
                                        res.then(save_resolve).catch(save_reject);
                                    } else if (res === false) {
                                        save_reject('Ошибка сохранения');
                                    } else if (typeof res === 'object' && res.error) {
                                        save_reject(res.error);
                                    } else {
                                        save_resolve();
                                    }
                                } catch(e) {
                                    console.error(e);
                                    save_reject('Ошибка сохранения');
                                }
                            };

                        if (presets_names.has(label)) {
                            _this.confirm({
                                body: `Заменить существующий набор фильтров "${label}"`,
                                ok_label: 'Заменить',
                                ok: save,
                                onhide: () => {
                                    if (button.dataset.closePrevent) {
                                        button.dataset.closePrevent = '';
                                        label_input.focus();
                                    }
                                }
                            });
                        } else {
                            save();
                        }
                        break;
                    }

                    // Удаление настройки
                    if (target.dataset.action == 'delete') {
                        const menu_item = target.closest('.dropdown-item');
                        if (menu_item) {

                            const
                                label = menu_item.firstChild.innerText,
                                delete_resolve = () => {
                                    button.dataset.closePrevent = '';
                                    menu_item.remove();
                                },
                                delete_reject = (reason) => {
                                    _this.confirm({
                                        body: reason,
                                        ok_label: false,
                                        onhide: () => {
                                            button.dataset.closePrevent = '';
                                        }
                                    });
                                },
                                _delete = () => {
                                    button.dataset.closePrevent = true;
                                    try {
                                        const res = _this.options.presets_delete.call(_this, label);
                                        if (res instanceof Promise) {
                                            res.then(delete_resolve).catch(delete_reject);
                                        } else if (res === false) {
                                            delete_reject('Ошибка сохранения');
                                        } else if (typeof res === 'object' && res.error) {
                                            delete_reject(res.error);
                                        } else {
                                            delete_resolve();
                                        }
                                    } catch(e) {
                                        console.error(e);
                                        delete_reject('Ошибка сохранения');
                                    }
                                };

                            button.dataset.closePrevent = true;
                            _this.confirm({
                                body: `Удалить "${label}"?`,
                                ok_label: 'Удалить',
                                ok_class: 'btn-danger',
                                ok: _delete,
                                onhide: () => { button.dataset.closePrevent = ''; }
                            })
                        }
                        break;
                    }

                    target = target.parentElement;
                }
			});
		}

		return res.join('');
	};

    /**
     * Показать фильтр
     */
    show_filter(name, event, no_update_label_width) {
        const
            _this = this,
            flt = _this.filter[name];
        if (!flt) {
            console.error(`Filter "${name}" not defined`);
            return;
        }
        if (_this.filters.indexOf(name) < 0) _this.filters.push(name);
        _this.filters_wrapper.appendChild(flt.widget_init());
        if (!no_update_label_width) _this.update_label_width();
    }

    /**
     * Получить или установить текущее состояние фильтров.
     * Параметры:
     * (states, mode, apply)
     */
    state() {
        let states, apply = true;
        for (const arg of arguments) {
            if (typeof arg === 'object') states = arg;
            else if (typeof arg === 'boolean') apply = arg;
        }

        const _this = this;
        if (states) {
            // Установить настройки
            if (states.filters !== undefined) {
                _this.filters = states.filters;
            }
            if (states.value !== undefined) {
                _this.value = states.value;
            }
            if (apply) _this.apply();
        } else {
            // Получить настройки
            return {
                filters: _this.filters,
                value: _this.value
            }
        }
    }

    /**
     * Сохранить текущее состояние панели.  Восстановить его можно методом state_restore.
     * Восстановление производится однократно.
     */
    state_restore() {
        const _this = this;
        if (_this._saved_state) {
            _this.state(_this._saved_state, false);
            delete _this._saved_state;
        }
    }

    /**
     * Сохранить текущее состояние панели.  Восстановить его можно методом state_restore.
     * Восстановление производится однократно.
     */
    state_save() {
        this._saved_state = JSON.parse(JSON.stringify(this.state()));
    }

    /**
     * Обновляет массив имён видимых фильтров в соответствии с текущими виджетами
     */
    sync_filters() {
        const filters = [];
        try {
            for (const node of this.filters_wrapper.childNodes) {
                if (node.dataset.name) {
                    filters.push(node.dataset.name);
                }
            }
            this._filters = filters;
        } catch(e) {
            console.error(e);
        }
    }

    /**
     * Вызвать обработчик события.
     * Доступные обработчики:
     * "cancel", "apply"
     */
    trigger(name, ...args) {
        const _this = this;
        if (typeof _this.options[name = ('on' + name)] === 'function') {
            args.unshift(_this);
            _this.options[name].apply(_this, args);
        }
    }

    /**
     * Перерисовка панели
     */
    update() {
        const _this = this;
        // Отображение всех виджетов фильтров
        for (const name of _this.filters) {
            try {
                _this.filters_wrapper.appendChild(_this.filter[name].widget_init());
            } catch(e) { console.error(e); }
        }
        _this.update_label_width();
    }

    /**
     * Обновить ширину меток
     */
    update_label_width() {
        const _this = this;
        let width = 0;
        _this.widget.style.removeProperty('--filterspanel-label-width');
        // Отображение всех виджетов фильтров
        for (const name of _this.filters) {
            try {
                width = Math.max(_this.filter[name].label_node.offsetWidth, width);
            } catch(e) { console.error(e); }
        }
        if (width) _this.widget.style.setProperty('--filterspanel-label-width', `calc( ${width}px + 1em)`);
    }

    /**
     * Получить установленные фильтры.
     */
    get value() {
        const res = {}, values = this.values(true);
        for (const flt of values) res[flt.name] = flt.value;
        return res;
    }

    /**
     * Установить значения фильтров
     */
    set value(values) {
        const filters = this.filters, filter = this.filter, names = new Set(Object.keys(values));
        for (const name of names) {
            // Фильтра с указанным именем нет - игнорируем
            if (filter[name] === undefined) continue;
            // Добавляем фильтр в видимые, если необходимо
            if (!filters.includes(name)) filters.push(name);
            // Установить значение
            filter[name].value = values[name];
        }
        // Сбросить прочие значения
        for (const name of filters) if (!names.has(name)) filter[name].value = null;
        this.state_save();
    }

    /**
     * Получить массив установленных фильтров.  Каждый элемент массива - объект с атрибутами:
     * {
     *   name: "filter_name",
     *   label: "Название фильтра",
     *   value: "Значение фильтра",
     *   text: "Читаемое значение фильтра"
     * }
     */
    values(no_text, empty) {
        const
            _this = this,
            res = [];
        let flt, item, value;
        for (const name of _this.filters) {
            flt = _this.filter[name];
            item = {
                name: flt.name,
                label: flt.label
            };
            value = flt.value;
            if (!flt.is_empty_value(value)) {
                item.value = value;
                if (!no_text) item.text = flt.text;
            } else if (!empty) {
                continue;
            }
            res.push(item);
        }
        return res;
    }

    /**
     * Возвращает true, если панель видна
     */
    get visible() {
        return (this.control && this.control.panel_active) || (!this.control && this.widget.parent);
    }

    /**
     * Создание корневого элемента
     */
    widget_init() {
        const _this = this, widget = document.createElement('div');
        widget.className = _this.cls.widget + ' ' + _this.options.cls_widget;
        widget.tabIndex = -1;
        return widget;
    }

} //~class FiltersPanel


/**
 * Базовый элемент управления фильтром
 */
export class FilterInput {

    /**
     * Счётчик для уникальной нумерации элементов
     */
    static _instance_id = 0;

    /**
     * Классы компонентов виджета
     */
    static cls = {
        button_clear: "_clear-icon",
        button_remove: "_remove-icon",
        button_restore: '_restore-icon',
        dirty: '_dirty',
        input_empty: '_filter-empty',
        input: "_filter-input",
        label: "_filter-label",
        widget: "_filter-widget"
    };

    /**
     * Настройки по умолчанию
     */
    static defaults = {
        button_clear: {
            title: "Очистить фильтр",
            label: "",
            cls: "bi bi-backspace"
        },
        button_remove: {
            title: "Удалить фильтр",
            label: "",
            cls: "bi bi-x-lg"
        },
        button_restore: {
            title: "Вернуть значение фильтра",
            label: "",
            cls: "bi bi-arrow-counterclockwise"
        },
        cls_button: '',
        cls_label: 'col-form-label',
        cls_input: 'form-control'
    };

    /**
     * Конструктор
     * @param {string} name - Имя фильтра
     * @param {string} label - читаемое название фильтра
     * @param {object} options
     */
    constructor(name, label, options) {
        const _this = this;
        _this.instance_id = FilterInput.get_instance_id();
        _this.name = name;
        _this.label = label;
        _this._value = undefined;
        _this._new_value = undefined;
        _this.options = Object.assign({}, _this.constructor.defaults, _this.defaults, options);
        _this.cls = Object.assign({}, _this.constructor.cls, _this.cls);
        _this.dirty = false;
    }

    /**
     * Применить фильтр
     */
    apply() {
        const _this = this;
        if (!_this.dirty) return;
        _this.value = _this.new_value;
    }

    /**
     * Создание кнопки очистки фильтра
     */
    button_clear_init() {
        const
            _this = this,
            button = document.createElement('i'),
            opts = _this.options.button_clear;
        button.className = _this.cls.button_clear + ' ' + (_this.options.cls_button || '') + ' ' + (opts.cls || '');
        button.title = opts.title;
        button.innerHTML = opts.label;
        button.addEventListener('click', () => _this.clear())
        return button;
    }

    /**
     * Создание кнопки удаления фильтра
     */
    button_remove_init() {
        const
            _this = this,
            button = document.createElement('i'),
            opts = _this.options.button_remove;
        button.className = _this.cls.button_remove + ' ' + (_this.options.cls_button || '') + ' ' + (opts.cls || '');
        button.title = opts.title;
        button.innerHTML = opts.label;
        button.addEventListener('click', () => _this.remove())
        return button;
    }

    /**
     * Создание кнопки восстановления значения фильтра
     */
    button_restore_init() {
        const
            _this = this,
            button = document.createElement('i'),
            opts = _this.options.button_restore;
        button.className = _this.cls.button_restore + ' ' + (_this.options.cls_button || '') + ' ' + (opts.cls || '');
        button.title = opts.title;
        button.innerHTML = opts.label;
        button.addEventListener('click', () => _this.restore())
        return button;
    }

    /**
     * Переключить состояние "Изменён".
     * Если ``on === undefined``, тогда состояние определяется автоматически.
     */
    changed(on) {
        const _this = this;
        // Элемент управления фильтром не создан
        if (!_this.widget) return;

        // Если необходимо автоматически определяем состояние изменения фильтра
        if (on === undefined) on = _this.is_changed();
        _this.dirty = on;

        // Установить состояние кнопки "clear"
        _this.widget.classList[_this.is_empty() ? 'add' : 'remove'](_this.cls.input_empty);
    }

    /**
     * Очистить фильтр
     */
    clear(apply) {
        this.new_value = '';
        this.onclear();
        if (apply) this.apply();
    }

    /**
     * Получение состояния dirty.
     */
    get dirty() {
        return this.widget && this.widget.classList.contains(this.cls.dirty);
    }

    /**
     * Установка состояния dirty.
     */
    set dirty(value) {
        this.widget && this.widget.classList[value ? 'add' : 'remove'](this.cls.dirty);
    }

    /**
     * Активировать (установить фокус) фильтр
     */
    focus() {
        if (this.input) this.input.focus();
    }

    /**
     * Получить следующий instance_id
     */
    static get_instance_id() {
        return ++FilterInput._instance_id;
    }

    /**
     * Разрушение поля ввода фильтра.  Выполняется в widget_dispose()
     */
    input_dispose() {}

    /**
     * Создание элемента ввода фильтра
     */
    input_init() {
        const
            _this = this,
            input = document.createElement('input');
        input.className = _this.cls.input + ' ' + (_this.options.cls_input || '');
        _this.input = input;
        input.addEventListener('input', () => _this.new_value = _this.input_value);
        return input;
    }

    /**
     * Получить значение input
     */
    get input_value() {
        return this.input.value;
    }

    /**
     * Установить значение input
     */
    set input_value(value) {
        this.input.value = value;
    }

    /**
     * Должно возвращать true или false, в зависимости от того изменились ли настройки фильтра
     * по сравнению с исходными. В общем случае производится сравнение атрибутов
     * _value и _new_value.
     */
    is_changed() {
        const _this = this;
        let v1 = _this.value, v2 = _this.new_value;
        if (_this.is_empty_value(v1) && (_this.is_empty_value(v1) === _this.is_empty_value(v2))) return false;
        if (Array.isArray(v1) && Array.isArray(v2)) {
            if (v1.length != v2.length) return true;
            (v1 = [...v1]).sort();
            (v2 = [...v2]).sort();
            for (let i=0;i<v1.length;i++) if (v1[i] != v2[i]) return true;
            return false;
        }
        return v1 !== v2;
    }

    /**
     * Фильтр не установлен.
     */
    is_empty() {
        return this.is_empty_value(this.new_value);
    }

    /**
     * Проверка value на соответствие "пустому" значению.
     */
    is_empty_value(value) {
        return ([undefined, null, ''].indexOf(value) >= 0) || (Array.isArray(value) && !value.length);
    }

    /**
     * Создание элемента заголовка фильтра
     */
    label_init() {
        const
            _this = this,
            label = document.createElement('label');

        label.className = _this.cls.label + ' ' + (_this.options.cls_label || '');
        label.innerText = _this.label;
        return label;
    }

    /**
     * Новое значение фильтра
     */
    get new_value() {
        return this._new_value;
    }

    /**
     * Устанавливается новый фильтр.
     */
    set new_value(value) {
        this._new_value = value;
        this.changed();
    }

    /**
     * Вызывается при нажатии кнопки очистки фильтра.
     */
    onclear() {
        this.input_value = '';
    }

    /**
     * Вызывается при программной установке фильтра. Например при инициализации виджета фильтра.
     */
    onvalue() {
        this.input && (this.input_value = this.value || '');
    }

    /**
     * Удалить фильтр
     */
    remove() {
        if (this.panel) this.panel.hide_filter(this.name, true);
    }

    /**
     * Восстановить предыдущее состояние фильтра.
     * Сбрасывает изменения фильтра.
     */
    restore() {
        this.value = this.value;
    }

    /**
     * Возвращает текст значения фильтра.
     */
    get text() {
        return this.is_empty_value(this._value) ? '' : '' + this._value;
    }

    /**
     * Текущее значение фильтра
     */
    get value() {
        return this._value;
    }

    /**
     * Установить значение фильтра.
     * Состояние "Изменён" сбрасывается.
     */
    set value(value) {
        const _this = this;
        _this._value = value;
        _this._new_value = value;
        _this.changed(false);
        if (_this.widget) _this.onvalue();
    }

    /**
     * Разрушение визуальных компонентов виджета.  Выполняется при удалении фильтра с панели.
     */
    widget_dispose() {
        const _this = this;
        if (_this.widget) {
            _this.input_dispose();
            _this.widget.remove();
            for (const attr of [
                'input', 'label_node', 'button_clear',
                'button_restore', 'button_remove', 'widget']) delete _this[attr];
        }
        _this.new_value = _this.value;
    }

    /**
     * Создание виджета на панели фильтров.
     * Данный метод вызывается при отображении панели фильтров или при добавлении фильтра на
     * открытую панель.
     */
    widget_init() {
        if (!this.widget) {
            // Widget
            const _this = this, widget = (_this.widget = document.createElement('div'));
            widget.className = _this.cls.widget;
            widget.dataset.name = _this.name;

            // Label
            widget.appendChild(_this.label_node = _this.label_init());

            // Input
            widget.appendChild(_this.input_init());

            // Clear icon
            widget.appendChild(_this.button_clear = _this.button_clear_init());

            // Restore icon
            widget.appendChild(_this.button_restore = _this.button_restore_init());

            // Remove icon
            widget.appendChild(_this.button_remove = _this.button_remove_init());

            _this.changed(false);
            _this.onvalue();
        }
        return this.widget;
    }

} //~class FilterInput


/**
 * Вспомогательные функции для элементов FilterTree, FilterSimpleList, FilterSelect.
 */
export class FilterListUtils {
    static _labels = ['text', 'label', 'name'];

    /**
     * Определение типа и преобразование элемента к стандартному виду
     */
    static _make_item(item, i) {
        let res;
        // Определение типа элемента
        if (item instanceof Array) {

            // Массив вида [key, label]
            res = {
                "id": '' + item[0],
                "text": item[1]
            };

        } else if (item instanceof Object) {

            // Объект вида {id: ..., html|text|label|name: ...]
            res = {"id": '' + item.id};
            if (item.html !== undefined) {
                res.html = item.html;
            } else {
                for (let name of FilterListUtils._labels) {
                    if (item[name] !== undefined) {
                        res.text = item[name];
                        break;
                    }
                }
            }

        } else {
            // Прочие типы обрабатываем как строку, а id - порядковый номер элемента в массиве items
            res = {"id": '' + i, "text": '' + item};
        }
        return res;
    }

    /**
     * Преобразование списка элементов к стандартному виду - коллекции объектов вида
     * {id: ..., text|html: ...}
     */
    static _make_items(list) {
        // Список элементов для выбора
        let items = new Map();

        if (list instanceof Array) {

            // В items передан массив
            let i = 0, _item;
            for (let item of list) {
                _item = FilterListUtils._make_item(item, i++);
                items.set(_item.id, _item);
            }

        } else if (list instanceof Object) {

            // В items передан объект
            let _item;
            for (let [i, item] of Object.entries(list)) {
                _item = FilterListUtils._make_item(item, i);
                items.set(_item.id, _item);
            }

        } else if (list instanceof Map) {

            // В items передана коллекция
            let _item;
            for (let [i, item] of list) {
                _item = FilterListUtils._make_item(item, i);
                items.set(_item.id, _item);
            }

        } else {
            throw 'Items list is wrong type';
        }
        return items;
    }

}


/**
 * Фильтр "дерево"
 */
export class FilterTree extends FilterInput {

    /**
     * Настройки дерева (TreeView) по умолчанию
     */
    static tree_defaults = {
        autoload: false,
        show_focus: true
    };

    /**
     * Настройки поля выбора объектов (ObjectsInput) по умолчанию
     */
    static input_defaults = {
        autocomplete: true
    };

    /**
     * Установка фокуса на дерево.
     */
    focus() {
        const _this = this,
            _focus = (_this._focus || (_this._focus = () => _this.input.focus()));
        setTimeout(_focus, 100);
    }

    /**
     *
     * @param {*} dialog
     */
    dialog_init(dialog) {
        const _this = this;

        _this.tree = new TreeView(dialog, _this.options.tree_load,
            Object.assign({}, _this.constructor.tree_defaults, _this.tree_defaults, _this.options.tree || {},
                          {
                            checkbox: _this.input.options.multiple,
                            widget: false,
                            load_data: function(parent_id) {
                                return {checks: _this.input.id_list()};
                            }
                          }));

        // dialog.addEventListener('keydown', event => {
        //     if (event.key == 'Tab' || event.key == 'Escape') {
        //         _this.input.dialog.hide();
        //         _this.input.focus();
        //         event.preventDefault();
        //     }
        // });

        _this.tree.on('check', function(){
            const old_id = new Set(_this.input.id_list()),
                remove = [],
                add = [];
            for (const id of _this.tree.checked()) {
                if (!old_id.has(id)) {
                    _this.input.add_item(id, false);
                    add.push(id);
                }
                else old_id.delete(id);
            }
            for (const id of old_id) {
                _this.input.remove_item(id, false);
                remove.push(id);
            }
            if (add.length || remove.length) {
                const changes = {};
                if (add.length) changes.add = add;
                if (remove.length) changes.remove = remove;
                _this.input.dispatch('change', changes);
            }
        });
    }

    dialog_show() {
        const _this = this;
        _this.tree.clear();
        _this.tree.load(undefined, (_this.__dialog_show || (_this.__dialog_show = function(){
            _this.tree.checks(_this.input.id_list(), true);
            setTimeout(() => _this.tree.focus(), 10);
        })));
    }

    input_init() {
        super.input_init();
        const _this = this;
        _this.input = new ObjectsInput(_this.input,
            Object.assign({}, _this.init_defaults, _this.options.input || {},
                {
                    multiple: true,
                    dialog: true,
                    ondialog: function(dialog) { _this.dialog_init(dialog); },
                    ondialogshow: function() { _this.dialog_show(); },
                    load: _this.options.item_load,
                    search: _this.options.item_load,
                    onchange: function() {
                        const val = _this.input.id_list();
                        _this.new_value = val.length ? val : undefined;
                    }
                }));
        return _this.input.widget;
    }

    onclear() {
        this.input.clear(false);
    }

    onvalue() {
        const filter = this.value;
        this.input.clear(false);
        if (Array.isArray(filter)) {
            this.input.add_item(filter, false);
        } else if (typeof filter === 'string' && filter) {
            this.input.add_item(filter.split(','), false);
        }
    }

    /**
     * Возвращает текстовое представление элементов
     */
    get text() {
        const _this = this;
        return new Promise(resolve => {
            let func, tick = 100, counter = 0, total = 20000;
            (func = () => {
                if ((counter += tick) > total) {
                    resolve('Время ожидания истекло');
                } else {
                    if (_this.input.items_loading()) setTimeout(func, tick); else resolve(_this.get_text());
                }
            })();
        });
    }

    /**
     * Получить текстовое представление элементов
     */
    get_text() {
        const res = [];
        for (const item of this.input.items.values()) res.push(item.label.innerText);
        return res.join(', ');
    }

} //~class FilterTree


export class FilterSimpleList extends FilterTree {
    constructor(name, label, options) {
        //  Не установлен обязательный параметр настройки "items"
        if (!options || !options.items) throw 'Set "items" option';

        // Настройки ObjectsInput
        options.input = Object.assign({"autocomplete": false, "dialog_onclick": true}, options.input);

        // Настройки TreeView
        options.tree = Object.assign({"view": "list"}, options.tree)

        // Вызов родительского конструктора
        super(name, label, options);

        const _this = this;
        options = _this.options;

        if (typeof options.items === 'function') {
            _this._load_items = new Promise(options.items);
        } else {
            _this._items = FilterListUtils._make_items(options.items);
        }

        // Получение элементов для ObjectsInput
        options.item_load = function(id, onload) {
            if (!_this._items) {
                _this._load_items.then((items) => {
                    _this._items = FilterListUtils._make_items(items);
                    options.item_load(id, onload);
                });
            } else {
                const
                    res = [],
                    items = _this._items;
                for (let i of id) {
                    if (items.has(i)) res.push(items.get(i));
                }
                onload(res);
            }
        };

        // Получение элементов для TreeView
        options.tree_load = function(parent_id, onload) {
            if (!_this._items) {
                _this._load_items.then((items) => {
                    _this._items = FilterListUtils._make_items(items);
                    options.tree_load(parent_id, onload);
                });
            } else {
                const
                    res = [],
                    items = _this._items;
                for (let item of items.values()) {
                    if (item.checked !== undefined) delete item.checked;
                    res.push(item);
                }
                onload(res);
            }
        };
    }

} //~class FilteSimpleList


/**
 * Фильтр выбора одного из вариантов (select)
 */
export class FilterSelect extends FilterInput {

    constructor(name, label, options) {
        super(name, label, Object.assign({cls_input: 'form-select'}, options));
    }

    input_init() {
        const
            _this = this,
            input = (_this.input = document.createElement('select'));

        input.className = _this.cls.input + ' ' + (_this.options.cls_input || '');
        input.addEventListener('input', () => _this.new_value = _this.input_value);
        input.addEventListener('keydown', function(e) {
            if (!e.defaultPrevented && (e.key == 'Backspace' || e.key == 'Delete')) {
                _this.clear();
                e.preventDefault();
            }
        });

        // Заполнение списка
        if (_this.options.items === undefined) throw 'Option "items" not defined';
        _this._fill_select();
        return input;
    }

    onclear() {
        this.input.selectedIndex = -1;
    }

    /**
     * Заполнение списка
     */
    _fill_select() {
        const _this = this;
        if (_this.options.items === undefined || _this.input.childNodes.length) return;
        let opt;
        for (let [,item] of FilterListUtils._make_items(_this.options.items)) {
            opt = document.createElement('option');
            opt.value = (item.id === undefined ? item.value : item.id);
            if (item.html !== undefined) {
                opt.innerHTML = item.html;
            } else {
                opt.innerText = item.text;
            }
            _this.input.appendChild(opt);
        }
    }

    get text() {
        const select = this.input;
        if (select.multiple) {
            const res = [];
            for (const item of select.childNodes) {
                if (item.selected) {
                    res.push(item.innerText);
                }
            }
            return res.join(', ');
        } else {
            return select.selectedIndex < 0 ? '' : select.item(select.selectedIndex).innerText;
        }
    }

} //~class FilterSelect


/**
 * Фильтр диапазона дат
 */
export class FilterPeriod extends FilterInput {

    input_tpl = '<div class="d-flex _filter-input"><div class="input-group w-auto">'
        +'<select class="form-select w-auto bg-light"></select>'
        +'<input type="date" class="form-control w-auto">'
        +'<select class="form-select w-auto bg-light"></select>'
        +'<input type="date" class="form-control w-auto">'
        +'</div></div>';

    _mode = new Map([
        ['period', 'Период с'],
        ['date', 'Дата'],
        ['before', 'До даты'],
        ['after', 'С даты'],
        ['today', 'Сегодня'],
        ['curr_week', 'Текущая неделя'],
        ['curr_month', 'Текущий месяц'],
        ['curr_quart', 'Текущий квартал'],
        ['curr_half_year', 'Текущее полугодие'],
        ['curr_year', 'Текущий год'],
        ['yesterday', 'Вчера'],
        ['prev_week', 'Прошлая неделя'],
        ['prev_month', 'Прошлый месяц'],
        ['prev_quart', 'Прошлый квартал'],
        ['prev_half_year', 'Прошлое полугодие'],
        ['prev_year', 'Прошлый год'],
        ['start_today', 'С текущей даты'],
        ['start_week', 'С начала недели'],
        ['start_month', 'С начала месяца'],
        ['start_quart', 'С начала квартала'],
        ['start_half_year', 'С начала полугодия'],
        ['start_year', 'С начала года']
    ]);
    _mode_date = ['period', 'date', 'before', 'after'];

    input_init() {
        const
            _this = this,
            _input = document.createElement('div');

        _input.innerHTML = _this.input_tpl;
        _this.input = _input.querySelectorAll('input');

        const oninput = function(e) {
            const
                mode = _this._sel_mode,
                rel_mode = _this._rel_mode(mode);
            if (mode != rel_mode) _this._sel_mode = rel_mode;
            _this.new_value = _this._get_input_value();
        };

        for (let node of _this.input) node.addEventListener('input', oninput);

        _this.delim = _input.querySelector('span.input-group-text');
        _this.select = _input.querySelectorAll('select');
        let node;
        for (let [val, label] of _this._mode.entries()) {
            node = document.createElement('option');
            node.innerText = label;
            node.value = val;
            _this.select[0].appendChild(node);
        }
        for (let [label, val] of [
            ['по', 'include'],
            ['до', 'exclude']
        ]) {
            node = document.createElement('option');
            node.innerText = label;
            node.value = val;
            _this.select[1].appendChild(node);
        }
        _this.select[0].selectedIndex = (_this.select[1].selectedIndex = 0);

        _this.select[0].addEventListener('change', function() {
            _this._set_value(undefined, undefined, undefined, _this._sel_mode);
            _this.new_value = _this._get_input_value();
        });
        _this.select[1].addEventListener('change', oninput);

        return _input.children[0];
    }

    focus() {
        const _this = this;
        if (_this.input) {
            if (_this._rel_mode(_this._sel_mode, true)) {
                _this.select[0].focus();
            } else {
                _this.input[0].focus();
            }
        }
    }

    onclear() {
        const _this = this;
        //if (!_this.select[1].parentNode) _this.input[0].insertAdjacentElement('afterend', _this.select[1]);
        //if (!_this.input[1].parentNode) _this.select[1].insertAdjacentElement('afterend', _this.input[1]);
        _this.input[0].value = '';
        _this.input[1].value = '';
        _this._sel_mode = _this._rel_mode(_this._sel_mode);
        //_this.select[0].selectedIndex = 0;
        //_this.select[1].selectedIndex = 0;
    }

    onvalue() {
        const _this = this;
        if (!_this.input) return;

        if ([undefined, null, ''].indexOf(_this._value) >= 0) {
            _this.onclear();
        } else {
            _this._set_value.apply(_this, _this._value);
        }
    }

    _rel_mode(mode, is_rel) {
        if (/^(curr_|prev_)/.test(mode)) return is_rel || 'period';
        else if (/^start_/.test(mode)) return is_rel || 'after';
        else if (/^(yesterday|today)/.test(mode)) return is_rel || 'date';
        return is_rel ? false : mode;
    }

    _get_input_value() {
        const
            _this = this,
            mode = _this._sel_mode,
            input = _this.input,
            res = [null, null, false, mode];

        if (input[0].value) res[0] = input[0].value;
        if (input[1].value && input[1].parentNode) res[1] = input[1].value;

        if (_this.select[1].parentNode) {
            res[2] = _this.select[1].querySelector('option:checked').value === 'include';
        } else if (['date', 'today', 'yesterday'].indexOf(mode) >= 0) {
            res[1] = res[0];
            res[2] = true;
        } else if (mode == 'before') {
            res[1] = res[0];
            res[0] = null;
        } else if (mode == 'after') {
            res[1] = null;
        }
        return res;
    }

    /**
     * Возвращает текущий выбранный режим
     * @param {*} mode
     * @param {*} prev
     * @returns
     */
    get _sel_mode() {
        return this.select[0].querySelector('option:checked').value;
    }

    set _sel_mode(val) {
        try { this.select[0].querySelector(`option[value="${val}"]`).selected = true; } catch(e) {}
    }

    _get_period(mode, prev) {
        let now = date_trunc_time(new Date()), e = date_start(mode + (prev ? '' : '+'), now);
        e.setDate(e.getDate() - 1);
        return [date_start(mode, e), e];
    }

    /**
     * Устанавливает режим элемента ввода
     * @param {*} mode
     */
    _set_value(b, e, include, mode) {
        const
            _this = this;

        let period;

        // Установка режима
        if (mode) {
            if (mode === 'today' || mode === 'start_today') {
                b = date_trunc_time(new Date());
                e = null;
                period = false;
            } else if (mode === 'yesterday') {
                (b = date_trunc_time(new Date())).setDate(b.getDate() - 1);
                e = null;
                period = false;
            } else if (mode.startsWith('curr_')) {
                [b, e] = _this._get_period(mode.slice(5), false);
                period = 'include';
            } else if (mode.startsWith('prev_')) {
                [b, e] = _this._get_period(mode.slice(5), true);
                period = 'include';
            } else if (mode.startsWith('start_')) {
                b = date_start(mode.slice(6), date_trunc_time(new Date()));
                e = null;
                period = false;
            }
        } else {
            if (mode === undefined) {
                if ((b === undefined) && (e === undefined)) return;
                if (b && !e) { mode = 'after'; period = false; }
                else if (!b && e) { mode = 'before'; period = false; }
                else if (b && e && (b == e)) { mode = 'date'; period = 'include' }
                else mode = 'period';
            }
        }

        if (period === undefined) {
            period = (mode == 'period' ? (include ? 'include' : true) : false);
        }

        if ( mode == 'before' && !b && e) { b = e; e = null; }

        // Установить значения input
        if (b !== undefined) { _this.input[0].value = (b && date_to_iso(b)) || ''; }
        if (e !== undefined) { _this.input[1].value = (e && date_to_iso(e)) || ''; }

        // Показать или скрыть дату окончания
        if (period) {
            if (!_this.select[1].parentNode) _this.input[0].insertAdjacentElement('afterend', _this.select[1]);
            if (!_this.input[1].parentNode) _this.select[1].insertAdjacentElement('afterend', _this.input[1]);
            try {
                _this.select[1].querySelector(`option[value="${period}"]`).selected = true;
            } catch(e) {}

        } else {
            if (_this.select[1].parentNode) _this.select[1].remove();
            if (_this.input[1].parentNode) _this.input[1].remove();
        }
    }

    get text() {
        const _this = this, mode = _this._sel_mode;
        let date0 = iso_to_date(_this.input[0].value),
            date1 = iso_to_date(_this.input[1].value);
        if (date0) date0 = date0.toLocaleDateString();
        if (date1) date1 = date1.toLocaleDateString();

        if (mode == 'date') return date0;
        if (mode == 'after') return "С " + date0;
        if (mode == 'before') return "До " + date0;
        if (mode == 'period') return "С " + date0 + ' ' + _this.select[1].querySelector('option:checked').innerText + ' ' + date1;

        return _this._mode.get(mode);
    }

} //~class FilterPeriod


/**
 * Зануляет время в объекте Date.  Меняет переданный объект.
 */
function date_trunc_time(day) {
	day.setHours(0);
	day.setMinutes(0);
	day.setSeconds(0);
	day.setMilliseconds(0);
	return day;
}
//~date_trunc_time

/**
 * Получить начало периода относительно указанной даты.  Возвращает объект Date.
 * mode:
 * 	'week' - недели
 * 	'month' - месяца
 * 	'quart' - квартала
 * 	'half_year' - полугодия
 * 	'year' - года
 * Если добавить в конце mode знак "+", то будет возвращено начало следующего периода.
 */
function date_start(mode_, day) {
	const [, mode, next] = /^([a-z_]+)(\+?)$/.exec(mode_);
	if (!day) {
		day = new Date();
	} else if (typeof day === 'number') {
		day = (new Date()).setTime(day);
	} else {
		day = new Date(day);
	}
	date_trunc_time(day);
	if (mode !== 'week') day.setDate(1);
	switch (mode) {
		case 'week':
			var d = day.getDay();
			if (next) {
				day.setDate(day.getDate() + (8 - (d || 7)));
			} else {
				day.setDate(day.getDate() - ((d || 7) - 1));
			}
			break;
		case 'month':
			if (next) day.setMonth(day.getMonth() + 1);
			break;
		case 'quart':
			var m = day.getMonth(), d = m % 3;
			if (d || next) day.setMonth(m - d + (next ? 3 : 0));
			break;
		case 'half_year':
			var m = day.getMonth(), d = m % 6;
			if (d || next) day.setMonth(m - d + (next ? 6 : 0));
			break;
		case 'year':
			day.setMonth(0);
			if (next) day.setFullYear(day.getFullYear() + 1);
			break;
		default:
			throw `Unknown mode "${mode}"`;
	}
	return day;
}
//~date_rel_day


/**
 * Преобразование даты в ISO-формат
 * @param Date val - Дата
 * @returns string
 */
function date_to_iso(val) {
	if (!val) return '';
	if (typeof val !== 'object') return val;
	let m = val.getMonth() + 1, d = val.getDate();
	return val.getFullYear() + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
}

/**
 * Преобразование строки даты ISO в дату Date()
 * @param val - Дата в ISO формате
 * @returns Date
 */
function iso_to_date(val) {
	if (!(val = val.trim())) return null;
	let r = /^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})/.exec(val);
	if (r) return new Date(r[1], r[2]-1, r[3]);
}
