import { Dropdown, Modal, Toast, Tooltip } from 'bootstrap';
import Sortable from 'sortablejs';

/**
 *
 * Список элементов
 *
 * @class
 * @param element {Object} - Элемент-контейнер списка. Обычно это <div>.
 * @param options {Object} - Настройки списка.
 */

export default class ItemsList {
	options = {
		autofocus: true,
		btn_classes: {
			all: "btn",
			primary: "btn-primary",
			secondary: "btn-secondary"
		},
		cells: false,
		cls: {
				cell: '_cell',
				click: '_click',
				col_after: '_col-after',
				col_before: '_col-before',
				col_check: '_check',
				col_menu_btn: '_menu',
				col_number: '_number',
				col_resize: '_col-resize',
				compact: '_compact',
				dlg_cols: '_cols-dlg',
				dlg_view: '_view-dlg',
				dlg: 'itemslist-dlg',
				fixed_left: '_fixed',
				focused: '_focused',
				hborder: '_hborder',
				header_wrapper: '_header-wrapper',
				header: '_header',
				hscrolled: '_hscrolled',
				label: '_label',
				last: '_last',
				loading: '_loading',
				move_ghost: '_move-ghost',
				moving: '_moving',
				multiple: '_multiple',
				no_check: '_no-check',
				no_outborder: '_no-outborder',
				odd: 'odd',
				resize_handle: '_resize',
				row: '_row',
				scroll_bottom: '_scroll-bottom',
				scroll_top: '_scroll-top',
				scroll: '_scroll',
				selected: '_selected',
				setup_btn: '_setup-btn',
				show_focus: '_show-focus',
				sort_asc: '_sort-asc',
				sort_desc: '_sort-desc',
				sort_mode: '_sort-mode',
				sort_num: '_sort-num',
				sort: '_sort',
				span: '_span',
				striped: '_striped',
				vborder: '_vborder',
				widget: 'itemslist'
			},
		col_sort: false,
		col_width: '10em',
		compact: false,
		data_prefix: 'il-',
		focus_timeout: 0,
		hborder: false,
		ico_classes: {
			reload: 'bi bi-arrow-clockwise',
			cfg_view: 'bi bi-table',
			cfg_cols: 'bi bi-layout-three-columns',
			clear_sort: 'bi bi-filter-circle',
			sort_asc: 'bi bi-sort-down-alt',
			sort_desc: 'bi bi-sort-down',
			x: 'bi bi-x',
			plus: 'bi bi-plus',
			sel_all: 'bi bi-square-fill',
			sel_none: 'bi bi-square',
			sel_invert: 'bi bi-square-half',
			hide: 'bi bi-eye-slash',
			pin_on: 'bi bi-pin-fill',
			pin_off: 'bi bi-pin-angle',
			reset_view: 'bi bi-recycle'
		},
		msg: {
			abort: 'Запрос прерван',
			timeout: 'Истекло время ожидания ответа от сервера',
			error: 'Ошибка обращения к серверу'
		},
		row_number: false,
		multiple: false,
		no_outborder: false,
		options_auto_save: true,
		select_timeout: 0,
		setup_button_html: '<i class="_icon"></i>',
		show_focus: false,
		striped: false,
		tooltips: true,
		reset_allow: false,
		vars: {
			header_row_height: '--il-header-row-height',
			row_height: '--il-row-height',
			scrolltop: '--il-scrolltop',
			scrollleft: '--il-scrollleft'
		},
		vborder: false
	};

	_other_options = ['compact', 'striped', 'vborder', 'hborder', 'row_number'];
	_depends = {
		'make_columns': ['make_sort', 'make_col_widths']
	};
	_loading_id = 1;

	header;
	sel_count;
	setup_button;
	widget;

	sort = [];
	columns = [];
	column = {};
	section = {};
	width = {};
	fixed = [];
	items = {};
	rows = [];
	resize_timer = null;
	sel;
	is_loading = false;

	static _inst_index = 0;
	static instances = new Map();

	/**
	 * @member row_size {number} - Высота строки (пиксели)
	 * @member upd_id {number} - Индикатор необходимости перерировки строки
	 * @member v_top {number} - Первая отображаемая строка (индекс строки в this.rows)
	 * @member v_last {number} - Последняя отображаемая строка + 1 (индекс строки в this.rows)
	 * @member s_left {number} - Текущее положение скроллинга Left
	 * @member s_top {number} - Текущее положение скроллинга Top
	 * @member col_id {string} - Имя колонки содержащей идентификаторы строк
	 */

	constructor(element, data, options) {
		const _this = this;

		if (typeof element !== 'object') throw 'Parameter "element" not defined';
		if (typeof data !== 'function') throw 'Parameter "data" not defined';

		_this.inst_index = ++ItemsList._inst_index;
		ItemsList.instances.set(element, _this);

		_this.get_data = data;

		// Инициализация Настройки
		const opts = Object.assign({}, _this.options, typeof options === 'object' ? options : {});
		_this.options = opts;

		// Root-элемент списка
		_this.wrapper = element;
		/* Расскоментировать если будут проблемы с размещением в элементе у которого position=static
		if (getComputedStyle(_this.wrapper = element).position == 'static') element.style.position = 'relative';
		*/
		element.appendChild(_this.widget = document.createElement('DIV'));
		const cls = [opts.cls.widget];
		for (const _cls of ['striped', 'multiple', 'show_focus', 'compact', 'hborder', 'vborder', 'no_outborder']) {
			if (opts[_cls]) cls.push(opts.cls[_cls]);
		}
		if (element.getAttribute('data-' + opts.data_prefix + 'classes')) cls.push(element.getAttribute('data-' + opts.data_prefix + 'classes'));
		if (opts.classes) cls.push(opts.classes);
		_this.widget.className = cls.join(' ');
		_this.widget.tabIndex = 0;

		/*
		 * Создание элементов
		 */

		// Область заголовков колонок
		(_this.header = document.createElement('DIV')).classList.add(opts.cls.header);
		_this.widget.appendChild(_this.header);

		// Кнопка настройки списка
		(_this.setup_button = document.createElement('DIV')).classList.add(opts.cls.setup_btn);
		_this.setup_button.innerHTML = opts.setup_button_html;
		_this.setup_button.firstElementChild.title = 'Настройки и дополнительные инструменты...';
		_this.header.appendChild(_this.setup_button);

		// Заполнитель прокрутки верхний
		_this.widget.appendChild(_this.scroll_top = document.createElement('DIV'));
		_this.scroll_top.className = `${opts.cls.scroll} ${opts.cls.scroll_top}`;

		// Заполнитель прокрутки нижний
		_this.widget.appendChild(_this.scroll_bottom = document.createElement('DIV'));
		_this.scroll_bottom.className = `${opts.cls.scroll} ${opts.cls.scroll_bottom}`;

		// Подключение обработчиков событий
		for (let n in opts) {
			if (n = /^on(data|focus|select|settings)$/.exec(n)) {
				_this.widget.addEventListener('il_' + n[1], opts[n[0]]);
			}
		}

		// ~Создание элементов

		_this.row_size = 0;
		_this.upd_id = 1;
		_this.v_top = 0;
		_this.v_last = 0;
		_this.s_left = 0;
		_this.s_top = 0;
		_this.col_id = '';
		_this.f_width = 0; // Ширина фиксированных слева колонок в пикселях. Рассчитывается в make_col_widths()
		_this.loaded_cols = [];
		_this._tooltips = [];

		_this.sel = new ItemsListSelection(_this);

		_this._bind();

		// Sets attributes using for visualization and init list
		for (let name of ['columns', 'fixed', 'section', 'sort', 'width']) {
			if (typeof opts[name] === 'object') _this[name] = opts[name];
		}
		// Initialization lists
		const init_opt = {}
		if (typeof opts.column === 'object') { init_opt.column = opts.column; }
		if (typeof opts.items === 'object') { init_opt.items = opts.items; }
		if (!_this._is_empty(init_opt)) {
			_this.ondata(init_opt, 'init', false);
		}

		if (opts.init != 'manual' && !(init_opt.column && init_opt.items)) {
			_this.load_items('init');
		}
	}

	/**
	 * Отрисовка строки списка.
	 * @param r: Номер строки
	 * @returns Object - Объект DOM
	 */
	make_row(r) {
		const
			_this = this,
			item = _this.rows[r],
			d = document,
			sel = _this.sel;

		if (!item) {
			console.error(`make_row(${r})`, _this.rows);
			return;
		}

		const
			cls = _this.options.cls,
			item_data = item.d,
			item_row_data = item_data[null] || {};

		// Уже подготовлено
		if (item.n) {
			if (item.u == _this.upd_id) return item.n;
			item.n.remove();
		}

		// Строка
		let row_node = (item.n = d.createElement('DIV')),
			_node,
			sel_c = -1,
			_cls = [];

		if (!(r % 2)) _cls.push(cls.odd);  // Counting start from 1
		if (item.s) _cls.push(cls.selected);
		if (r === sel.r) {
			_cls.push(cls.focused);
			sel_c = sel.c;
			sel.row = row_node;
		}
		row_node.className = cls.row + ' ' + _cls.join(' ');
		row_node.dataset.id = item.id;
		row_node.dataset.index = r;

		// Ячейки/колонки
		let _node2, _name, grid_column = 0;

		// Колонка номера строки
		if (_this.options.row_number) {
			(_node = d.createElement('DIV')).className = `${cls.cell} ${cls.col_number} ${cls.fixed_left}`;
			if (_this.f_last == grid_column) _node.classList.add(cls.last);
			_node.style.left = _this.f_left[grid_column] + 'px';
			_node.style.gridColumn = ++grid_column;
			_node.innerHTML = '<span title="' + (r + 1) + '">' + (r + 1) + '</span>';
			row_node.appendChild(_node);
		}
		// Колонка выбора ячеек
		if (_this.options.multiple) {
			(_node = d.createElement('DIV')).className = `${cls.cell} ${cls.col_check} ${cls.fixed_left}`;
			if (_this.f_last == grid_column) _node.classList.add(cls.last);
			if (item_row_data.no_check) _node.classList.add(cls.no_check);
			_node.style.left = _this.f_left[grid_column] + 'px';
			_node.style.gridColumn = ++grid_column;
			row_node.appendChild(_node);
		}

		let start_col_grid = grid_column + 1;
		if (sel_c > -1 && grid_column) sel_c += start_col_grid;

		for (const _col of _this.view_cols) {
			_node = d.createElement('DIV');
			_node.dataset.col = (_name = _col.name);
			_cls = [cls.cell];

			// Классы строки
			if (item_row_data.cls) _cls.push(item_row_data.cls);
			// Классы ячейки
			if (_col.cls) _cls.push(_col.cls);
			// Ячейка фиксированная
			if (_col.fixed) {
				_cls.push(cls.fixed_left);
				_node.style.left = _this.f_left[grid_column] + 'px';
				if (_this.f_last == grid_column) _cls.push(cls.last);
			}
			// Выравнивание ячейки
			if (_col.align) _node.style.textAlign = _col.align;

			_node.style.gridColumn = ++grid_column;
			_node.className = _cls.join(' ');

			if (_col.r) { // Пользовательский рендеринг ячейки
				_col.r.call(_this, item_data[_name], _name, item, _node);
			} else {
				if (item_data[_name] !== undefined) {
					(_node2 = d.createElement('SPAN')).innerText = item_data[_name];
					_node.appendChild(_node2);
				}
			}
			if (grid_column == sel_c) {
				_node.classList.add(cls.focused);
				sel.cell = _node;
			}
			row_node.appendChild(_node);


			if (item_row_data.span === _name) {
				// span-ячейка
				_node.classList.add(cls.span);
				if ((grid_column - 1) <= _this.f_last) {
					// Fixed cell
					_node.style.gridColumnEnd = 'span ' + (_this.f_last - grid_column + start_col_grid - 1);

					grid_column = _this.f_last + 1;

					if (grid_column < (_this.view_cols.length + start_col_grid)) {
						// Заполнитель для фиксированной span ячейки
						_node = d.createElement('DIV');
						_node.className = cls.cell + ' ' + (item_row_data.cls || '');
						_node.style.gridColumn = ++grid_column + ' / span ' + (_this.view_cols.length - grid_column + start_col_grid);
						grid_column = start_col_grid + _this.view_cols.length - 1;
						row_node.appendChild(_node);
					}

				} else {
					_node.style.gridColumnEnd = 'span ' + (_this.view_cols.length - grid_column + start_col_grid);
					grid_column = start_col_grid + _this.view_cols.length - 1;
				}
				break;
			}
		}

		// Right filler
		_node = d.createElement('DIV');
		_node.className = cls.cell;
		_node.style.gridColumn = ++grid_column;
		row_node.appendChild(_node);

		item.u = _this.upd_id;
		return row_node;
	}

	h_height_calc() {
		const _this = this;

		_this.widget.style.removeProperty(_this.options.vars.header_row_height);

		// Принудительная перерисовка заголовка
		_this.header.style.display = 'none';
		_this.header.offsetHeight;
		_this.header.style.display = '';

		let res = 0;
		for (let node of _this.header.childNodes) {
			if (node.offsetHeight > res) {
				res = node.offsetHeight;
			}
		}
		_this.h_height = res;
		_this.widget.style.setProperty(_this.options.vars.header_row_height, (_this.h_height = res) + 'px', 'important');
	}

	/**
	 * Сбрасывает область просмотра. Убирает все отображаемые строки.
	 */
	reset_view() {
		const _this = this;

		let _row = _this.scroll_top.nextElementSibling, _next;
		while (_row && _row !== _this.scroll_bottom) {
			_next = _row.nextElementSibling;
			_row.remove();
			_row = _next;
		}
		_this.scroll_top.style.height = '0px';
		_this.scroll_bottom.style.height = _this.widget.clientHeight - (_this.h_height || 0) + 'px';
		_this.widget.style.removeProperty(_this.options.vars.row_height);

		_this.v_top = 0;
		_this.v_last = 0;
		_this._sth = -1;
		_this._sbh = -1;
		_this.upd_id++;
	}

	/**
	 * Вычисление размера страницы в строках.
	 * @returns number: Количество полных строк размещающихся на странице
	 */
	get_psize() {
		return Math.floor((this.widget.clientHeight - this.h_height) / this.row_size);
	}

	/**
	 * Установить фокус на строку с указанным идентификатором.
	 * @returns boolean
	 */
	focus_id(id) {
		try {
			return this.sel.focus(this.items[id].i);
		} catch(e) {
			return false;
		}
	}

	/**
	 * Получить идентификатор строки находящейся в фокусе.
	 * возвращает ``undefined``, если фокус не установлен.
	 */
	focused_id() {
		try { return this.rows[this.sel.r].id; } catch(e) {}
	}

	/**
	 * Set focus to list widget
	 */
	focus() {
		this.widget.focus();
	}

	/**
	 * Получить элемент по идентификатору, если id === undefined, то возвражается focused-элемент.
	 * возвращает ``undefined``, если фокус не установлен.
	 */
	get_item(id) {
		const _this = this;
		try {
			return id === undefined ? _this.rows[_this.sel.r] : _this.items[id];
		} catch(e) {}
	}

	/**
	 * Полная перерисовка области просмотра.
	 */
	draw() {
		const _this = this;

		_this.reset_view();
		if (!_this.rows.length) return;

		// Определение размера строки
		_this.row_size = 0;
		_this.widget.style.removeProperty(_this.options.vars.row_height);
		let row = _this.make_row(0), size = 0;
		_this.scroll_top.insertAdjacentElement('afterend', row);

		// Расчёт высоты с учётом бордера
		for (let node of row.childNodes) {
			if (size < node.offsetHeight) size = node.offsetHeight;
		}
		if (_this.options.hborder) {
			let bwidth = getComputedStyle(row.firstElementChild).borderBottomWidth;
			if ((bwidth=/([0-9.])+px/.exec(bwidth)) && (bwidth=parseFloat(bwidth[1]))) {
				size += bwidth;
			}
		}

		_this.row_size = size;
		_this.widget.style.setProperty(_this.options.vars.row_height, size + 'px');
		row.remove();

		// Прорисовка текущей страницы
		_this.draw_view();
	}

	/**
	 * Прорисовывает текущую страницу.
	 */
	draw_view() {
		const
			_this = this,
			widget = _this.widget,
			scroll_top = _this.scroll_top,
			scroll_bottom = _this.scroll_bottom,
			row_size = _this.row_size,
			page_size = _this.get_psize(),
			rows = _this.rows;

		// Определение первой и последней строки для отрисовки
		let _top = Math.max(0, Math.ceil(widget.scrollTop / row_size - 3)), // page_size / 10 - 1)),
			_last = Math.min(rows.length, Math.ceil(page_size + 6 + _top)),
			r, __last;

		if (_top >= _this.v_last || _last <= _this.v_top) {
			// Сместились больше текущего отображения

			scroll_bottom.remove();
			// Отображение строк
			for (r=_top;r<_last;r++) {
				widget.appendChild(_this.make_row(r));
			}

			// Убираем текущее отображение
			__last = Math.min(_this.v_last, rows.length);
			for (r=_this.v_top;r<__last;r++) {
				if (rows[r].n) {
					rows[r].n.remove();
					rows[r].n = null;
				}
			}

			widget.appendChild(scroll_bottom);
		} else {
			// Есть пересечения с текущим отображением

			if (_this.v_top < _top) {
				// Убираем лишние отображаемые сверху строки
				__last = Math.min(_top, _this.v_last, rows.length);
				for (r=_this.v_top;r<__last;r++) {
					if (rows[r].n) {
						rows[r].n.remove();
						rows[r].n = null;
					}
				}
			} else {
				// Отображение строк
				__last = Math.min(_this.v_top, rows.length);
				for (r=__last-1;r>=_top;r--) {
					scroll_top.insertAdjacentElement("afterend", _this.make_row(r));
				}
			}

			if (_last < _this.v_last) {
				// Убираем лишние отображаемые снизу строки
				__last = Math.min(_this.v_last, rows.length);
				for (r=_last;r<__last;r++) {
					if (rows[r].n) {
						rows[r].n.remove();
						rows[r].n = null;
				 	}
				}
			} else {
				// Отображение строк
				scroll_bottom.remove();
				__last = Math.min(_last, rows.length);
				for (r=_this.v_last;r<__last;r++) {
					widget.appendChild(_this.make_row(r));
				}
				widget.appendChild(scroll_bottom);
			}
		}

		/**
		 * Расчёт "заполнителей" скроллинга
		 */
		// Верхний
		let h = _this.row_size * _top;
		if (_this._sth != h) {
			_this.scroll_top.style.height = (_this._sth = h) + 'px';
		}
		_this.v_top = _top;

		// Нижний
		if (h = (_this.row_size * (rows.length - _last))) {
			// Отображены не все строки - высота заполнителя рассчитывается по неотображаемым строкам
			if (_this._sbh != h) {
				_this.scroll_bottom.style.height = (_this._sbh = h) + 'px';
			}
		} else {
			if (!_this._sth) {
				if((h = _this.widget.clientHeight - _this.h_height - rows.length * _this.row_size) <= 0) {
					h = 0;
				}
			} else {
				h = Math.max(_this.widget.clientHeight - _this.h_height - _this._sth - (_last - _top) * _this.row_size, 0);
			}
			if (h != _this._sbh) _this.scroll_bottom.style.height = (_this._sbh = h) + 'px';
		}
		_this.v_last = _last;
	}

	/**
	 * Show alert message
	 * @param {*} data
	 * @param {string} alert
	 * @param {object} options
	 */
	alert(data) {
		const
			_this = this;

		// Check arguments. If alert is object and options undefined then ok.
		let alert, options, args = Array.from(arguments);
		if (args.length > 1) {
			args.shift()
			for (let arg of args) {
				if (typeof arg === 'string') alert = arg;
				else if (typeof arg === 'object') options = arg;
			}
		}

		let toasts = _this.toasts;
		// Toast placement container
		if (!toasts) {
			toasts = (_this.toasts = document.createElement('div'));
			toasts.className = "toast-container";
			_this.widget.appendChild(toasts);
		}

		// toast
		const _toast = document.createElement('div');
		_toast.className = 'toast';
		if (alert || (alert === undefined && (alert = 'danger'))) _toast.classList.add('text-bg-' + alert);
		_toast.innerHTML = '<div class="d-flex"><div class="toast-body"></div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>';

		let text;
		if (typeof data === 'string') {
			text = data;
		} else if (typeof data === 'object') {
			if (data.result) {
				text = xhr_error(data.request, data.result, _this.options.msg);
			} else {
				text = 'Error receiving data';
			}
		}

		let _body = _toast.querySelector('.toast-body');

		if (typeof text === 'string') {
			_body.innerText = text;
		} else if (typeof text === 'object') {
			_body.appendChild(text);
		} else {
			console.error('Error data parameter type for ItemsList.alert() method', data);
			_body.innerText = 'Undefined error';
		}

		toasts.appendChild(_toast);
		let toast = new Toast(_toast, options);
		_toast.addEventListener('hidden.bs.toast', () => {
			toast.dispose();
			_toast.remove();
			if (!_this.toasts.firstElementChild) {
				_this.toasts.remove();
				delete _this.toasts;
			}
		})
		toast.show();
	}

	/**
	 * Destroy ItemsList. Unbind events and remove widget.
	 */
	dispose() {
		this.instances.delete(this.element);
		try { this._mouse.reset(); } catch(e) { console.error(e); }
		for (let [element, event, handler] of this._events) try {
			element.removeEventListener(event, handler);
		} catch(e) { console.error(e); }
		this.widget.remove();
	}

	/**
	 * Отрисовывает колонки
	 */
	make_columns() {
		const
			_this = this,
			header = _this.header,
			cls = _this.options.cls,
			doc = document;

		// Удаление элементов заголовков колонок
		const _remove = [];
		for (let col of header.childNodes) {
			if (col.classList.contains(cls.cell) && col.dataset.col) {
				_remove.push(col);
			}
		}
		for (let col of _remove) col.remove();

		let grid_column = 0, after;
		_this.f_last = -1;
		_this.sys_cols = 0;

		// Колонка номера ячеек
		if (_this.col_number) _this.col_number.remove();
		if (_this.options.row_number) {
			_this.f_last = grid_column++;
			(_this.col_number = doc.createElement('DIV')).className = `${cls.cell} ${cls.col_number} ${cls.fixed_left}`;
			_this.col_number.style.gridColumn = grid_column;

			header.insertAdjacentElement('afterbegin', _this.col_number);
			_this.sys_cols++;
			after = _this.col_number;
		} else {
			_this.col_number = undefined;
		}

		// Элемент select
		if (_this.col_check) _this.col_check.remove();
		if (_this.options.multiple) {
			_this.f_last = grid_column++;
			(_this.col_check = doc.createElement('DIV')).className = `${cls.cell} ${cls.col_check} ${cls.fixed_left}`;
			(_this.sel_count = doc.createElement('DIV'));
			_this.col_check.appendChild(_this.sel_count);
			_this.col_check.style.gridColumn = grid_column;

			if (after) {
				after.insertAdjacentElement('afterend', _this.col_check);
			} else {
				header.insertAdjacentElement('afterbegin', _this.col_check);
			}
			_this.sys_cols++;
			after = _this.col_check;
		} else {
			_this.col_check = undefined;
		}

		// Колонки
		_this.view_cols = _this.get_view_cols();

		let node, subnode, _subnode;
		for (const col of _this.view_cols) {

			// Колонка
			(node = doc.createElement('DIV')).className = cls.cell + (col.fixed ? ' ' + cls.fixed_left : '');
			node.dataset.col = col.name;
			if (col.fixed) _this.f_last = grid_column;
			node.style.gridColumn = ++grid_column;

			// Заголовок колонки
			(subnode = doc.createElement('DIV')).className = cls.label;
			// Выравнивание колонки
			if (col.align) subnode.style.textAlign = col.align;

			// Название колонки
			if (col.label || col.label_html) {
				_subnode = doc.createElement('span');
				if (col.label_html !== undefined) {
					_subnode.innerHTML = col.label_html;
				} else {
					_subnode.innerText = col.label;
				}
				node.title = (col.hint || col.label || '');
				subnode.appendChild(_subnode);
			}

			// Кнопка меню
			if (_this.col_menu_items(node, true)) {
				_subnode = doc.createElement('span');
				_subnode.className = cls.col_menu_btn;
				_subnode.title = "Настройки колонки (сортировка, фиксирование и т.п.)";
				subnode.appendChild(_subnode);
				node.classList.add(cls.click);
			}

			node.appendChild(subnode);

			// Сортировка
			if (col.sort) {
				(subnode = doc.createElement('DIV')).className = cls.sort;
				subnode.title = 'Сортировка';
				subnode.innerHTML = `<div class="${cls.sort_mode}"></div><div class="${cls.sort_num}"></div>`;
				node.appendChild(subnode);
			}

			// Изменение размера
			if (col.resizable !== false) {
				let resize_node = doc.createElement('DIV');
				resize_node.className = cls.resize_handle;
				node.appendChild(resize_node);
			}

			// Добавление узла в заголовок
			if (after) {
				after.insertAdjacentElement('afterend', node);
			} else {
				header.insertAdjacentElement('afterbegin', node);
			}
			after = node;
		}

		// Добавление класса "last" в последнюю фиксированную колонку
		if (_this.f_last > -1) _this.header.childNodes[_this.f_last].classList.add(cls.last);

		_this.make_col_widths();
		_this.make_sort();
		_this.h_height_calc();
	}

	/**
	 * Получение ширины видимых колонок включая служебные.
	 * Используется для заполнения CSS.
	 * Возвращает [widths, names]:
	 * widths - массив размеров колонок,
	 * names - соответствующие имена колонок.
	 * Имена служебных колонок устанавливаются в undefined.
	 * Метод должен выполнятся после make_columns()
	 */
	col_widths() {
		const
			_this = this,
			widths = [],
			names = [];

		// Колонка номера строки
		if (_this.options.row_number) {
			widths.push('max-content');
			names.push(undefined);
		}
		// Колонка флажка выбора строки
		if (_this.options.multiple) {
			widths.push('max-content');
			names.push(undefined);
		}

		// Заполнение колонок
		let width, re = /^[0-9.]+([a-z]+|%)$/;
		for (const col of _this.view_cols) {
			if (!(width = _this.width[col.name] || col.width) || !re.test(width)) width = _this.options.col_width;
			widths.push(width);
			names.push(col.name);
		}
		return [widths, names]
	}

	/**
	 * Установить ширину колонок.
	 */
	make_col_widths() {
		const
			_this = this,
			width = _this.col_widths()[0],
			cls_col_fixed = _this.options.cls.fixed_left;

		// Ширина колонок
		_this.widget.style.gridTemplateColumns = width.join(" ");

		// Ширина фиксированных колонок
		_this.f_width = 0;
		_this.f_left = [];
		for (let col of _this.header.childNodes) {
			if (col.classList.contains(cls_col_fixed)) {
				col.style.left = _this.f_width + 'px';
				_this.f_left.push(_this.f_width);
				_this.f_width += col.offsetWidth;
			} else {
				break;
			}
		}
	}

	/**
	 * "Заворачивает" метод ondata() в функцию. Служебный метод.
	 * @param mode string - Режим запроса данных (``"init"``, ``"items"``...).
	 */
	_ondata(mode, onloaded, loading_id) {
		const _this = this;
		return function(data) { _this.ondata(data, mode, onloaded, loading_id); };
	}

	/**
	 * Добавить в массив действие, если оно там не существует.
	 */
	_action_add(actions, val) {
		if (actions.indexOf(val) >= 0) return;
		let i=-1, action, _depends=this._depends[val] || [];
		while (++i < actions.length) {
			if (_depends.indexOf(action=actions[i]) >= 0) {
				// Action val include actions[i]
				actions.splice(i, 1);
			} else if ((this._depends[action] || []).indexOf(val) >=0) {
				// Action actions[i] include val
				return;
			}
		}
		if (!actions.length || val == '_restore_selected') {
			actions.push(val);
		} else  {
			for (let name of ['draw', '_restore_selected']) {
				if ((i=actions.indexOf(name)) >= 0) {
					actions.splice(i, 0, val);
					return
				}
			}
			actions.push(val);
		}
	}

	/**
	 * Удалить из массива значение.
	 */
	_arr_del(arr, val) {
		let i = arr.indexOf(val);
		if (i >= 0) arr.splice(i, 1);
	}

	/**
	 * Compare two arrays. Return true if are equals.
	 */
	_arr_eq(a, b) {
		if (a.length != b.length) return false;
		for (let i=0;i<a.length;i++) if (a[i] !== b[i]) return false;
		return true;
	}

	/**
	 * Возвращает функцию, которая выполняет рендеринг ячейки.
	 */
	render_factory(render) {
		const _this = this;
		let i = (_this._renders || (_this._renders = [])).indexOf(render);
		if (i >= 0) return _this._renders[i];
		let res;

		if (render == 'html') {
			res = function(value, name, item, node) {
				node.innerHTML = value || ((value === null || value === undefined) ? '' : value);
			};
		} else if (typeof render === 'function') {
			res = function(value, name, item, node) {
				const _n = document.createElement('SPAN');
				_n.innerText = render.call(this, value, name, item);
				node.appendChild(_n);
			}
		} else if (typeof render === 'object') {
			if (render.html) {
				let func = render.html;
				res = function(value, name, item, node) {
					node.innerHTML = func.call(this, value, name, item);
				}
			} else if (render.dom) {
				let func = render.dom;
				res = function(value, name, item, node) {
					node.appendChild(func.call(this, value, name, item));
				}
			} else if (render.text) {
				let func = render.text;
				res = function(value, name, item, node) {
					const _n = document.createElement('SPAN');
					_n.innerText = func.call(this, value, name, item);
					node.appendChild(_n);
				}
			} else {
				console.error("Wrong render function description", render);
				throw "Wrong render function description";
			}
		}
		_this._renders.push(res);
		return res;
	}

	/**
	 * Обработка полученных настроек и данных
	 */
	ondata(data, mode, event, loading_id) {
		const _this = this;
		let actions = [];

		if (loading_id) _this.loading(false, loading_id);

		if (typeof data === 'string') {
			if (typeof _this.options.alert === 'function') {
				_this.options.alert(data);
			} else if (data !== 'abort') {
				_this.alert(data);
			}
			return;
		}

		if (typeof data === 'object') {
			// Ошибка запроса?
			if (data.result && data.request) {
				if (typeof _this.options.alert === 'function') {
					_this.options.alert(data);
				} else if (data.result !== 'abort') {
					_this.alert(data);
				}
				return;
			}

			// Описание разделов колонок
			if (typeof data.section === 'object') {
				_this.section = data.section;
			}
			// Описание колонок
			if (typeof data.column === 'object') {
				// reset column collection
				if (_this.col_id) _this.col_id = '';
				if (_this.column) _this.column = {};

				// Create columns instances
				for (let [name, col] of Object.entries(data.column)) {
					_this.column[name] = new ItemsListColumn(_this, name, col);
				}

				// Check existing of id-column
				if (!_this.col_id) throw 'Column with attribute "id=true" not defined';

				_this._action_add(actions, 'make_columns');
			}

			// Ширина колонок
			if (typeof data.width === 'object') {
				_this.width = data.width;
				_this._action_add(actions, 'make_col_widths');
			}

			// Порядок следования колонок
			if (typeof data.columns === 'object') {
				_this.columns = data.columns;
				_this._action_add(actions, 'make_columns');
				_this._action_add(actions, 'draw');
			}

			// Отображение сортировки
			if (typeof data.sort === 'object') {
				_this.sort = data.sort;
				_this._action_add(actions, 'make_sort');
			}

			// Фиксация колонок
			if (typeof data.fixed === 'object') {
				_this.fixed = data.fixed;
				_this._action_add(actions, 'make_columns');
				_this._action_add(actions, 'draw');
			}

			// Обработка списка строк
			if (typeof data.items === 'object') {
				_this._load_items(data.items);
				_this.sel.reset();
				_this.sel.stimer.start();
				_this._action_add(actions, 'draw');
				_this._action_add(actions, '_restore_selected');
			}

			// Другие настройки
			let _options = {};
			for (let n of _this._other_options) {
				if (data[n] !== undefined) _options[n] = data[n];
			}
			if (_options = _this._update_options(_options)) {
				if (_options.indexOf('row_number') >=0) _this._action_add(actions, 'make_columns');
				_this._action_add(actions, 'draw');
			}

			if (mode === 'reset') {
				_this._action_add(actions, 'make_columns');
				_this._action_add(actions, 'draw');
			}
		}

		// Выполнение необходимых действий
		for (let a of actions) _this[a]();

		if (event || event === undefined) {
			if (typeof event === 'function') {
				event.call(_this, mode);
			} else {
				_this.widget.dispatchEvent(new CustomEvent('il_data', {detail: {
					list: _this,
					mode: mode
					}
				}));
			}
		}
	}

	/**
	 * Очистка содержимого списка
	 */
	clear() {
		this.ondata({items: []}, 'clear', false);
	}

	/**
	 * Фабрика для функции обработки onscroll
	 */
	_onscroll() {
		const _this = this,
			_scroll = _this.widget,
			classes = _scroll.classList,
			style = _scroll.style,
			cls_hscrolled = _this.options.cls.hscrolled,
			var_scrolltop = _this.options.vars.scrolltop,
			var_scrollleft = _this.options.vars.scrollleft,
			mouse = _this._mouse,
			abs = Math.abs;

		let d = 0;

		return function() {
			if (_scroll.scrollTop != _this.s_top) {
				style.setProperty(var_scrolltop, (_this.s_top = _scroll.scrollTop) + 'px');
				if (abs((_this.s_top) - d) > _this.row_size) {
					_this.draw_view();
					d = _this.s_top;
				}
			}
			if (_scroll.scrollLeft != _this.s_left) {
				style.setProperty(var_scrollleft, (_this.s_left = _scroll.scrollLeft) + 'px');
				if (_scroll.scrollLeft) {
					if (!classes.contains(cls_hscrolled)) {
						classes.add(cls_hscrolled);
					}
				} else if (classes.contains(cls_hscrolled)) {
					classes.remove(cls_hscrolled);
				}
			}
			mouse.on_list_scroll();
		}
	}

	/**
	 * Подготовка списка строк. Внутренний метод.
	 * Вызывается из this.ondata()
	 */
	_load_items(items) {
		const _this = this;

		if (typeof items !== 'object' || !items.length) {
			_this.rows = [];
			_this.items = {};
			return;
		}

		const
			_map = (_this.loaded_cols = items[0]),
			map_len = items[0].length,
			_items = {},
			_rows = [],
			cls = ItemsListItem,
			data_len = items.length;

		let _item, _d;
		// Заполнение this.items и this.rows
		for (let i=1;i<data_len;i++) {
			_d = {};
			for (let j=0;j<map_len;j++) {
				_d[_map[j]] = items[i][j];
			}
			_item =  new cls({list: _this, d: _d, i: i - 1, n: null, u: -1, id: _d[_this.col_id]});
			if (_d[null] && _d[null].no_check) _item.nc = true;
			_items[_item.id] = _item;
			_rows.push(_item);
		}

		_this.rows = _rows;
		_this.items = _items;
	}

	_update_options(options) {
		let _this = this, res = [];
		if (typeof options === 'object') {
			for (let n in options) {
				if (_this._other_options.indexOf(n) < 0) continue;
				if (_this.options[n] !== options[n]) {
					_this.options[n] = options[n];
					_this.widget.classList[options[n] ? 'add' : 'remove']('_' + n);
					res.push(n);
				}
			}
		}
		return res.length ? res : false;
	}

	/**
	 * Добавление пункта в выпадающее меню
	 * @param {*} label
	 * @param {*} icon
	 * @param {*} action
	 * @returns
	 */
	_menu_item(menu, label, icon, action, attrs) {
		let node;
		if (label == '-') {
			(node = document.createElement('div')).className = 'dropdown-divider'
			menu.appendChild(node);
			return;
		} else {
			node = document.createElement('span');
			node.innerText = label;
			if (icon) {
				let _icon = document.createElement('i');
				_icon.className = `${icon} me-2`;
				node.insertAdjacentElement('afterbegin', _icon);
			}
		}

		let item;
		if (action) {
			item = document.createElement('a');
			item.className = 'dropdown-item';
			item.href = '#';
			item.dataset.action = action;
		} else {
			item = document.createElement('div');
			item.className = 'px-3 text-muted small mb-1';
		}
		item.appendChild(node);

		// Дополнительные атрибуты
		for (let [n, v] of Object.entries(attrs || {})) item.setAttribute(n, v);

		menu.appendChild(item);
		return item;
	};

	/**
	 * Установка настроек списка
	 */
	set_options(options, event) {
		const _this = this, actions = [];

		// Видимость колонок
		if (typeof options.columns === 'object' && !_this._arr_eq(_this.columns, options.columns)) {
			_this.columns = options.columns;
			// Данные по видимым колонкам уже загружены?
			let reload = false;
			for (let col of _this.columns) {
				if (_this.loaded_cols.indexOf(col) < 0) {
					reload = true;
					break;
				}
			}

			_this._action_add(actions, 'make_columns');
			if (reload) {
				_this._action_add(actions, 'load_items');
			} else {
				_this._action_add(actions, 'draw');
			}
		}

		// Фиксация колонки
		if (typeof options.fixed === 'object' && !_this._arr_eq(options.fixed, _this.fixed)) {
			_this.fixed = options.fixed;
			_this._action_add(actions, 'make_columns');
			_this._action_add(actions, 'draw');
		}


		// Сортировка
		if (typeof options.sort === 'object' && !_this._arr_eq(options.sort, _this.sort)) {
			_this.sort = options.sort;
			_this._action_add(actions, 'make_sort');
			_this._action_add(actions, 'load_items');
		}

		// Ширина колонок
		if (typeof options.width === 'object') {
			for (let n in options.width) {
				_this.width[n] = options.width[n];
			}
			_this._action_add(actions, 'make_col_widths');
		}

		// Настройки вида списка
		let _options = {};
		for (let n of _this._other_options) {
			if (typeof options[n] !== 'undefined') _options[n] = options[n];
		}
		if (_options = _this._update_options(_options)) {
			if (_options.indexOf('row_number') >=0) _this._action_add(actions, 'make_columns');
			_this._action_add(actions, 'draw');
		}

		// Выполнение необходимых действий
		for (let action of actions) {
			_this[action]();
		}

		if (event || event === undefined) {
			if (_this.options.options_auto_save) _this.get_data.call(_this, 'options', _this._ondata('options'), options);
			_this.widget.dispatchEvent(new CustomEvent('il_settings', {detail: {list: _this, options: options}}));
		}
	}

	/**
	 * Получить текущие настройки списка.
	 * @returns Массив настроек. Параметры настроек соответствуют параметрам
	 * метода set_options
	 */
	get_options() {
		const
			_this = this,
			options = _this.options,
			width = {},
			res = {
				columns: _this.columns,
				sort: _this.sort,
				width: width,
				fixed: _this.fixed
			};

		// Ширина колонок
		for (let n in _this.column)
			if (_this.column[n].resizable !== false && !_this.column[n].hidden) {
				width[n] = _this.width[n]; // || _this.column[n].width;
			}

		// Настройки вида списка
		_this._other_options.forEach(function(v){
			res[v] = options[v];
		});

		return res;
	}

	/**
	 * Получить массив объектов отображаемых колонок в порядке следования.
	 * Фиксированные колонки будут вынесены в начало.
	 */
	get_view_cols() {
		const
			columns = this.columns,
			column = this.column,
			res = [];
		let fixed = 0, col;

		// Колонки отображаемые принудительно
		for (col of Object.values(column)) {
			if (columns.indexOf(col.name) < 0 && col.show) {
				if (col.fixed) {
					res.splice(fixed++, 0, col);
				} else {
					res.push(col);
				}
			}
		}

		for (let name of columns) {
			if (!(col=column[name]) || col.hidden) continue;
			if (col.fixed) {
				res.splice(fixed++, 0, col);
			} else {
				res.push(col);
			}
		}

		return res;
	}

	/**
	 * Получить список колонок для загрузки строк.
	 * Включает скрытые колонки и колонку id.
	 * @returns [] - Массив имён колонок.
	 */
	get_load_cols() {
		let res = [], cols = this.columns;
		for (let [name, col] of Object.entries(this.column)) {
			if (col.hidden || col.id || col.loading || col.show) {
				res.push(name);
			}
		}
		for (let i=0;i<cols.length;i++) if (res.indexOf(cols[i]) < 0) res.push(cols[i]);
		return res;
	}

	/**
	 * Получить список фиксированных колонок (только видимых).
	 * @returns [] - Массив имён колонок.
	 */
	get_fixed_cols(exclude_always) {
		const res = [], col = this.column;
		for (let name of this.columns) {
			try {
				if (col[name].fixed && !(exclude_always && col[name].fixed === 'always')) {
					res.push(name);
				}
			} catch (e) {}
		}
		return res;
	}

	/**
	 * Запросить данные таблицы
	 * @param options {object} - Дополнительные параметры, которые будут
 	 * переданы в метод ``ItemsList.get_data()``.
	 */
	load_items(options, onload) {
		const
			_this = this;
		let opts = {};

		_this.loaded_cols = [];

		_this.sel.save_selected(true);

		let mode = 'items';
		if (options === 'reset') {
			mode = 'reset';
			_this.__last_items_opts = undefined;
		} else if (options === 'init') {
			mode = 'init';
			_this.__last_items_opts = undefined;
		} else {
			opts = {
				'columns': _this.get_load_cols(),
				'sort': _this.sort
			};
			if (typeof options === 'object') {
				_this.__last_items_opts = Object.assign({}, options);
				Object.assign(opts, options);
			} else if (options !== undefined) {
				mode = options;
			}
		}

		const loading_id = _this._loading_id++;
		_this.loading(true, loading_id);
		_this.get_data.call(_this, mode, _this._ondata(mode, onload, loading_id), opts);
	}

	/**
	 * Перезагрузить данные таблицы
	 */
	reload(onload) {
		this.load_items(this.__last_items_opts, onload);
	}

	/**
	 * Установить сортировку таблицы.
	 * @param col_name string - Имя колонки. Для сортировки DESC добавьте "-" в
	 * начале имени.
	 * @param add boolean - true - добавить колонку к существующей сортировке.
	 *                      false - исключить колонку из сортировки.
	 *                      undefined - заменить текущую сортировку.
	 */
	set_sort(col_name, add) {
		if (!col_name) {
			console.error('Error value for parameter col_name', col_name);
			return;
		}
		const
			_this = this,
			res = [];

		if (add !== undefined) {
			const
				sort = _this._sort_map(),
				[, order, name] = /^(\-?)(.+)$/.exec(col_name);

			if (add === false) {
				// delete from current sort
				sort.delete(name);
			} else if (add) {
				sort.set(name, order);
			}
			for (let [col, ord] of sort.entries()) res.push((ord == '-' ? '-' :  '') + col);
		} else {
			res.push(col_name);
		}

		_this.set_options({'sort': res}, true);
	}

	/**
	 * Отрисовка сортировки колонок
	 */
	make_sort() {
		const
			_this = this,
			cls = _this.options.cls;

		// Очистка отображения текущих сортировок
		_this.header.querySelectorAll(`.${cls.cell}.${cls.sort}`).forEach(function(col){
			col.classList.remove(cls.sort);
			col.classList.remove(cls.sort_asc);
			col.classList.remove(cls.sort_desc);
			(col.querySelector('.' + cls.sort_num) || {}).innerHTML = '';
		});

		// Установка сортировок на колонках
		for (let i=0;i<_this.sort.length;i++) {
			let n = /^(-?)(.+)$/.exec(_this.sort[i]);
			let col = _this.header.querySelector(`.${cls.cell}[data-col="${n[2]}"]`);
			if (!col) continue;
			col.classList.add(cls.sort);
			col.classList.add(n[1] == '-' ? cls.sort_desc : cls.sort_asc);
			if (_this.sort.length > 1) try {
				col.querySelector('.' + cls.sort_num).innerText = i + 1;
			} catch(e) {
				// console.error(e);
			}
		}
	}

	/**
	 * Add event listener to element and save handler to ItemsList._events
	 * @param {*} element
	 * @param {*} event
	 * @param {*} handler
	 */
	_event_add(element, event, handler) {
		this._events.push([element, event, handler]);
		element.addEventListener(event, handler);
	}

	/**
	 * Навешивание обработчиков на события.
	 * Вызывается один раз из конструктора.
	 */
	_bind() {
		const
			_this = this,
			sel = _this.sel,
			options = _this.options,
			cls = options.cls,
			widget = _this.widget,
			mouse = (_this._mouse = new ItemsListMouse(_this));

		_this._events = [];

		// Resize
		//
		_this.resize_timer = null;
		let _on_resize_timer = function() {
				_this.resize_timer=null;
				_this.draw_view();
			},
			_on_resize = function() {
				if (_this.resize_timer!==null)
					clearTimeout(_this.resize_timer);
				_this.resize_timer = setTimeout(_on_resize_timer, 100);
			};
		_this._event_add(widget, 'resize', _on_resize);
		_this._event_add(window, 'resize', _on_resize);

		// Keydown
		_this._event_add(widget, 'keydown', function(e){
			// Скрыть tooltip
			if (_this._mouse.tt) _this._mouse.tooltip();

			// Keypress
			switch (e.key) {
				case 'ArrowDown': // DOWN
					if (sel.focus_row(1, e.shiftKey)) {
						e.preventDefault();
						return;
					}
					break;
				case 'ArrowUp': // UP
					if (sel.focus_row(-1, e.shiftKey)) {
						e.preventDefault();
						return;
					}
					break;
				case 'ArrowRight': // RIGHT
					if (!e.ctrlKey && options.cells) {
						if (sel.focus_col(1)) {
							e.preventDefault();
							return;
						}
					}
					break;
				case 'ArrowLeft': // LEFT
					if (!e.ctrlKey && options.cells) {
						if (sel.focus_col(-1)) {
							e.preventDefault();
							return;
						}
					}
					break;
				case 'PageUp': // Page UP
					sel.focus_row(-_this.get_psize(), e.shiftKey);
					e.preventDefault();
					return;
				case 'PageDown': // Page DOWN
					sel.focus_row(_this.get_psize(), e.shiftKey);
					e.preventDefault();
					return;
				case 'Home': // HOME
					if (e.ctrlKey) { // в начало строки
						widget.scrollLeft = 0;
						if (options.cells) sel.focus_col('f');
					} else { // в начало списка
						sel.focus(0, undefined, e.shiftKey);
					}
					e.preventDefault();
					return;
				case 'End': // END
					if (e.ctrlKey) { // В конец строки
						widget.scrollLeft = widget.clientWidth;
						if (options.cells) sel.focus_col('l');
					} else { // В конец списка
						sel.focus(_this.rows.length-1, undefined, e.shiftKey);
					}
					e.preventDefault();
					return;
				case 'a': // a
					if (e.ctrlKey && !e.altKey) {
						if (e.shiftKey) sel.unselect_all(); else sel.select_all();
						e.preventDefault();
						return;
					}
					break;
				case 'A': // a
					if (e.ctrlKey && !e.altKey) {
						sel.unselect_all();
						e.preventDefault();
						return;
					}
					break;
				case 'r': // r
					if (e.ctrlKey) {
						_this.reload();
						e.preventDefault();
						return;
					}
					break;
				case 'F5': // F5
					if (!e.ctrlKey) {
						_this.reload();
						e.preventDefault();
						return;
					}
					break;
				}
				if (options.keydown && options.keydown.call(widget, e, _this) === false) {
					e.preventDefault();
				}
			});

		// Keypress
		_this._event_add(widget, 'keypress', function(e) {
				// Space
				if (e.key == ' ') {
					if (options.multiple) sel.toggle(sel.r, e.shiftKey);
					e.preventDefault();
					e.stopPropagation();
				}
			});

		// Mouse events for widget
		//
		const onmousedown = function(e){
				const t = e.target, t_cls = t.classList;
				let col;

				if (!mouse.default) mouse.up(e);
				if (e.button == 0 || e.touches) {
					if (t_cls.contains(cls.resize_handle)) {
						mouse.colresize_start(col, e);
					} else if ((col = _this._closest(t, cls.label, _this.header)) && (col = col.closest('.'+cls.cell+'[data-col]'))) {
						mouse.colmove_start(col, e);
					}
				}
			},
			onmousemove = function(e) { mouse.move(e); },
			onmouseup = function(e) { mouse.up(e); };

		_this._event_add(_this.header, 'mousedown', onmousedown);
		_this._event_add(_this.header, 'touchstart', onmousedown);

		_this._event_add(widget, 'mousemove', onmousemove);
		_this._event_add(widget, 'touchmove', onmousemove);
		_this._event_add(widget, 'mouseup', onmouseup);
		_this._event_add(widget, 'touchend', onmouseup);
		_this._event_add(widget, 'touchcancel', onmouseup);
		_this._event_add(widget, 'mouseleave', onmousemove);

		_this._event_add(widget, 'click', function(e){
				const target = e.target;

				// Скрыть tooltip
				if (_this._mouse.tt) mouse.tooltip();

				// Вернуть стандартное подвисшее состояние мыши
				if (!mouse.default) mouse.up(e);

				// Если клик на resize-handler выходим
				if (_this._closest(target, cls.resize_handle)) return;

				let cell = _this._closest(target, cls.cell),
					row = _this._closest(target, cls.row);

				// Клик на строке
				if (row) {
					let c = cell && cell.classList.contains(cls.cell) && !cell.classList.contains(cls.col_check) ? Array.prototype.indexOf.call(cell.parentNode.childNodes, cell) - 1: sel.c,
						r = parseInt(row.getAttribute('data-index'));
					sel.focus(r, c);
					if (target.classList.contains(cls.col_check) && options.multiple) sel.toggle(r, e.shiftKey);
					return;
				}

				// Клик в заголовке
				if (_this._closest(target, cls.header)) {

					// Показать настройки списка
					if (target === _this.setup_button.firstElementChild) {
						_this.list_menu();
					}
					else
					// Показать меню колонки
					if (cell && cell.classList.contains(cls.click)) {
						_this.col_menu(cell);
					}
					else
					// Показать меню выбора строк.
					if (_this._closest(target, cls.col_check)) {
						sel.select_dialog();
					}
					e.preventDefault();
				}
			});

		// Scroll
		_this._event_add(widget, 'scroll', _this._onscroll());
	}

	/**
	 * Назначение обработчика события
	 * @param event string - Тип события
	 * @param handler callback - Функция-обработчик.
	 * @returns ItemsList - Возвращает объект для которого была вызван метод
	 * (this).  Позволяет создавать цепочки вызовов как в jQuery.
	 */
	on(event, handler) {
		this._event_add(this.widget, event, handler);
		return this;
	}

	/**
	 * Возвращает элементы для которых произошло событие event (Event).  Объект с атрибутами:
	 * col - объект ItemsListColumn
	 * item - объект ItemsListItem
	 *
	 * Элементы определяются по event.target.
	 */
	get_targets(event) {
		const
			_this = this,
			widget = _this.widget,
			cls = _this.options.cls,
			res = {};
		let target = event.target;
		while (target && target !== widget) {
			if (target.dataset.col && target.classList.contains(cls.cell)) {
				res.col = _this.column[target.dataset.col];
			} else if (target.dataset.id && target.classList.contains(cls.row)) {
				res.item = _this.items[target.dataset.id];
			}
			target = target.parentElement;
		}
		return res;
	}

	/**
	 * Показать выпадающее меню.
	 * @param toggler {Object} - Элемент для которого отображается меню (DOM)
	 * @param content {Object} - Элемент содержащий меню (DOM)
	 */
    _dropdown(toggler, content) {
		const _this = this;

		if (_this.active_dropdown) { _this.active_dropdown.hide(); }

		if (toggler.dataset.bsToggle == 'dropdown') return;
		toggler.setAttribute('data-bs-toggle', 'dropdown');
		toggler.setAttribute('aria-expanded', 'false');
		toggler.insertAdjacentElement('afterend', content);
		let dropdown
		_this.active_dropdown = (dropdown = new Dropdown(toggler, {autoClose: true}));
		const event = () => {
			if (dropdown === _this.active_dropdown) delete _this.active_dropdown;
			toggler.removeAttribute('data-bs-toggle');
			toggler.removeEventListener('hidden.bs.dropdown', event);
			dropdown.dispose();
			content.remove();
		};
		toggler.addEventListener('hidden.bs.dropdown', event);
		dropdown.show();
		return dropdown;
	}

	/**
	 * Return Map object from array sort.  Key - column name, value - '' or '-' (desc sort order).
	 */
	_sort_map() {
		const res = new Map();
		(this.sort || []).forEach((v) => { if (v.startsWith('-')) res.set(v.slice(1), 'd'); else res.set(v, 'a'); });
		return res;
	}


	/**
	 * Возвращает массив элементов для меню колонки.
	 * Если exists=true, то возвращает true, если есть хотя бы один элемент.
	 * @param {*} node
	 * @param {*} exists
	 */
	col_menu_items(node, exists) {
		const
			_this = this,
			col_name = node.getAttribute('data-col'),
			col = _this.column[col_name],
			ico = _this.options.ico_classes,
			res = [];

		// Сортировка
		if (col.sort) {
			if (exists) return true; // Меню содержит элементы

			const sort = _this._sort_map();
			let current_sort = sort.get(col_name);

			res.push(['Сортировка по...']);
			if ((current_sort || 'd') === 'd') {
				res.push(['возрастанию', ico.sort_asc, current_sort && sort.size > 1 ? 'add-asc' : 'asc']);
			}
			if ((current_sort || 'a') === 'a') {
				res.push(['убыванию', ico.sort_desc, current_sort && sort.size > 1 ? 'add-desc' : 'desc']);
			}

			if (current_sort) {
				res.push(['-']);
				res.push(['Убрать сортировку', ico.x, 'clear']);
			} else if (sort.size && !_this.options.sort_one) {
				if (!current_sort) res.push(['-']);
				res.push(['Сортировка по...', ico.plus, null, {title: "Добавить к существующей"}]);
				res.push(['возрастанию', ico.sort_asc, 'add-asc', {title: "Добавить к существующей"}]);
				res.push(['убыванию', ico.sort_desc, 'add-desc', {title: "Добавить к существующей"}]);
			}

		}

		if (!col.not_fixed && col.show !== 'fixed') {
			if (res.length) res.push(['-']);
		}

		if (!col.not_fixed && col.show !== 'fixed') {
			if (exists) return true; // Меню содержит элементы

			// Фиксация колонки
			if (col.fixed) {
				res.push(['Открепить колонку', ico.pin_off, 'fix-off']);
			} else {
				res.push(['Фиксировать колонку', ico.pin_on, 'fix-on']);
			}
		}

		if (!col.show) {
			if (exists) return true; // Меню содержит элементы
			// Скрыть колонку
			res.push(['Скрыть колонку', ico.hide, 'hide']);
		}

		return exists ? false : res;
	}

	/**
	 * Показать меню колонок.
	 * @param node {Object} - Элемент заголовка колонки (DOM)
	 */
	col_menu(node) {
		const
			_this = this,
			items = _this.col_menu_items(node);

		// Нет элементов меню или нажатие уже обрабатывется bootstrap
		if (!items.length || (node.dataset.bsToggle == 'dropdown')) return;

		const
			col_name = node.getAttribute('data-col'),
			dlg = document.createElement('DIV');
		let dropdown;
		dlg.className = 'dropdown-menu';

		for (let item of items) {
			item.unshift(dlg);
			_this._menu_item.apply(_this, item);
		}

		const action = function(e){
			let t = e.target.closest('[data-action]');
			if (!t) return;

			dropdown.hide();

			switch (t.dataset.action){
			case "asc":
				_this.set_sort(col_name);
				break;
			case "desc":
				_this.set_sort('-' + col_name);
				break;
			case "clear":
				_this.set_sort(col_name, false);
				break;
			case "add-asc":
				_this.set_sort(col_name, true);
				break;
			case "add-desc":
				_this.set_sort('-' + col_name, true);
				break;
			case "hide":
				_this.column_hide(col_name);
				break;
			case "fix-on":
				_this.column_fix(col_name, true);
				break;
			case "fix-off":
				_this.column_fix(col_name, false);
				break;
			}
		};

		dlg.addEventListener('click', action);

		dropdown = _this._dropdown(node, dlg);
	}

	/**
	 * Показать меню настройки и доп.элементов.
	 */
	list_menu() {
		const
			_this = this,
			btn = _this.setup_button.firstElementChild,
			icons = _this.options.ico_classes,
			menu_item = _this._menu_item;
		if (btn.dataset.bsToggle == 'dropdown') return;

		const menu = document.createElement('div');
		menu.className = 'dropdown-menu';
		menu_item(menu, 'Колонки...', icons.cfg_cols, 'cols',
							 {title: 'Настройка колонок таблицы'}
							 ).addEventListener('click', () => _this.cols_dialog());
		menu_item(menu, 'Вид...', icons.cfg_view, 'view',
							 {title: 'Настройка внешнего вида таблицы'}
							 ).addEventListener('click', () => _this.view_dialog());
		if (_this.options.reset_allow) {
			menu_item(menu, '-');
			menu_item(menu, 'Сбросить настройки', icons.reset_view, 'reset-view',
								 {title: 'Вернуть настройки таблицы в состояние по умолчанию'}
								 ).addEventListener('click', () => _this.reset_options());

		}
		if (_this.sort && _this.sort.length) {
			if (!_this.options.reset_allow) menu_item(menu, '-');
			menu_item(menu, 'Убрать сортировку', icons.clear_sort, 'clear-sort',
								 {title: 'Убрать все установленные сортировки'}
								 ).addEventListener('click', () => _this.set_options({'sort': []}, true));

		}
		menu_item(menu, '-');
		menu_item(menu, 'Обновить', icons.reload, 'reload',
							 {title: 'Настройка внешнего вида таблицы'}
							 ).addEventListener('click', () => { _this.reload(); _this.focus();});
		_this._dropdown(btn, menu);
	}

	/**
	 * Получение ближайшего родительского элемента с указанным классом в
	 * пределах parent. Если не найден, то возвращается null.
	 */
	_closest(node, cls, parent) {
		if (!parent) parent = this.widget;
		while (node && (node !== parent)) {
			if (node.classList && node.classList.contains(cls)) return node;
			node = node.parentElement;
		}
		return null;
	}

	/**
	 * Вернуть настройки списка в состояние по умолчанию
	 */
	reset_options() {
		const _this = this,
			cls = _this.options.cls,
			dlg = document.createElement('DIV');

		dlg.className = 'modal';
		dlg.tabIndex = -1;
		//dlg.setAttribute('data-bs-backdrop', 'static');
		dlg.innerHTML = '<div class="modal-dialog modal-dialog-centered">'
			+`<div class="modal-content ${cls.dlg}">`
			// modal-body
			+'<div class="modal-body">'
			+'<div>Вернуть настройки списка в состояние по умолчанию?</div>'
			+'</div>' // modal-body

		  	+'<div class="modal-footer">'// modal-body
			  +`<button type="button" data-action="apply" class="${_this.options.btn_classes.all} ${_this.options.btn_classes.primary}">Да</button>`
			  +`<button type="button" data-action="cancel" class="${_this.options.btn_classes.all} ${_this.options.btn_classes.secondary}">Нет</button>`
		  	+'</div>' // modal-footer
			+'</div>' // modal-content
	  		+'</div>' // modal-dialog;

		// Удалить диалог после закрытия
		dlg.addEventListener('hidden.bs.modal', function(){
			dlg.remove();
		});
		dlg.addEventListener('shown.bs.modal', function(){
			const backdrop = dlg.previousElementSibling;
			if (backdrop && backdrop.classList.contains('modal-backdrop')) {
				backdrop.style.zIndex = getComputedStyle(dlg).zIndex;
			}
		});

		let modal = new Modal(dlg);

		// Обработчики действий
		const close = function(e){
			modal.hide();
		};

		const action = function(e) {
			let t = e.target.closest('[data-action]');
			if (!t) return;
			switch (t.getAttribute('data-action')) {
			case "apply":
				_this.load_items('reset');
				close();
				break;
			case "cancel":
			case "close":
				close();
				break;
			default:
				return;
			}
			e.preventDefault();
		};

		// Обработчик action
		dlg.querySelectorAll('[data-action]').forEach(function(node){
			node.addEventListener('click', action);
		});

		// Показать диалог
		modal.show();

	}


	/**
	 * Диалог настроек колонок списка.
	 */
	cols_dialog() {
		new ItemsListColumnsDialog(this);
	}

	/**
	 * Диалог настроек внешнего вида списка.
	 */
	view_dialog() {
		const _this = this,
			cls = _this.options.cls,
			dlg = document.createElement('DIV');

		dlg.className = 'modal';
		dlg.tabIndex = -1;
		dlg.setAttribute('data-bs-backdrop', 'static');
		dlg.innerHTML = '<div class="modal-dialog modal-fullscreen-sm-down modal-dialog-scrollable modal-dialog-centered">'
			+`<div class="modal-content ${cls.dlg} ${cls.dlg_view}">`
			// modal-header
			+'<div class="modal-header"><div class="modal-title">Вид</div></div>'
			// modal-body
			+'<div class="modal-body">'
			+'</div>' // modal-body

		  	+'<div class="modal-footer">'// modal-body
			  +'<button type="button" data-action="apply">Применить</button><button type="button" data-action="cancel">Отмена</button>'
		  	+'</div>' // modal-footer
			+'</div>' // modal-content
	  		+'</div>' // modal-dialog;

		const dlg_body = dlg.querySelector('.modal-body'), html_id = uniq_id();
		for (let [name, label] of [
			['compact', 'Компактный'],
			['striped', 'Подсветка строк'],
			['vborder', 'Вертикальные границы'],
			['hborder', 'Горизонтальные границы'],
			['row_number', 'Номера строк']
		]) {
			dlg_body.insertAdjacentHTML('beforeend', `<div class="form-check"><input class="form-check-input" type="checkbox" data-view="${name}" id="${html_id}-${name}">
			<label class="form-check-label" for="${html_id}-${name}">${label}</label>
		  </div>`);
		}

		let btn_apply;

		// Обработчик включающий кнопку "Применить"
		const apply_activate = function(){
			btn_apply.disabled = false;
		};

		// Настройки вида
		dlg.querySelectorAll('input[data-view]').forEach(function(node){
			node.addEventListener('change', apply_activate);
			node.checked = _this.options[node.dataset.view];
		});

		// Удалить диалог после закрытия
		dlg.addEventListener('hidden.bs.modal', function(){
			window.removeEventListener('resize', onresize);
			dlg.remove();
		});
		dlg.addEventListener('shown.bs.modal', function(){
			const backdrop = dlg.previousElementSibling;
			if (backdrop && backdrop.classList.contains('modal-backdrop')) {
				backdrop.style.zIndex = getComputedStyle(dlg).zIndex;
			}
		});

		let modal = new Modal(dlg);

		// Обработчики действий
		const close = function(e){
			modal.hide();
		};

		const action = function(e) {
			let t = e.target.closest('[data-action]');
			if (!t) return;
			switch (t.getAttribute('data-action')) {
			case "apply":
				const opts = {};
				// Настройки вида
				dlg.querySelectorAll('input[data-view]').forEach(function(node){
					opts[node.getAttribute('data-view')] = node.checked;
				});
				_this.set_options(opts, true);
				close();
				break;
			case "cancel":
			case "close":
				close();
				break;
			default:
				return;
			}
			e.preventDefault();
		};

		// Кнопка "Применить"
		(btn_apply = dlg.querySelector('[data-action="apply"]')).className = _this.options.btn_classes.all + ' ' + _this.options.btn_classes.primary;
		btn_apply.disabled = true;

		// Кнопка "Отмена"
		dlg.querySelector('[data-action="cancel"]').className = _this.options.btn_classes.all + ' ' + _this.options.btn_classes.secondary;

		// Обработчик action
		dlg.querySelectorAll('[data-action]').forEach(function(node){
			node.addEventListener('click', action);
		});

		// Показать диалог
		modal.show();
	}

	/**
	 * Включение режима loading списка.
	 * @param on {boolean} - false - отключить режим loading.
	 */
	loading(on, loading_id) {
		const
			_this = this,
			loading_set = _this._loading_set || (_this._loading_set = new Set());
		if (on !== false) {
			_this.is_loading = true;
			loading_set.clear();
			loading_set.add(loading_id);
			on = 'add';
		} else {
			loading_set.delete(loading_id);
			if (loading_set.size) return;
			on = 'remove';
			_this.is_loading = false;
		}
		_this.widget.classList[on](_this.options.cls.loading);
	}

	/**
	 * Внутрений метод. Shortcut для sel.restore_selected.
	 * Используется в методе ondata().
	 */
	_restore_selected() {
		this.sel.restore_selected();
		if (this.options.autofocus && !this.sel.is_focused()) this.sel.focus(0, 0);
	}

	/**
	 * Удалить строки с указанными идентификаторами.
	 * @param id - Идентификатор или массив идентификаторов строк
	 */
	del(id) {
		if (typeof id !== 'object') id = [id];
		const _this = this,
			rows = _this.rows,
			items = _this.items,
			sel = _this.sel;
		let min_r = rows.length, r, _id;

		for (let i=0;i<id.length;i++)
			try {
				r = items[_id = id[i]].i;

				if (items[_id].n) items[_id].n.remove();
				delete items[_id];
				rows[r] = null;
				if (sel.sel[_id]) {
					delete sel.sel[_id];
					sel.length--;
				}
				if (r < min_r) min_r = r;
			} catch(e) {}

		for (let i=min_r;i<rows.length;i++) {
			if (rows[i]) rows[i].i = i;
			else {
				while (!rows[i] && i < rows.length) rows.splice(i, 1);
				i--;
			}
		}
		_this.upd_id++;
		_this.sel.draw_count();
		_this.draw();
	}

	/**
	 * Проверка, что объект не содержит атрибутов.
	 * Служебный метод.
	 */
	_is_empty(obj) {
	    for(var prop in (obj || {})) { if(obj.hasOwnProperty(prop)) return false; }
	    return true;
	}

	/**
	 * Скрыть колонку с указанным именем
	 */
	column_hide(name) {
		const columns = [];
		for (let _name of this.columns) if (_name !== name) columns.push(_name);
		this.set_options({columns: columns}, true);
	}

	/**
	 * Фиксировать/открепить колонку с указанным именем
	 */
	column_fix(name, fix) {
		const
			fixed = this.get_fixed_cols(true),
			i = fixed.indexOf(name);

		if (fix && (i < 0)) {
			fixed.push(name);
		} else if (!fix && (i >= 0)) {
			fixed.splice(i, 1);
		}

		const columns = [];
		for (let name of fixed) columns.push(name);
		for (let name of this.columns) if (fixed.indexOf(name) < 0) columns.push(name);

		this.set_options({columns: columns, fixed: fixed}, true);
	}

}

/**
 * Строка списка.
 * @member list {ItemsList} - Список
 * @member d {Object} - Данные строки
 * @member i {number} - Номер строки в rows
 * @member n {Object} - Отрисованная строка (DOM)
 * @member s {boolean} - Строка выбрана
 * @member u {number} - Индикатор перерисовки
 * @member nc {boolean} - Признак no_check
 */
class ItemsListItem {
	constructor(data) {
		Object.assign(this, data);
	}
}


/**
 * Обработчик событий мыши в ItemsList
 */
class ItemsListMouse {

	/**
	 * Размер зоны включения автопрокрутки (в пикселях).
	 */
	AutoSize = 10;

	/**
	 * Шаг автопрокрутки (в пикселях).
	 */
	AutoStep = 30;

	/**
	 * Тик автоскроллинга в мс (Скорость автоскроллинга)
	 */
	AutoTick = 50;

	/**
	 * Данные текущего режима отслеживания.
	 */
	data;

	/**
	 * Текущий обработчик события mousemove
	 */
	move;

	/**
	 * Текущий обработчик события mouseup
	 */
	up;

	/**
	 * Текущий обработчик mousemove подключённый к window
	 */
	window_move = null;

	/**
	 * Текущий обработчик mouseup подключённый к window
	 */
	window_up = null;

	constructor(list) {
		const _this = this;
		_this.list = list;
		_this.scroll = list.widget;

		if (!list.options.tooltips) {
			_this._default_move = _this._stub;
		} else {
			_this.on_now = null; // Мышь находится над данным элементом сейчас
			_this.on_old = null; // Мышь находилась над данным элементом в прошлый раз
			_this.movetimer = setInterval(_this.onmovetimer(), 200);
		}
		_this.reset();
	}

	/**
	 * Восстановление состояние по умолчанию
	 */
	reset() {
		const _this = this;
		_this.move = this._default_move;
		_this.up = this._stub;
		if (_this.window_move) {
			window.removeEventListener('mousemove', _this.window_move);
			_this.window_move = null;
		}
		if (_this.window_up) {
			window.removeEventListener('mouseup', _this.window_up);
			_this.window_up = null;
		}
		_this.default = true;
	}

	/**
	 * Запустить режим отслеживания изменения размера колонки
	 * @param col {string} - Имя колонки
	 * @param e {Object} - Объект Event.
	 */
	colresize_start(col, e) {
		const _this = this,
			list = _this.list,
			node = e.target.closest('[data-col]'),
			widget_rect = list.widget.getBoundingClientRect();

		// Заполнение ширины колонок
		const [widths, names] = list.col_widths();
		// Ширина последней колонки - кнопки настроек списка
		//widths.push(list.header.childNodes[list.header.childNodes.length - 1].offsetWidth + 'px');
		widths.push('100%');

		// Колонка для которой меняется ширина
		col = node.dataset.col;
		list.widget.classList.add(list.options.cls.col_resize);
		if (e.touches) e = e.touches[0];

		// Левая координата для автоскроллинга
		let _left = widget_rect.left + _this.AutoSize;
		for (let cell of list.header.childNodes) {
			if (cell === node || !cell.classList.contains(list.options.cls.fixed_left)) break;
			_left += cell.offsetWidth;
		}

		// Правая координата для автоскроллинга
		let _right = widget_rect.right - _this.AutoSize;
		/*
		for (let i=list.header.childNodes.length - 1;i>=0;i--) {
			let cell = list.header.childNodes[i];
			if (cell === node || cell.classList.contains(list.options.cls.cell)) break;
			_right -= cell.offsetWidth;
		}
		*/

		_this.data = {
			n: node,
			col: col,
			ow: node.offsetWidth,
			idx: names.indexOf(col),
			widths: widths,
			left: _left,
			right: _right,
			x_add: node.getBoundingClientRect().right - e.clientX, // Отступ от правого края до курсора мыши
			scroll: _this.calc_col_width
		}

		if (node.classList.contains(list.options.cls.fixed_left)) {
			let f_index = 0;
			for (let _node of node.parentElement.childNodes) {
				if (node === _node) break;
				f_index++;
			}
			if (f_index < list.f_last) {
				_this.data.f_index = f_index + 1;
				_this.data.f_nodes = [];
				for (let i=_this.data.f_index;i<=list.f_last;i++) {
					let _col = [list.header.childNodes[i]], row=list.scroll_top.nextElementSibling;
					while (row && row !== list.scroll_bottom) {
						_col.push(row.childNodes[i]);
						row = row.nextElementSibling;
					}
					_this.data.f_nodes.push(_col);
				}
			}
		}

		/**
		 * Установка обработчиков
		 */
		//_this.up = _this.colresize_up;
		//_this.move = _this.colresize_move;
		_this.move = _this._stub;
		window.addEventListener('mousemove', (_this.window_move = (e) => _this.colresize_move(e)));
		window.addEventListener('mouseup', (_this.window_up = (e) => _this.colresize_up(e)));
		_this.default = false;
	}

	/**
	 * Обработчик события mousemove для изменения размера колонки
	 * @param e {Object} - Event
	 */
	colresize_move(e) {
		this._scroll(e);
		this.calc_col_width(e);
		if (e.touches) e.preventDefault();
	}

	/**
	 * Расчет
	 * @param e {Object} - Event
	 */
	calc_col_width(e) {
		const _this = this, data = _this.data;

		data.w = Math.max(10, (e.touches ? e.touches[0] : e).clientX - data.n.getBoundingClientRect().left + data.x_add);
		data.widths[data.idx] = data.w + 'px';
		_this.list.widget.style.gridTemplateColumns = data.widths.join(' ');
		if (data.f_nodes) {
			const f_left = _this.list.f_left;
			let left = f_left[data.f_index - 1] + data.w, w;
			for (let _nodes of data.f_nodes) {
				w = 0;
				for (let _node of _nodes) {
					if (!w) w = _node.offsetWidth;
					_node.style.left = left + 'px';
				}
				left += w;
			}
		}
	}

	/**
	 * Обработчик события mouseup для изменения размера колонки
	 * @param e {Object} - Event
	 */
	colresize_up(e) {
		const _this = this;

		// Установка стандартных обработчиков
		_this.reset();

		// Остановить автоматический скроллинг
		_this._scroll_stop();

		_this.list.widget.classList.remove(_this.list.options.cls.col_resize)
		// Если было выполнено изменение размера
		if (_this.data.w !== undefined) _this.set_col_width();
		//if (e.touches && e.touches.length) _this.calc_col_width(e);

		_this.data = null;
	}

	/**
	 * Установить ширину колонки.
	 * Преобразует текущую установленную ширину колонки в пикселях в настроенные единицы
	 * измерения колонки.
	 */
	set_col_width() {
		const data = this.data, list = this.list;
		let old = /([0-9.]+)([a-z]+)/.exec(list.width[data.col] || list.column[data.col].width),
			width;
		if (!old || old[2] == 'px') {
			width = data.w + 'px';
		} else {
			width = Math.round(data.w / data.ow * old[1] * 100000) / 100000 + old[2];
		}
		let _w = {};
		_w[data.col] = width;

		list.set_options({width: _w}, true);
	}

	/**
	 * Запукск таймаута начала перемещения ячейки
	 * @param {*} node
	 * @param {*} e
	 * @returns
	 */
	colmove_start(node, e) {
		let timeout;
		const
			_this = this,
			_stop = function(e) {
				if (e !== 'start') try { clearTimeout(timeout); } catch(exc) {}
				_this.reset();
			};
		window.addEventListener('mousemove', _this.window_move = _stop);
		window.addEventListener('mouseup', _this.window_up = _stop);
		_this.default = false;
		timeout = setTimeout(() => {_stop('start'); _this.colmove_start_(node, e)}, 300);
	}

	/**
	 * Запустить режим отслеживания перемещения колонки
	 * @param name {string} - Имя колонки
	 * @param node {Object} - DOM загловка колонки.
	 */
	colmove_start_(node, e) {
		const _this = this,
			list = _this.list,
			cls = list.options.cls,
			ghost = node.cloneNode(true),
			cols = [];

		if (list.active_dropdown) { list.active_dropdown.hide(); }

		// Список колонок с шириной
		let curr = -1, left = 0;
		for (let i of list.header.children) {
			if (!i.classList.contains(cls.cell)) continue;
			if (i.classList.contains(cls.col_check)) {
				left +=  i.offsetWidth;
				continue;
			}
			if (i === node) curr = cols.length;
			cols.push([i, left, i.offsetWidth + left, i.offsetWidth / 2 + left]);
			left +=  i.offsetWidth;
		}
		if (curr >= 0) {
			cols[curr].push('c');
			if (curr) cols[curr - 1].push('l');
			if ((curr + 1) < cols.length) cols[curr + 1].push('r');
		}

		// Левая координата для автоскроллинга
		let _left = list.widget.getBoundingClientRect().left + _this.AutoSize;
		for (let cell of list.header.childNodes) {
			if (cell.classList.contains(cls.cell) && !cell.classList.contains(cls.fixed_left)) break;
			_left += cell.offsetWidth;
		}

		// Правая координата для автоскроллинга
		let _right = list.widget.getBoundingClientRect().right - _this.AutoSize;
		for (let i=list.header.childNodes.length - 1;i>=0;i--) {
			let cell = list.header.childNodes[i];
			if (cell.classList.contains(cls.cell)) break;
			_right -= cell.offsetWidth;
		}

		// Настройки ghost
		ghost.style.left = '';
		ghost.classList.add(cls.move_ghost, cls.last);

		_this.data = {
			//col: name,
			node: node,
			ghost: ghost,
			before: null, // placeholder находится перед элементом before
			ph: null, // placeholder
			cols: cols,
			left: _left,
			right: _right
		}

		/**
		 * Установка обработчиков
		 */
		//_this.up = _this.colmove_up;
		//_this.move = _this.colmove_move;
		window.addEventListener('mousemove', (_this.window_move = (e) => _this.colmove_move(e)));
		window.addEventListener('mouseup', (_this.window_up = (e) => _this.colmove_up(e)));
		_this.default = false;

		// Создание "ghost" колонки при первом вызове метода
		//_this.list.widget.classList.add('_col-move');
		node.classList.add(list.options.cls.moving);
		list.header.appendChild(ghost);
		const x = (e.touches ? e.touches[0] : e).clientX - _this.scroll.getBoundingClientRect().left + _this.scroll.scrollLeft;
		ghost.style.left = x - ghost.offsetWidth / 2 + 'px';
		ghost.style.top = _this.scroll.scrollTop + 10 + 'px';

	}

	/**
	 * Обработчик события mousemove для перемещения колонки
	 * @param e {Object} - Event
	 */
	colmove_move(e) {
		const _this = this, data = _this.data, list = _this.list, scroll = _this.scroll;

		_this._scroll(e);

		// Создание "ghost" колонки при первом вызове метода
		if (!data.ghost.style.left) {
			//_this.list.widget.classList.add('_col-move');
			data.node.classList.add(list.options.cls.moving);
			list.header.appendChild(data.ghost);
		}

		// Позиция курсора внутри колонок и положение "ghost" колонки
		const x = (e.touches ? e.touches[0] : e).clientX - scroll.getBoundingClientRect().left + scroll.scrollLeft;
		data.ghost.style.left = x - data.ghost.offsetWidth / 2 + 'px';
		data.ghost.style.top = scroll.scrollTop + 10 + 'px';

		// Определение колонки над которой находится курсор
		let _col, idx = 0;
		for (_col of data.cols) { if (x < _col[2]) { break; } idx++; }
		let [col, left, right, middle, mode] = _col, ph;

		if (mode != 'c' // Текущая перемещаемая колонка
			&& !((mode == 'l') && (x >= middle)) // Колонка перед перемещаемой
			&& !((mode == 'r') && (x < middle)) // Колонка после перемещаемой
			) {
			if (x < middle) {
				ph = [idx > 0 ? idx -1 : null, idx];
			} else {
				ph = [idx, idx < (data.cols.length - 1) ? idx + 1 : null];
			}
		}

		// Убрать предыдущую подсветку колонок
		if (data.ph && (!ph || (data.ph[0] != ph[0] || data.ph[1] != ph[1]))) {
			if (data.ph[0] !== null) data.cols[data.ph[0]][0].classList.remove(list.options.cls.col_after);
			if (data.ph[1] !== null) data.cols[data.ph[1]][0].classList.remove(list.options.cls.col_before);
			data.ph = null;
		}

		// Установить подсветку колонок
		if (ph) {
			try {
				if (ph[0] !== null) data.cols[ph[0]][0].classList.add(list.options.cls.col_after);
				if (ph[1] !== null) data.cols[ph[1]][0].classList.add(list.options.cls.col_before);
				data.ph = ph;
			} catch(e) {}
		}

		if (e.touches) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	/**
	 * Обработчик события mouseup для перемещения колонки
	 * @param e {Object} - Event
	 */
	colmove_up(e) {
		const _this = this;

		_this.reset();

		if (_this.data && _this.data.ghost) {
			_this._scroll_stop();
			_this.data.ghost.remove();

			const moved_col = _this.data.node;
			moved_col.classList.remove(_this.list.options.cls.moving);

			const columns = [], fixed = [];
			if (_this.data.ph) {
				let after;
				const cls_fixed = _this.list.options.cls.fixed_left;

				/*
				 * На момент вызова данного обработчика размещение элементов в headers остаётся
				 * первоначальным. Обработчик формирует массивы нового расположения и фиксации
				 * колонок. Данные массивы передаются в метод ItemsList.set_options(), который
				 * и перестраивает список в соответствии с изменениями.
				 */

				if (_this.data.ph[0] !== null) {
					_this.data.cols[_this.data.ph[0]][0].classList.remove(_this.list.options.cls.col_after);
					// Колонка перемещена после "after"
					after = _this.data.cols[_this.data.ph[0]][0];
				} else {
					// Колонка перемещена в начало
					columns.push(moved_col.dataset.col);
					// Колонка должна стать фиксированной?
					try {
						if (_this.list.header.childNodes[0].classList.contains(cls_fixed) ||
							moved_col.classList.contains(cls_fixed)) {
							fixed.push(moved_col.dataset.col);
						}
					} catch(e) {}
				}
				if (_this.data.ph[1] !== null) {
					_this.data.cols[_this.data.ph[1]][0].classList.remove(_this.list.options.cls.col_before);
				}
				_this.data.ph = null;

				// Строится массив следования колонок
				for (let col of _this.list.header.childNodes) {
					if (!col.dataset.col) continue; // Служебная колонка

					if (col === moved_col) {
						// Перемещённая колонка
						continue;
					}

					columns.push(col.dataset.col);
					if (col.classList.contains(cls_fixed)) {
						fixed.push(col.dataset.col);
					}

					if (col === after) {
						columns.push(moved_col.dataset.col);
						if (col.classList.contains(cls_fixed) &&
							(moved_col.classList.contains(cls_fixed)
							|| (col.nextElementSibling && col.nextElementSibling.classList.contains(cls_fixed))
							)) {
								fixed.push(moved_col.dataset.col);
							}
					}
				}
				_this.list.set_options({columns: columns, fixed: fixed}, true);
			}
		}
		_this.data = null;
	}

	/**
	 * Обработчик автоскроллинга
	 */
	_scroll(e) {
		const _this = this, data = _this.data;
		if (data) {
			if ( (e.touches ? e.touches[0] : e).clientX < data.left ) {
				if ( !data.lt ) {
					if ( data.rt ) { clearInterval(data.rt); data.rt = null }
					data.lt = setInterval(function(){
						_this.scroll.scrollLeft -= _this.AutoStep;
						if (data.scroll) data.scroll.call(_this, e);
					}, _this.AutoTick);
				}
			}
			else if ( (e.touches ? e.touches[0] : e).clientX > data.right ) {
				if ( !data.rt ) {
					if ( data.lt ) { clearInterval(data.lt); data.lt = null }
					data.rt = setInterval(function(){
						_this.scroll.scrollLeft += _this.AutoStep;
						if (data.scroll) data.scroll.call(_this, e);
					}, _this.AutoTick);
				}
			} else {
				if ( data.lt ) { clearInterval(data.lt); data.lt = null; }
				if ( data.rt ) { clearInterval(data.rt); data.rt = null; }
			}
		}
	}

	/**
	 * Остановка обработчика автоскроллинга
	 */
	_scroll_stop() {
		const data = this.data;
		if ( data.lt ) clearInterval(data.lt);
		if ( data.rt ) clearInterval(data.rt);
	}

	/** Метод "заглушка" */
	_stub() {}

	/**
	 * Стандартные действия при перемещении мыши
	 */
	_default_move(e) {
		const _this = this;

		if (e.type == 'mouseleave') {
			_this.on_now = null;
		} else if (_this.on_now !== e.target) {
			_this.on_now = e.target;
		} else {
			return;
		}
		if (_this.tt) _this.tooltip();
	}

	tooltip(cell) {
		const _this = this;
		if (_this.tt) {
			try { _this.tt.hide(); _this.tt.dispose(); } catch(x) {}
			try { delete _this.tt; } catch(x) {}
		}
		if (cell) {
			const
				child = cell.firstElementChild,
				test = child && child.cloneNode(true);
			if (!test) return;
			for (let [name, val] of [
				['position', 'fixed'],
				['top', '-200vh'],
				['visibility', 'hidden'],
				['oveflow', 'hidden'],
				['width', 'auto']
			]) test.style.setProperty(name, val, 'important');
			cell.appendChild(test);
			let show = child.offsetWidth <= test.offsetWidth;
			test.remove();
			if (show) {
				try {
				(_this.tt = new Tooltip(cell, {
					boundary: _this.list.widget,
					title: '<div class="text-start">' + child.innerHTML + '</div>',
					html: true,
					animation: false,
					delay: {'show': 500, 'hide': 0},
					placement: 'bottom'
				})).show();
				} catch(e) {
					console.error(e);
				}
			}
		}
	}

	/**
	 * Возвращает обработчик "тиков" таймера отображения подсказок.
	 */
	onmovetimer() {
		const
			_this = this,
			list = _this.list,
			cls_cell = _this.list.options.cls.cell;

		return function() {
			if (_this.on_now !== _this.on_old) {
				// Переместились
				_this.on_old = _this.on_now;
				if (_this.on_flag !== false) _this.on_flag = false;
			} else if (_this.move === _this._default_move) {
				// Проверка необходимсти показа подсказки
				if (_this.on_flag) {
					_this.on_flag = undefined;
					let cell = list._closest(_this.on_now, cls_cell);
					_this.tooltip(cell && cell.parentNode && cell.parentNode.classList.contains(_this.list.options.cls.row) && cell);
				} else if (_this.on_flag !== undefined) {
					_this.on_flag = true;
				}
			}
		};
	}

	/**
	 * Обработчик прокрутки списка. вызывается из ItemsList при прокуртке.
	 */
	on_list_scroll() {
		try { if (this._tt_last && this._tt_last.__tooltip) this._tt_last.__tooltip.hide(); } catch(x) {}
	}

}


/**
*
* Текущее выделение и фокус элементов списка ItemsList.
*
*/
class ItemsListSelection {
	/**
	 * @member c {number} - Колонка на которой установлен фокус
	 */
	c;
	/**
	 * @member cell {DOM} - Ячейка на которой установлен фокус.
	 */
	cell;
	/**
	 * @member ftimer - Таймер запуска обработчика фокуса (setTimeout).
	 */
	ftimer;
	/**
	 * @member last {number} - Индекс последней строки у которой поменялось выделение.
	 */
	last;
	/**
	 * @member last_sel {boolean} - Последнее действие было select (true) или
	 * unselect (false)
	 */
	last_sel;
	/**
	 * @member length {number} - Количество выделеных объектов
	 */
	length;
	/**
	 * @member list {ItemsList} - Объект списка ItemsList
	 */
	list;
	/**
	 * @member r {number} - Строка на которой установлен фокус
	 */
	r;
	/**
	 * @member row {DOM} - Строка на которой установлен фокус.
	 */
	row;
	/**
	 * @member sel - Словарь выделенных строк таблицы. Ключи - идентификаторы
	 * строк. Значения - true.
	 */
	sel;
	/**
	 * @member stimer - Таймер запуска обработчика выделения (setTimeout).
	 */
	stimer;

	/**
	 * @param list {ItemsList} - Список.
	 */
	constructor(list) {
		const _this = this;
		_this.list = list;

		class Timeout {
			constructor(func, timeout) {
				this.i = null;
				this.f = func;
				this.t = timeout;
			}
			start() {
				const t = this;
				if (t.i) { clearTimeout(t.i); t.i = null; }
				if (t.t) t.i = setTimeout(t.f, t.t); else t.f();
			}
			stop() {
				const t = this;
				if (t.i) { clearTimeout(t.i); t.i = null; }
			}
		}

		const widget = list.widget;

		_this.ftimer = new Timeout(function(){
			widget.dispatchEvent(new CustomEvent('il_focus', {
				detail: {
					list: list,
					select: _this
				}}));
		}, list.options.focus_timeout);

		_this.stimer = new Timeout(function(){
			widget.dispatchEvent(new CustomEvent('il_select', {
				detail: {
					list: list,
					select: _this
				}}));
		}, list.options.select_timeout);

		_this.reset();
	}

	/**
	 * Сброс параметров выбора и фокуса
	 */
	reset() {
		const _this = this;
		_this.r = -1;
		_this.c = -1;
		_this.length = 0;
		_this.sel = {};
		_this.last = -1;
		_this.last_sel = true;
		_this.row = undefined;
		_this.cell = undefined;
	}

	/**
	 * Получить идентификаторы выбраных строк
	 * @returns {Array} - Массив идентификаторов
	 */
	get_selected() {
		const res = [], sel = this.sel;
		for (let i in sel) if (sel[i]) res.push(i);
		return res;
	}

	/**
	 * Выбрать строки с указанными идентификаторами.
	 * Обработчик il_select не вызывается.
	 * @param id {Array} - Массив идентификаторов
	 */
	set_selected(id) {
		const _this = this, items = _this.list.items;
		let sel = _this.sel;

		// Убрать текущий выбор строк
		for (let i in sel) try { items[i].s = false; } catch(e) {}
		_this.length = 0;
		_this.sel = (sel = {});
		// Установить новый выбор строк
		for (let i=0;i<id.length;i++) try {
			_this.select(items[id[i]].i, false);
		} catch(e) {}

		_this.list.upd_id++;
		_this.list.draw_view();
		_this.draw_count();
	}

	/**
	 * Установить фокус на ячейку с координатами (r, c).
	 * Если необходимо выполняет прокручивание списка к указанной строке.
	 * @param r {number} - Индекс строки
	 * @param c {number} - Индекс колонки
	 * @param shift {boolean} - Зажата клавиша Shift
	 */
	focus(r, c, shift) {
		const
			_this = this,
			list = _this.list;
		let
			rows = list.rows,
			scroll = list.widget;

		if (r < 0 || r > rows.length) return false;

		let item = rows[r], dispatch, sel_dispatch;

		// Фокус строки
		if (r != _this.r) {
			if (_this.row) _this.row.classList.remove(list.options.cls.focused);
			if (_this.cell) _this.cell.classList.remove(list.options.cls.focused);

			// select/unselect строки
			if (shift && _this.r >= 0) {
				let _s = Math.min(_this.r, r),
					_e = Math.max(_this.r, r),
					_sel = _this.sel[rows[_this.r].id] ? _this.select : _this.unselect;
				if (_s == r) _e--; else _s++;
				for (let i=_s;i<=_e;i++) _sel.call(_this, i, true);
				_this.draw_count()
				sel_dispatch = true;
			}

			// Установка фокуса
			_this.r = r;
			let y = scroll.scrollTop,
				yr = r * list.row_size; // y-координата строки
			if (yr < y) {
				// Строка выше области видимости
				scroll.scrollTop = yr;
				list.draw_view();
			} else if ((yr + list.row_size) > (y + scroll.clientHeight - list.h_height)) {
				// Строка ниже области видимости
				scroll.scrollTop = yr + list.row_size - scroll.clientHeight + list.h_height + 1;
				list.draw_view();
			}
			if (item && item.n) {
				(_this.row = item.n).classList.add(list.options.cls.focused);
				// Фокусировка ячейки
				if (list.options.cells && _this.c >= 0) {
					let cell = item.n.childNodes[Math.min(_this.c + list.sys_cols, item.n.childNodes.length - 1)];
					while (cell && !cell.dataset.col) cell = cell.previousElementSibling;
					if (_this.cell = cell) {
						cell.classList.add(list.options.cls.focused);
					}
				}
			}
			dispatch = true;
		}

		// Фокус колонки
		if (c !== undefined && c != _this.c && list.options.cells && c < list.columns.length && item && item.n) {
			_this.c = c;
			if (_this.cell) _this.cell.classList.remove(list.options.cls.focused);
			let cell = item.n.childNodes[Math.min(c + list.sys_cols, item.n.childNodes.length - 1)];
			while (cell && !cell.dataset.col) cell = cell.previousElementSibling;
			if (_this.cell = cell) {
				cell.classList.add(list.options.cls.focused);

				// Если фиксированная ячейка не проверяем скроллинг - нет смысла
				if (!cell.classList.contains(list.options.cls.fixed_left)) {
					// Если необходимо переместить скроллинг, чтобы показать всю ячейку
					if ((cell.offsetLeft < (scroll.scrollLeft + list.f_width)) || (cell.offsetWidth >= scroll.clientWidth)) {
						// За левой границей
						scroll.scrollLeft = cell.offsetLeft - list.f_width;
					}
					else if ((cell.offsetLeft + cell.offsetWidth) > (scroll.scrollLeft + scroll.clientWidth)) {
						// За правой границей
						scroll.scrollLeft = cell.offsetLeft + cell.offsetWidth - scroll.clientWidth;
					}
				}
			}

			dispatch = true;
		}

		// Запуск обработчика focus
		if (dispatch) _this.ftimer.start();
		// Запуск обработчика selectcase
		if (sel_dispatch) _this.stimer.start();
		return dispatch;
	};


	/**
	 * Установить фокус на строку относительно текущего фокуса.
	 * @param offset {number} - Смещение (например: -1 - предыдущая строка).
	 * @param shift {boolean} - Зажата клавиша Shift
	 */
	focus_row(offset, shift) {
		let n, _this = this;
		if (offset > 0) {
			n = Math.min(_this.r + offset, _this.list.rows.length - 1);
		} else {
			n = Math.max(_this.r + offset, 0);
		}
		return _this.r != n ? _this.focus(n, undefined, shift) : false;
	};

	/**
	 * Установить фокус на колонку относительно текущего фокуса.
	 * @param offset {number} - Смещение (например: -1 - предыдущая колонка).
	 * "f" - первая колонка, "l" - последняя колонка.
	 */
	focus_col(offset) {
		let n, _this = this;
		if (offset === 'f') {
			n = 0;
		} else if (offset === 'l') {
			n = _this.list.columns.length - 1;
		} else if (offset > 0) {
			n = Math.min(_this.c + offset, _this.list.columns.length - 1);
		} else {
			n = Math.max(_this.c + offset, 0);
		}
		return _this.c != n ? _this.focus(_this.r, n) : false;
	};

	/**
	 * @returns Возвращает ``true`` если фокус установлен.
	 */
	is_focused() {
		return this.r >= 0 && this.row;
	}

	/**
	 * Выбрать указанную строку.
	 * @param r {number} - Индекс строки.
	 * @param nocount {boolean} - Не обновлять счётчик выделеных строк и не
	 * запускать обработчик onselect
	 */
	select(r, nocount) {
		const
			_this = this,
			list = _this.list,
			rows = list.rows;

		// Указан неправильный номер строки.
		if (r < 0 || r >= rows.length ) return;

		const row = rows[r],  // строка
			id = row.id;      // идентификатор строки

		// Строка уже выделена или она no_check
		if (_this.sel[id] || row.nc) return;

		// Выделить строку
		_this.sel[id] = true;
		_this.length++;
		row.s = true;
		if (row.n) {
			if (r >= list.v_top && r < list.v_last)
				row.n.classList.add(list.options.cls.selected);
			else
				row.u = -1;
		}

		_this.last = r;
		_this.last_sel = true;
		if (!nocount) {
			_this.draw_count();
			// Запуск обработчика select
			_this.stimer.start();
		}
	}
	/**
	 * Убрать выделение с указанной строки.
	 * @param r {number} - Индекс строки.
	 * @param nocount {boolean} - Не обновлять счётчик выделеных строк и не
	 * запускать обработчик onselect
	 */
	unselect(r, nocount) {
		const
			_this = this,
			list = _this.list,
			rows = list.rows;

		// Указан неправильный номер строки.
		if (r < 0 || r >= rows.length ) return;

		let row = rows[r],  // строка
			id = row.id;   // идентификатор строки

		// Строка не выделена.
		if (!_this.sel[id]) return;

		// Убрать выбор строки
		delete _this.sel[id];
		_this.length--;
		row.s = false;
		if (row.n) {
			if (r >= list.v_top && r < list.v_last)
				row.n.classList.remove(list.options.cls.selected);
			else
				row.u = -1;
		}

		_this.last = r;
		_this.last_sel = false;
		if (!nocount) {
			_this.draw_count();
			// Запуск обработчика select
			_this.stimer.start();
		}
	}

	/**
	 * "Переключить" выделение на строке.
	 * @param r {number} - Индекс строки.
	 * @param shift {boolean} - Нажата клавиша Shift
	 */
	toggle(r, shift) {
		const _this = this;
		try {
			if (shift && _this.last !== r && _this.last >= 0) {
				let s = Math.min(r, _this.last),
					e = Math.max(r, _this.last),
					_sel = _this.last_sel !== false ? _this.select : _this.unselect;
				for (let i=s;i<=e;i++) _sel.call(_this, i, true);
				_this.draw_count();
				_this.stimer.start();
			} else {
				if (_this.sel[_this.list.rows[r].id]) _this.unselect(r); else _this.select(r);
			}
		} catch(e) {
			console.error(e);
		}
	}

	/**
	 * Выделить все строки
	 */
	select_all() {
		const _this = this, _rows = _this.list.rows.length;
		for (let r=0;r<_rows;r++) _this.select(r, true);
		_this.draw_count();
		_this.stimer.start();
	}

	/**
	 * Убрать выделение всех строк
	 */
	unselect_all() {
		const _this = this, _rows = _this.list.rows.length;
		for (let r=0;r<_rows;r++) _this.unselect(r, true);
		_this.draw_count();
		_this.stimer.start();
	}

	/**
	 * Инвертировать выделение строк
	 */
	invert() {
		const _this = this, rows = _this.list.rows, rows_len = rows.length;
		for (let r=0;r<rows_len;r++) {
			if (_this.sel[rows[r].id]) _this.unselect(r, true); else _this.select(r, true);

		}
		_this.draw_count();
		_this.stimer.start();
	}

	/**
	 * Показать панель выделения строк.
	 */
	select_dialog() {
		const
			_this = this,
			node = _this.list.col_check;
		if (node.dataset.bsToggle == 'dropdown') return;

		const
			dlg = document.createElement('DIV'),
			ico = _this.list.options.ico_classes;

		dlg.className = 'dropdown-menu dropdown-menu-end';

		dlg.innerHTML =
			'<div class="px-3 text-muted mb-1">Выбор строк...</div>'
			+ `<a href="#" class="dropdown-item" data-action="select"><span class="d-flex align-items-center"><i class="${ico.sel_all} me-1"></i> <span class="me-2">Выбрать все</span> <span class="ms-auto badge text-muted border fw-normal">Ctrl+A</span></span></a>`
			+ `<a href="#" class="dropdown-item" data-action="unselect"><span class="d-flex align-items-center"><i class="${ico.sel_none} me-1"></i> <span class="me-2">Снять выбор со всех</span> <span class="ms-auto badge text-muted border fw-normal">Ctrl+Shift+A</span></span></a>`
			+ `<a href="#" class="dropdown-item" data-action="invert"><span class="d-flex align-items-center"><i class="${ico.sel_invert} me-1"></i> <span>Инвертировать выбор</span></span></a>`;

		const action = function(e){
			let t = e.target.closest('[data-action]');
			if (!t) return;
			switch (t.getAttribute('data-action')){
			case "select":
				_this.select_all();
				break;
			case "unselect":
				_this.unselect_all();
				break;
			case "invert":
				_this.invert();
				break;
			}
		};

		dlg.addEventListener('click', action);
		_this.list._dropdown(node, dlg);
	}

	/**
	 * Отрисовывает счётчик выбранных строк
	 */
	draw_count() {
		try { this.list.sel_count.innerText = this.length || ''; } catch(e) {}
	}

	/**
	 * Сохранить текущий выбор строк.  Нужно выполнять перед перезагрузкой
	 * данных.
	 * @param focus boolean - Сохранить также позицию фокуса.
	 */
	save_selected(focus) {
		this._store = this.get_selected();
		if (focus) {
			this._store_focus = {
				r: this.r,
				c: this.c,
				id: this.id()
			}
		}
	}

	/**
	 * Id строки на которой установлен фокус
	 * @returns Идентифкатор строки
	 */
	id() {
		try { return this.list.rows[this.r]; } catch(e) { return undefined; }
	}

	get focused_item() {
		try { return this.list.rows[this.r].d; } catch(e) { return undefined; }
	}

	/**
	 * Восстановить выбор строк сохранённый save_selected().
	 */
	restore_selected() {
		if (this._store !== undefined)  {
			this.set_selected(this._store);
			delete this._store;
		}

		if (this._store_focus !== undefined) {
			this.r = -1;
			this.c = -1;

			let r = -1;
			try {
				r = this.list.items[this._store_focus.id].i;
			} catch(e) {
				if (this._store_focus.r >= 0 && this._store_focus.r < this.list.rows.length) {
					r = this._store_focus.r;
				}
			}
			if (r >= 0) {
				this.focus(r, this._store_focus.c);
			}
			delete this._store_focus;
		}
	}

	/**
	 * Итератор выбраных строк.
	 * @param func {callback} - Функция принимающая item списка и объект
	 * ItemsList в this.
	 */
	forEach(func) {
		const sel = this.sel, list = _this.list, items = list.items;
		for (let i in sel) if (items[i]) func.call(list, items[i]);
	}

}

/**
 * Колонка списка
 */
class ItemsListColumn {

	/** ItemsList object */
	list;
	/** Column name */
	name;
	// Column contains id of rows
	id = false;
	// Width (for internal use)
	_width = null;
	// Allow sort (for internal use)
	_sort = undefined;

	_xattrs = ['name', 'width', 'sort', 'id', 'render'];

	constructor(list, name, col) {
		const _this = this;
		if (typeof (_this.list = list) !== 'object') throw 'Parameter "list" must be ItemsList instance';
		if (col.name && col.name != name) console.error(`Parameter "name" ("${name}") and attribute "name" in column object ("${col.name}") not equal. Using "${name}"`);
		_this.name = name;

		if (col.width !== undefined) _this.width = col.width;
		if (col.sort !== undefined)  _this.sort = col.sort;
		if (col.id) {
			list.col_id = name;
			_this.id = true;
		}
		if (col.render) {
			_this.r = list.render_factory(col.render);
		} else try { _this.r = list.render_factory(list.options.render[n]); } catch (e) {}

		// Other options set
		for (let [name, val] of Object.entries(col)) {
			if (_this._xattrs.indexOf(name) < 0) _this[name] = val;
		}
	}

	/**
	 * Width property getter
	 */
	get width() {
		const _this = this;
		if (_this._width !== undefined) return _this._width;
		const list = _this.list;
		try {
			return list.width[_this.name];
		} catch(e) {
			return list.options.col_width;
		}
	}

	/**
	 * Width property setter
	 */
	set width(value) {
		this._width = (value === null ? undefined : value);
	}

	/**
	 * Sort property getter
	 */
	get sort() {
		return (this._sort !== undefined && this._sort !== null) ? this._sort : this.list.options.col_sort;
	}

	/**
	 * Sort property setter
	 */
	set sort(value) {
		this._sort = (value === null ? undefined : value);
	}

	/**
	 * Fixed property getter
	 */
	get fixed() {
		return (!this.not_fixed) &&
			((this.show === 'fixed') || (this.list.fixed.indexOf(this.name) >= 0));
	}

}


class ItemsListColumnsDialog {

	HTML = '<div class="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-scrollable modal-dialog-centered">'
	+'<div class="modal-content">'
	 // modal-header
	+'<div class="modal-header"><div class="modal-title">Колонки</div></div>'
	//<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label=""></button></div>'
	// modal-body
	+'<div class="modal-body">'
	+ '  <div class="_setup-dlg-columns-cols-labels"><div>Видимые</div><div>Доступные</div></div>'
	+ '  <div class="_setup-dlg-columns-cols">'
	+ '    <div class="_setup-dlg-columns-show"></div>'
	+ '    <div class="_setup-dlg-columns-hide"></div>'
	+ '  </div>'
	+'</div>' // modal-body
	  +'<div class="modal-footer">'// modal-body
	  +'<button type="button" data-action="apply">Применить</button><button type="button" data-action="cancel">Отмена</button>'
	  +'</div>' // modal-footer
	+'</div>' // modal-content
	  +'</div>'; // modal-dialog

	constructor(list) {
		const
			_this = this,
			dlg = (_this.widget = document.createElement('DIV'));

		_this.list = list;

		dlg.className = 'modal';
		dlg.tabIndex = -1;
		dlg.setAttribute('data-bs-backdrop', 'static');
		dlg.innerHTML = this.HTML;

		dlg.querySelector('.modal-content').classList.add(list.options.cls.dlg, list.options.cls.dlg_cols);
		_this.cols_hide = dlg.querySelector('._setup-dlg-columns-hide');
		_this.cols_show = dlg.querySelector('._setup-dlg-columns-show');

		// Кнопка "Применить"
		_this.btn_apply = dlg.querySelector('[data-action="apply"]')
		_this.btn_apply.className = list.options.btn_classes.all + ' ' + list.options.btn_classes.primary;
		_this.btn_apply.disabled = true;

		// Кнопка "Отмена"
		(_this.btn_cancel = dlg.querySelector('[data-action="cancel"]')).className = list.options.btn_classes.all + ' ' + list.options.btn_classes.secondary;

		_this.make_columns();
		_this.sortable();
		_this.bind_modal();
		_this.bind_actions();
		_this.bind_columns();

		_this.modal = new Modal(dlg);
		// Показать диалог
		_this.modal.show();

	}

	/**
	 * Создание элемента колонки
	 * @param {*} label
	 * @param {*} name
	 * @returns
	 */
	col_node(label, name, fixed, show) {
		let col;
		if (label instanceof Element) {
			col = label.cloneNode(true);
		} else {
			col = document.createElement('DIV');
			col.classList.add('_column');
			col.innerHTML = (show ? '<span class="_show"></span>' : '<span class="_handle"></span>') +
			'<span class="_label"></span>' +
			(fixed !== 'never' ? '<label class="_fixed ' + ( show === 'fixed' ? '_always' : '') + '"><input type="checkbox"' + (fixed ? ' checked' : '') + '><span></span></label>' : '');
			col.querySelector('._label').innerText = label;
			col.setAttribute('data-name', name);
		}
		return col
	}

	/**
	 * Заполнение списков колонок
	 */
	make_columns() {
		const
			_this = this,
			list = _this.list,
			columns = list.get_view_cols(),
			column = list.column,
			all_cols = [];


		// Заполнить список отображаемых колонок
		for (let col of columns) {
			_this.cols_show.appendChild(_this.col_node(col.label, col.name, col.not_fixed ? 'never' : col.fixed, col.show));
		}

		// Доступные колонки
		const sections = list.section || {};
		for (let col of Object.values(column)) if (!(col.hidden || col.show)) all_cols.push(col);

		// Сортировка колонок
		all_cols.sort(function(a, b){
			if (a.section == b.section) return a.label < b.label ? -1 : (a.label > b.label ? 1 : 0);
			a = (a.section && sections[a.section]),
			b = (b.section && sections[b.section]);
			if (!a && b) return -1;
			if (a && !b) return 1;
			if (a.order_id == b.order_id) return a.label < b.label ? -1 : (a.label > b.label ? 1 : 0);
			return a.order_id < b.order_id ? -1 : (a.order_id > b.order_id ? 1 : 0);
		});

		// Заполнить список всех колонок
		let _cnode, sname;
		for (let _col of all_cols) {
			if (_col.section && sname != _col.section) {
				sname = _col.section;
				if (list.section[sname]) {
					let _snode = document.createElement('DIV');
					_snode.classList.add('_section');
					_snode.innerText = list.section[sname].label;
					_this.cols_hide.appendChild(_snode);
				}
			}
			_this.cols_hide.appendChild(_cnode = _this.col_node(_col.label, _col.name, _col.not_fixed ? 'never' : _col.fixed));

			// Скрыть если добавлено в видимые.
			if (columns.indexOf(column[_col.name]) >= 0) _cnode.style.display = 'none';
		}
	}


	/**
	 * Включение перетаскивания
	 */
	sortable() {
		if (typeof Sortable === 'undefined') return;

		const
			_this = this,
			list = _this.list,
			cols_show = _this.cols_show,
			cols_hide = _this.cols_hide;



		const
			check_fixed = function(item) {
				// Колонка всегда фиксирована?
				if (item.querySelector('._fixed._always')) {
					item.querySelector('._fixed input').checked = true;  // Для надёжности
					let prev = item.previousElementSibling;
					while (prev && !(prev.querySelector('._fixed input') || {}).checked) {
						prev = prev.previousElementSibling;
					}
					if (prev) {
						prev.insertAdjacentElement('afterend', item);
					} else {
						item.parentElement.insertAdjacentElement('afterbegin', item);
					}
					return;
				}

				// Проверка и исправление фиксации
				let fix_input = item.querySelector('._fixed input') || {};
				if (fix_input.checked) {
					// Выносим из фиксированных в нефиксированные
					if (item.previousElementSibling && !(item.previousElementSibling.querySelector('._fixed input') || {}).checked) {
						item.querySelector('._fixed input').checked = false;
					}
				} else {
					// Выносим из нефиксированных в фиксированные
					if (item.nextElementSibling && (item.nextElementSibling.querySelector('._fixed input') || {}).checked) {
						if (!fix_input.tagName) {
							// Колонку нельзя фиксировать
							let next = item.nextElementSibling;
							while (next && (next.querySelector('._fixed input') || {}).checked) {
								next = next.nextElementSibling;
							}
							if (next) {
								next.insertAdjacentElement('beforebegin', item);
							} else {
								item.parentElement.appendChild(item);
							}
						} else {
							fix_input.checked = true;
						}
					}
				}
			},
			sort_end = function(e) {
			// В пределах одного списка
			if (e.from === e.to) {
				if (e.to === cols_show) {
					_this.apply_activate(e); // Изменение порядка видимых колонок
					window.setTimeout(()=>check_fixed(e.item), 10);
				}
				return;
			}

			if (e.to === cols_show) {
				// Перенос в видимые колонки
				try {
					window.setTimeout(()=>check_fixed(e.item), 10);
					e.clone.style.display = 'none';
				} catch(exc) { console.error(exc); }
			} else if (e.to === cols_hide) {
				// Перенос в невидимые колонки
				e.item.remove();
				try {
					cols_hide.querySelector('[data-name="'+e.item.dataset.name+'"]').style.display = '';
				} catch(exc) { console.error(exc); }
			}
			_this.apply_activate(e);
		};

		// Включение перетаскивания в списке отображаемых колонок
		_this.sortable_show = Sortable.create(cols_show, {
			group: {
				name: 'sort' + list.inst_index,
				put: true,
				pull: true
			},
			animation: 150,
			handle: '._column',
			onEnd: sort_end
		});

		// Включение перетаскивания в списке скрытых колонок
		_this.sortable_hide = Sortable.create(cols_hide, {
			group: {
				name: 'sort' + list.inst_index,
				put: function(to, from, col) {
					return to.el.querySelector(`._column[data-name="${col.dataset.name}"]`) ? true : false;
				},
				pull: 'clone'
			},
			animation: 150,
			handle: '._column',
			sort: false,
			onEnd: sort_end
		});

		cols_hide.parentElement.classList.add('_sortable');
	}

	/**
	 * Активировать кнопку "Применить"
	 */
	apply_activate() {
		this.btn_apply.disabled = !this.cols_show.children.length;
	};

	/**
	 * Обработчики событий окна диалога
	 */
	bind_modal() {

		// Пересчитывает максимальный размер панели колонок
		let onresize_timer;

		const
			_this = this,
			dlg = _this.widget,
			dlg_dialog = dlg.querySelector('.modal-dialog'),
			dlg_header = dlg.querySelector('.modal-header'),
			dlg_footer = dlg.querySelector('.modal-footer'),
			dlg_body = dlg.querySelector('.modal-body'),
			dlg_cols_labels = dlg.querySelector('._setup-dlg-columns-cols-labels'),
			dlg_cols = dlg.querySelector('._setup-dlg-columns-cols'),

			onresize = function(e) {
			if (e) {
				if (onresize_timer) window.clearTimeout(onresize_timer);
				onresize_timer = window.setTimeout(onresize, 100);
			} else {
				onresize_timer = undefined;
				let calc = 'calc( 99vh';
				let style = getComputedStyle(dlg_dialog);
				calc += ' - ' + style.marginTop + ' - ' + style.marginBottom;
				calc += ' - ' + dlg_header.offsetHeight + 'px';
				calc += ' - ' + dlg_footer.offsetHeight + 'px';
				calc += ' - ' + dlg_cols_labels.offsetHeight + 'px';
				style = getComputedStyle(dlg_body);
				calc += ' - ' + style.paddingTop + ' - ' + style.paddingBottom;
				calc +=  ' )';
				dlg_cols.style.maxHeight = calc;
			}
		};
		window.addEventListener('resize', onresize);

		/*
		 * Удалить диалог после закрытия
		 */
		dlg.addEventListener('hidden.bs.modal', function(){
			window.removeEventListener('resize', onresize);
			if (_this.sortable_show) _this.sortable_show.destroy();
			if (_this.sortable_hide) _this.sortable_hide.destroy();
			dlg.remove();
		});

		/*
		 * При отображении диалога переместить backdrop под modal.
		 */
		dlg.addEventListener('shown.bs.modal', function(){
			const backdrop = dlg.previousElementSibling;
			if (backdrop && backdrop.classList.contains('modal-backdrop')) {
				backdrop.style.zIndex = getComputedStyle(dlg).zIndex;
			}
			onresize();
		});

	}

	/**
	 * Обработчики действий ()
	 */
	bind_actions() {
		const
			_this = this,
			action = function(e) {
				let t = e.target.closest('[data-action]');
				if (!t) return;
				switch (t.getAttribute('data-action')) {
				case "apply":
					_this.apply();
					break;
				case "cancel":
				case "close":
					_this.modal.hide();
					break;
				default:
					return;
				}
				e.preventDefault();
			};

		// Обработчик action
		_this.widget.querySelectorAll('[data-action]').forEach(function(node){
			node.addEventListener('click', action);
		});
	}

	/**
	 * Обработчики действий над списками колонок
	 */
	bind_columns() {
		const
			_this = this;

		// Обработчик переключения колонки
		const col_toggle = function(e){
			// Проверка нажатия на кнопке фиксации колонки
			let t = _this.list._closest(e.target, '_fixed', _this.widget);
			if (t) return; // Фиксация отрабатывается другим обработчиком

			// Проверка нажатия на колонке
			t = _this.list._closest(e.target, e.type == 'dblclick' ? '_column' : '_handle', _this.widget);
			if (!t) return;

			t = t.closest('._column');
			if (t.parentElement === _this.cols_hide) {
				t.style.display = 'none';
				t = _this.col_node(t);
				_this.cols_show.appendChild(t);
				t.style.display = '';
			} else {
				try {
					_this.cols_hide.querySelector('[data-name="' + t.dataset.name + '"]').style.display = '';
				} catch(e) {
					console.error(e);
				}
				t.remove();
			}
			_this.apply_activate(e);
		};

		// Обработчик фиксации колонки
		const col_fixed = function(e){

			// Получение элемента колонки
			let t = _this.list._closest(e.target, '_column', _this.widget);
			if (!t) return;

			// Всегда закреплена?
			if (e.target.closest('._fixed').classList.contains('_always')) {
				if (!e.target.checked) e.target.checked = true;
				return;
			}

			let sibling;
			if (e.target.checked) {
				// Зафиксирована
				while ((sibling = t.previousElementSibling) && !(sibling.querySelector('._fixed input') || {}).checked) {
					sibling.insertAdjacentElement('beforebegin', t);
				}
			} else {
				// Откреплена
				while ((sibling = t.nextElementSibling) && (sibling.querySelector('._fixed input') || {}).checked) {
					sibling.insertAdjacentElement('afterend', t);
				}
			}

			_this.apply_activate(e);
		};

		_this.cols_hide.parentElement.addEventListener('click', col_toggle);
		_this.cols_hide.parentElement.addEventListener('dblclick', col_toggle);
		_this.cols_hide.parentElement.addEventListener('input', col_fixed);
	}


	/**
	 * Применить изменения и закрыть диалог
	 */
	apply() {
		const
			_this = this,
			opts = {columns: [], fixed: []};

		// Колонки
		for (let node of _this.cols_show.querySelectorAll('[data-name]')) {
			opts.columns.push(node.dataset.name);
			if ((node.querySelector('._fixed input') || {}).checked) opts.fixed.push(node.dataset.name);
		}
		_this.list.set_options(opts, true);
		_this.modal.hide();
	}

}


/**
 * Get error of XHR-request.
 * Return error message or DOM object if xhr.response is html
 */
function xhr_error(xhr, error, messages) {
	if (typeof xhr === 'string') {
		if (typeof error === 'object') {
			messages = error;
		}
		error = xhr;
		xhr = undefined;
	}

    if (error == 'abort') return;

	let message;

    if (error == 'timeout') {
        message = (messages && messages.timeout) || 'Server timed out';
    } else if (xhr) {
        if (xhr.responseType == 'json' && xhr.response && xhr.response.error !== undefined) {
            message = xhr.response.error;
        } else if (['', 'text'].indexOf(xhr.responseType) >= 0 && xhr.responseText) {
			if (xhr.responseText.startsWith('<')) {
				message = document.createElement('div');
				message.innerHTML = xhr.responseText;
				if (!message.innerText) message = '';
			} else {
				message = xhr.responseText;
			}
        }
		if (!message) message = `${xhr.status}: ${xhr.statusText}`;
    } else {
		message = error;
	}

    return message;
}
//~xhr_error


/**
 * Generate unique id
 * @param {string} prefix - Id prefix.  Default: "id-"
 * @param {DOM} node - The element that is checked for an id, and in case of its absence,
 *                     a new ID is generated
 */
function uniq_id(prefix, node) {
	if (typeof prefix === 'object' && !node) { node = prefix; prefix = undefined; }
	if (node && node.id) return node.id;
	let res = (prefix || 'id') + (uniq_id._id = (uniq_id._id || 0) + 1) + '-' + (uniq_id._suffix || (uniq_id._suffix = (new Date()).getTime()));
	if (node) node.id = res;
	return res;
}
