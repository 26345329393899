/**
 * Возвращает данные формы в виде объекта FormData.
 */
export function form_data(form) {
	const res = new FormData();
	let name;
	for (let input of form.querySelectorAll('[name]')) {
		name = input.name;
		if (input.tagName == 'INPUT') {
			if ((input.type == 'checkbox') || (input.type == 'radio')) {
				if (input.checked) res.append(name, input.value);
			} else if (input.type == 'file') {
				for (let file of input.files) res.append(name, file);
			} else {
				res.append(name, input.value);
			}
		} else if (input.tagName == 'SELECT') {
			for (let o of input.childNodes) {
				if (o.selected) {
					res.append(name, o.value === undefined ? o.innerText : o.value);
				}
			}
		} else {
			res.append(name, input.value);
		}
	}
	return res;
}


/**
 * Отправка данных формы при помощи ajax-запроса (XMLHttpRequest)
 */
export default function form_send(form, options) {
	const
		xhr = new XMLHttpRequest(),
		opts = {},
		_names = form_send._opt_names;

	Object.assign(opts, form_send.defaults, options || {});

	for (let n in opts) { if (_names.indexOf(n) >= 0) { xhr[n] = opts[n]; } }

	if (opts.error) {
		const onerror = function(mode) {
			try {
				opts.error.call(this, mode);
			} catch(e) {
				console.error(e);
			}
			if (opts.complete) opts.complete.call(this);
		};
		xhr.onabort = function() { onerror('abort'); };
		xhr.ontimeout = function() { onerror('timeout'); };
	}

	xhr.onload = function() {
		const xhr = this;
		try {
			if (xhr.status == 200) {
				if (opts.success) opts.success.call(xhr);
			} else {
				if (opts.error) opts.error.call(xhr);
			}
		} catch (e) {
			console.error(e);
		}
		if (opts.complete) opts.complete.call(xhr);
	};

	let url = opts.url || '';
	if (opts.cache === false) {
		url += (url.indexOf('?') < 0 ? '?' : '&') + (new Date()).getTime();
	}
	xhr.open(opts.method, url);
	if (opts.headers) for (let n in opts.headers) xhr.setRequestHeader(n, opts.headers[n]);
	let data;
	if (form instanceof Element) data = form_data(form);
	else {
		if (typeof form === 'function') form = form();
		if (typeof form === 'object') {
			data = new FormData();
			for (let n in form) data.append(n, typeof form[n] === 'object' ? JSON.stringify(form[n]) : form[n] === null ? '' : form[n]);
		}
	}
	xhr.send(data);
	return xhr;
}

form_send._opt_names = ['timeout', 'responseType'];
form_send.defaults = {method:'POST'};
